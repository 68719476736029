import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import { FaWhatsapp } from "react-icons/fa";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #36A469;
    border-radius: 3px;
    color: white;
    font-size: 14px;
    transition: background-color 0.3s;
    padding: 15px;
    gap: 10px;
    width: 100%;
    user-select: none;

    &:hover {
        cursor: pointer;
        background-color: #128c7e;
    }

    &:active {
        background-color: #128c7e;
    }

    @media (max-width: 768px) {
        font-size: 12px;
    }
`

const Icon = styled(FaWhatsapp)`
    color: ${colors.white};
    font-size: 17px;
    margin-right: 10px;
`

type WhatsAppButtonProps = {
    text: string;
    onClick?: () => void;
}

export default function WhatsAppButton( { text, onClick = () => {} }: WhatsAppButtonProps ) {
    return (
        <Container onClick={onClick}>
            <Icon />
            {text}
        </Container>
    )
}