import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";


const containerStyle = {
  width: "100%",
  height: "100%",
};

const mapOptions = {
  disableDefaultUI: true, 
  zoomControl: true, 
};

type GoogleMapsComponentProps = {
  lat_long: string;
  text?: string;
}

const GoogleMapsComponent = ({ lat_long, text}: GoogleMapsComponentProps ) => {

  const [lat, lng] = lat_long.split(',').map(Number);
  const center = {
    lat,
    lng,
  };

  const openInGoogleMaps = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=${center.lat},${center.lng}`;
    window.open(url, "_blank");
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "100%" }}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={17}
          options={mapOptions}
        >
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
      <button
        onClick={openInGoogleMaps}
        style={{
          position: "absolute",
          bottom: "10px",
          left: "10px",
          padding: "10px 20px",
          backgroundColor: "#4285F4",
          color: "#fff",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Abrir no Google Maps
      </button>
    </div>
  );
};

export default GoogleMapsComponent;