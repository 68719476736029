import styled from 'styled-components'
import colors from '../style/colors'
import noImageBarber from '../assets/images/background.png'

export const LabelAndValueContainer = styled.div<{align?: string}>`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    height: max-content;
    align-items: ${props => props.align || 'flex-start'};
    text-align: ${props => props.align || 'left'};
`

export const Label = styled.span`
    font-size: 12px;
    color: ${colors.mediumGray};
`

export const GridMenuWithTreeColumns = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
`

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 125px;
    gap: 5px;
    padding: 10px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`

export const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

export const Services = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
`

export const Service = styled.div<{ isSelected?: boolean, disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 2px solid ${colors.lightGray};
    padding: 20px;
    border-radius: 5px;
    user-select: none;

    transition: background-color 0.3s;

    ${props => props.isSelected && `
        background-color: ${colors.lightGray};        
    `}

    ${props => props.isSelected && `
        border: 2px solid ${colors.primary};  
    `}

    ${props => (!props.isSelected && !props.disabled) && `
        &:hover {
            background-color: ${colors.lightGray};
            cursor: pointer;
        }
    `}

    &:active {
        outline: 1px solid ${colors.gray} !important;
    }

    ${props => props.disabled && `
        background-color: ${colors.lightGray};
        border: 1px solid ${colors.gray};
        cursor: not-allowed;  
    `}
`

export const ServiceValueAndTimeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

export const Separator = styled.div<{width?: string, height? : string}>`
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '1px'};
    background-color: ${colors.gray};
`

export const TotalWithReservationButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`

export const TotalsContainer = styled.div`
    display: flex;
    gap: 10px;
`

export const TotalContainer = styled.div<{flexDirection? : string}>`
    display: flex;
    flex-direction: ${props => props.flexDirection || 'column'};
    gap: 10px;
    width: 100%;
`

export const ReservationButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: ${colors.darkGray};
    cursor: pointer;
    transition: background-color 0.3s;

    user-select: none;

    &:hover {
        background-color: ${colors.mediumGray};
    }

    &:active {
        background-color: ${colors.darkGray};
        outline: 2px solid ${colors.gray} !important;
    }
`
export const GoBackButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.gray};
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s;
    border: 1px solid ${colors.lightGray};
    padding: 20px;
    gap: 10px;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${colors.lightGray};
        border: 1px solid ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 1px solid ${colors.gray};
    }
`

export const AddServiceButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: ${colors.darkGray};
    cursor: pointer;
    gap: 5px;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${colors.mediumGray};
    }

    &:active {
        background-color: ${colors.darkGray};
        outline: 1px solid ${colors.gray} !important;
    }
`

export const NoImageBarber = noImageBarber;

export const FloattingContainer = styled.div<{ isVisible: boolean }>`
    display: flex;
    gap: 20px;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: ${colors.white};
    padding: 20px;
    transition: opacity 0.3s ease;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    pointer-events: ${({ isVisible }) => (isVisible ? 'auto' : 'none')};
    border-top: 1px solid ${colors.gray};
    z-index: 2;
`;

export const InputValue = styled.input`
    width: 100% !important;
    border: 1px solid ${colors.gray};
    border-radius: 3px;
    padding: 15px 7px;
    color: ${colors.darkGray};
    font-size: 16px;

    &:focus-visible {
        border: 1px solid ${colors.gray};
        outline: 1px solid ${colors.mediumGray};
    }

    &:active {
        border: 1px solid ${colors.gray};
    }

    &:hover {
        border: 1px solid ${colors.gray};
    }

    &::placeholder {
        color: ${colors.gray};
        opacity: 1;
    }
        
    @media (max-width: 768px) {
        &[type='time'], &[type='date'] {
            width: 100% !important;
            box-sizing: border-box;
            -webkit-appearance: none;
        }
    }
`

export const ResumeContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 20px;
    width: 100%;
    padding: 10px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
    padding: 20px;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`

export const ConfirmationButton = styled.div<{disabled? : boolean}>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.disabled ? colors.mediumGray : colors.primary};
    border-radius: 3px;
    color: white;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;
    width: 100%;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

    &:hover {
        cursor: pointer;
        background-color: ${colors.primaryHover};
    }

    &:active {
        background-color: ${colors.primary};
        outline: 2px solid ${colors.gray};
    }
`

export const HoursAvailable = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow-x: auto;
    padding: 0 0 10px 0;

    &::-webkit-scrollbar {
        height: 5px;
        background: ${colors.lightGray};
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: ${colors.gray};
        border-radius: 10px;
    }
`

export const Hour = styled.div<{ selected?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-radius: 3px;
    border: 1px solid ${colors.gray};
    background-color: ${colors.white};
    transition: 0.3s;

    ${props => props.selected && `
        background-color: ${colors.primary};
        // background-color: ${colors.blue};
        color: ${colors.white};
    `}

    user-select: none;

    &:hover {
        cursor: pointer;
        ${props => !props.selected && `
            // background-color: ${colors.blueHover};
            background-color: ${colors.gray};
        `}
    }

    &:active {
        background-color: ${colors.primary};
    }
`
