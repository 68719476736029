import React from "react";
import styled from "styled-components";
import colors from "../../../style/colors";

type Props = {
    children: React.ReactNode;
    bold?: boolean;
    color?: string;
    align?: string;
    width?: string;
}

type PropsStyled = {
    bold?: boolean;
    color?: string;
    align?: string;
    width?: string;
}

const Text = styled.span<PropsStyled>`
    font-size: 12px;
    font-weight: ${props => props.bold ? "bold" : "normal"};
    color: ${props => props.color? props.color : colors.darkGray};
    text-align: ${props => props.align? props.align : "center"};
    width: ${props => props.width? props.width : "auto"};
`

export default function NormalText({children, bold, color=colors.darkGray, align, width}: Props) {
    return (
        <Text 
            bold={bold} 
            color={color} 
            align={align}
            width={width}
        >
            {children}
        </Text>
    );
}