import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from '../config/api'; 
import LoadingFreezeScreen from '../components/LoadingFreezeScreen';
import { LoadingHook } from '../hooks/LoadingHook';
import { UsuarioType } from '../types/user';

type AuthContextType = {
    user: UsuarioType | null;
    signIn: (email: string, password: string) => Promise<void>;
    signOut: () => void;
    validateTokenAndSetUser: () => void;
    validateUser: () => boolean;
};

type AuthProviderProps = {
    children: React.ReactNode;
};

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: AuthProviderProps) {
    const navigate = useNavigate();
    const [user, setUser] = useState<UsuarioType | null>(null);
    const {isLoading, setIsLoading, hideLoadingWithDelay} = LoadingHook();

    const checkToken = async (token: string) => {
        try {
            const response = await api.get('/token/verify', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return true;
        } catch (error) {
            return false;
        }
    }

    const validateTokenAndSetUser = async () => {
        setIsLoading(true);
        try {
            const storagedUser = localStorage.getItem('@barber:user');
        
            if (storagedUser) {
                setUser(JSON.parse(storagedUser));
            } else {
                setUser(null);
                hideLoadingWithDelay();
                return;
            }
        
            const storagedToken = localStorage.getItem('@barber:token');
        
            if (storagedToken) {
                const tokenIsValid = await checkToken(storagedToken);
                if (!tokenIsValid) {
                    localStorage.removeItem('@barber:user');
                    localStorage.removeItem('@barber:token');
                    setUser(null);
                    navigate('/');                    
                    toast.error('Sessão expirada');
                    return;
                }
                
                api.defaults.headers.common['Authorization'] = `Bearer ${storagedToken}`;
            }
        }
        catch (error) {
        } finally {
            hideLoadingWithDelay();
        }

    };

    useEffect(() => {
        const validateAndSetUser = async () => {
            await validateTokenAndSetUser();
        };
    
        validateAndSetUser();
    }, []);

    const signIn = async (email: string, password: string) => {
        try {
            const response = await api.post('/login/', new URLSearchParams({
                username: email.toLowerCase(),
                password,
                grant_type: '',
                scope: '',
                client_id: '',
                client_secret: ''
            }), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            });
    
            if (response.data.detail){
                toast.error('Erro ao fazer login');
                return;
            }
    
            const { access_token } = response.data;
            
            const userResponse = await api.get('/auth/me', {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
    
            const user = userResponse.data;
    
            localStorage.setItem('@barber:user', JSON.stringify(user));
            localStorage.setItem('@barber:token', access_token);
    
            api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    
            setUser(user);
            toast.success('Logado com sucesso');
        } catch (error) {
            toast.error('Email ou senha incorretos');
        }
    };

    const signOut = () => {
        localStorage.removeItem('@barber:user');
        localStorage.removeItem('@barber:token');
        setUser(null);
        navigate('/');
        toast.success('Deslogado com sucesso');
    };

    const validateUser = () => {
        const storagedUser = localStorage.getItem('@barber:user');
        if (!storagedUser){
            toast.error('Faça login para acessar esta página');
            navigate('/');
            return false
        }
        return true
    }

    return (
        <AuthContext.Provider value={{ 
            user,
            signIn,
            signOut,
            validateTokenAndSetUser,
            validateUser
        }}>
            <LoadingFreezeScreen isLoading={isLoading} />
            {children}
        </AuthContext.Provider>
    );
}