import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import MediumText from "../../../components/texts/MediumText";
import { useNavigate } from "react-router-dom";
import colors from "../../../style/colors";
import NormalText from "../../../components/texts/NormalText";
import { formatDateToDDMMYYYY, formatDateToYYYYMMDD } from "../../../functions/date";
import { FaFilter, FaPlus, FaSlidersH } from "react-icons/fa";
import { LoadingHook } from "../../../hooks/LoadingHook";
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen";
import { ReservationAusencesBarbersListType } from "../../../types/reservation";
import { api } from "../../../config/api";
import { AuthContext } from "../../../context/authContext";
import GenericModal from "../../../components/GenericModal";
import { BarbersType, BarberType } from "../../../types/barber";
import DefaultContainer from "../../../components/Containers/DefaultContainer";
import CenteredContentContainer from "../../../components/Containers/CenteredContentContainer";
import SelectDate from "../../../components/SelectDate";
import ActionButton from "../../../components/ActionButton";
import CalendarSchedule from "./components/CalendarSchedule";
import { BarbeariaType, OpeningHourType } from "../../../types/barbershop";
import SelectionOptionsWithSearch from "../../../components/SelectOptionsWithSearch";
import BarbersSelector from "../../../components/BarbersSelector";
import { Separator } from "../../globalStyles";
import GoBackTitle from "../../../components/GoBackTitle";
import ListSchedules from "./components/ListSchedules";
import GenericButton from "../../../components/GenericButton";
import { ScreenSizeHook } from "../../../hooks/ScreenSizeHook";
import SmallText from "../../../components/texts/SmallText";
import LinkText from "../../../components/Link";

export const ReservationsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
`;

export const ReservationContainer = styled.div<{ status_agendamento_id?: number }>`
    display: flex;
    gap: 5px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: ${props => props.status_agendamento_id === 3 ? colors.gray : colors.white};
    outline: 1px solid ${colors.lightGray};

    &:hover {
        background-color: ${colors.lightGray};
        cursor: pointer;
    }

    &:active {
        outline: 2px solid ${colors.gray};
        
    }
`;

export const AusenceContainer = styled.div`
    display: flex;
    gap: 5px;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    background-color: ${colors.gray};

    &:hover {
        background-color: ${colors.lightGray};
        cursor: pointer;
    }

    &:active {
        outline: 2px solid ${colors.gray};
    }
`;

export const HourContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 10px;
    border-right: 2px solid ${colors.gray};
    min-width: 70px;
`;

export const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    gap: 5px;
`;

export const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid ${colors.lightGray};
`;

const TopContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`;

const AddScheduleContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
`;

const AddScheduleButton = styled.div`
    position: fixed;
    bottom: 120px;
    right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 50%;
    background-color: ${colors.primary};
    color: ${colors.white};
    font-size: 30px;
    cursor: pointer;
    transition: 0.3s;
    z-index: 2;
    box-shadow: 0px 0px 10px 0px ${colors.gray};

    &:hover {
        background-color: ${colors.primaryHover};
    }

    &:active {
        background-color: ${colors.primary};
    }
`;

const FilterIcon = styled(FaFilter)`
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        color: ${colors.primary};
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
    border: 1px solid ${colors.lightGray};
    user-select: none;

    &:hover {
        background-color: ${colors.lightGray};
    }

    &:active {
        background-color: ${colors.gray};
    }
`;

const VisualizationContainer = styled.div`
    display: flex;    
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    width: 100%;
`;

export default function Schedules() {
    const navigate = useNavigate();
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    const { user } = useContext(AuthContext)

    const [selectedDate, setSelectedDate] = useState(() => {
        const date = new Date();
        const localDate = new Date(date.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));
        return localDate;

        // const localStorageSelectedDate = localStorage.getItem('selectedDate')
        // if (localStorageSelectedDate) {
        //     return new Date(localStorageSelectedDate)
        // } else {
        //     return new Date()
        // }
    });
    const [reservationsAndAusences, setReservationsAndAusences] = React.useState<ReservationAusencesBarbersListType>([])
    const [isModalSchedulesOpen, setIsModalSchedulesOpen] = useState(false);
    const [isModalFiltersOpen, setIsModalFiltersOpen] = useState(false);

    const [barberShops, setBarberShops] = useState<BarbeariaType[]>([]);
    const [selectedBarberShop, setSelectedBarberShop] = useState<BarbeariaType | null>(null);

    const [barbers, setBarbers] = useState<BarberType[]>([]);
    const [selectedBarbers, setSelectedBarbers] = useState<BarbersType>([]);
    const [barberOpeningHours, setBarberOpeningHours] = useState<OpeningHourType[]>([]);
    const [isCalendarView, setIsCalendarView] = useState(
        localStorage.getItem('isCalendarView') ? JSON.parse(localStorage.getItem('isCalendarView') as string) : true 
    );

    const { isMobileView } = ScreenSizeHook()

    const [firstRender, setFirstRender] = useState(true)

    useEffect(() => {
        const getBarberShops = async () => {
            try {
                const response = await api.get(`/barbearias/user/${user?.id}`)                                
                if (response.data.length > 0) {
                    if (localStorage.getItem('selectedBarberShop')) {
                        setSelectedBarberShop(JSON.parse(localStorage.getItem('selectedBarberShop') as string))
                    } else {
                        setSelectedBarberShop(response.data[0])
                    }

                    setBarberShops(response.data)
                }
            } catch (error) {
            }
        }

        if (!user) return;
        setIsLoading(true)
        getBarberShops()
        hideLoadingWithDelay()
    }, [user])

    useEffect(() => {
        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${selectedBarberShop?.id}`)
                setBarbers(response.data)
                setSelectedBarbers(response.data)               

            } catch (error) {
                console.error("Failed to fetch barbers:", error);
            }
        }

        if (!selectedBarberShop?.id) return;
        setIsLoading(true)
        getBarbers()
        hideLoadingWithDelay()
    }, [selectedBarberShop?.id])

    useEffect(() => {
        const getBarberOpeningHours = async () => {
            try {
                const responses = await Promise.all(
                    selectedBarbers.map(barber =>
                        api.get(`/horarios_atendimento/barbeiro/${barber.barbearia_usuario_id}/dia_semana/${selectedDate.getDay()}`)
                    )
                );

                const data = responses.map(response => response.data);
                if (data.length === 0 || !data) {
                    setBarberOpeningHours([]);
                    return;
                }
                setBarberOpeningHours(data.flat());
            } catch (error) {
                console.error("Failed to fetch barber opening hours:", error);
            }
        };

        if (selectedBarbers.length > 0 && selectedDate) {           
            localStorage.setItem('selectedDate', selectedDate.toISOString())   
            getBarberOpeningHours();
        }

    }, [selectedBarbers, selectedDate]);

    useEffect(() => {
        try {
            setIsLoading(true)
            const localStorageSelectedDate = localStorage.getItem('selectedDate')
            if (localStorageSelectedDate) {

                if (localStorageSelectedDate >= new Date().toISOString()) {
                    setSelectedDate(new Date(localStorageSelectedDate))
                } else {
                    localStorage.removeItem('selectedDate')
                }
            }

            fetchReservationsAndAbsences()

            if (localStorage.getItem('selectedBarberShop')) {
                setSelectedBarberShop(JSON.parse(localStorage.getItem('selectedBarberShop') as string))                
            }
            setFirstRender(false)
        }
        catch (error) {
            console.error("Failed to fetch reservations and absences:", error);
        }
        finally {
            hideLoadingWithDelay()
        }
    }, []);

    useEffect(() => {
        if (selectedBarberShop && !firstRender) {
            localStorage.setItem('selectedBarberShop', JSON.stringify(selectedBarberShop))
        }

    }, [selectedBarberShop, firstRender]);

    useEffect(() => {
        fetchReservationsAndAbsences();
    }, [JSON.stringify(selectedBarbers), selectedDate]);

    useEffect(() => {
        localStorage.setItem('isCalendarView', JSON.stringify(isCalendarView))
    }, [isCalendarView]);

    const fetchReservationsAndAbsences = async () => {
        setIsLoading(true)
        const formattedDate = formatDateToYYYYMMDD(selectedDate);

        try {

            if (!selectedBarbers || selectedBarbers.length === 0) {
                setReservationsAndAusences([]);
                return;
            }

            const responses = await Promise.all(selectedBarbers.map(barber =>
                api.get(`/agendamentos_completos/date/with_ausences/${formattedDate}/${barber.barbearia_usuario_id}`)
            ));

            const data = responses.map((response, index) => ({
                barbearia_usuario_id: selectedBarbers[index].barbearia_usuario_id,
                agendamentos_ausencias: response.data
            }));

            setReservationsAndAusences(data)
        } catch (error) {
            console.error("Failed to fetch reservations and absences:", error);
        } finally {
            hideLoadingWithDelay()
        }
    };

    const getDayOfWeek = (date: Date) => {
        const days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
        return days[date.getDay()];
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text={`Agendamentos ${selectedBarberShop?.nome ? ' - ' + selectedBarberShop?.nome : ''}` + ` # ${getDayOfWeek(selectedDate)} - ${formatDateToDDMMYYYY(selectedDate)}`} />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="flex-start"
                align="flex-start"
                flexDirection="row"
                fullWidth={isMobileView}
            >
                <CenteredContentContainer
                    width="100%"
                    padding={isMobileView ? '20px 3px' : undefined}
                >
                    <TopContainer>
                        <SelectDate
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                            storeOnLocalStorage
                        />
                    </TopContainer>

                    <Separator />

                    <VisualizationContainer>
                        <LinkText onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/ausences`)} >
                            <NormalText color={colors.blue}>Ausencias</NormalText>
                        </LinkText>
                        <LinkText onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/reservations`)} >
                            <NormalText color={colors.blue}>Agendamentos</NormalText>
                        </LinkText>

                        <ButtonContainer onClick={() => { setIsCalendarView(!isCalendarView) }}>
                            <FaSlidersH />
                            {isMobileView ? (<SmallText bold>Mudar visualização</SmallText>) : (<NormalText bold>Mudar visualização</NormalText>)}
                        </ButtonContainer>
                        <ButtonContainer onClick={() => setIsModalFiltersOpen(true)}>
                            <FilterIcon />
                            {isMobileView ? (<SmallText bold>Filtros</SmallText>) : (<NormalText bold>Filtros</NormalText>)}
                        </ButtonContainer>
                        {!isMobileView && (
                            <AddScheduleContainer>
                                <ActionButton
                                    text="Novo Agendamento"
                                    onClick={() => setIsModalSchedulesOpen(true)}
                                    padding="15px"
                                />
                            </AddScheduleContainer>
                        )}
                    </VisualizationContainer>

                    {isMobileView && (
                        <AddScheduleButton onClick={() => setIsModalSchedulesOpen(true)}>
                            <FaPlus />
                        </AddScheduleButton>
                    )}

                    {isCalendarView ? (
                        <CalendarSchedule
                            reservationsAndAusences={reservationsAndAusences}
                            selectedBarberShop={selectedBarberShop}
                            selectedBarbers={selectedBarbers}
                            barberOpeningHours={barberOpeningHours}
                            selectedDate={selectedDate}
                        />

                    ) : (
                        <ListSchedules
                            reservationsAndAusences={reservationsAndAusences}
                            selectedBarbers={selectedBarbers}
                            selectedBarberShop={selectedBarberShop}
                            barberOpeningHours={barberOpeningHours}
                        />
                    )}

                    <GenericModal
                        open={isModalSchedulesOpen}
                        onClose={() => setIsModalSchedulesOpen(false)}
                        align="flex-start"
                    >
                        <MediumText>Agendamentos</MediumText>
                        <NormalText color={colors.mediumGray}>Selecione o tipo de agendamento</NormalText>

                        <Separator />

                        <NormalText>Reserva</NormalText>
                        <NormalText color={colors.mediumGray}>Reserve um agendamento em uma só data para seu cliente</NormalText>

                        <GenericButton
                            onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/reservations/new`)}
                            backgroundColor={colors.primary}
                            backgroundHoverColor={colors.primaryHover}
                        >
                            <MediumText color={colors.white}>Novo Agendamento</MediumText>
                        </GenericButton>

                        <NormalText>Reserva recorrente</NormalText>
                        <NormalText color={colors.mediumGray}>Reserve uma agenda que deve acontecer x vezes por mês</NormalText>

                        <GenericButton
                            onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/reservations/recurrent/new`)}
                            backgroundColor={colors.primary}
                            backgroundHoverColor={colors.primaryHover}
                        >
                            <MediumText color={colors.white}>Reserva com recorrencia</MediumText>
                        </GenericButton>

                        <NormalText>Ausencia</NormalText>
                        <NormalText color={colors.mediumGray}>Precisa se ausentar por algum motivo? Registre aqui para constar na sua agenda</NormalText>

                        <GenericButton
                            onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/ausences/ausence/`)}
                            backgroundColor={colors.secondary}
                            backgroundHoverColor={colors.secondary}
                        >
                            <MediumText>Agendar ausencia</MediumText>
                        </GenericButton>
                    </GenericModal>

                    <GenericModal
                        open={isModalFiltersOpen}
                        onClose={() => setIsModalFiltersOpen(false)}
                        align="flex-start"
                    >
                        <>
                            <MediumText bold>Filtros</MediumText>
                            {barberShops.length > 1 && (
                                <>
                                    <Separator />
                                    <NormalText bold>Seleção de barbearia</NormalText>
                                    <NormalText color={colors.mediumGray}>Selecione a barbearia que deseja visualizar os agendamentos</NormalText>
                                    <SelectionOptionsWithSearch
                                        label="Barbearia selecionada"
                                        options={barberShops.map(barberShop => barberShop.nome)}
                                        seletedOption={selectedBarberShop?.nome || ''}
                                        onSelect={(value) => setSelectedBarberShop(barberShops.find(barberShop => barberShop.nome === value) || null)}
                                        placeholder="Selecione uma barbearia"
                                    />
                                </>
                            )}

                            <Separator />
                            <NormalText bold>Seleção de barbeiros</NormalText>
                            <NormalText color={colors.mediumGray}>Selecione os barbeiros que deseja visualizar os agendamentos</NormalText>
                            <BarbersSelector
                                barbers={barbers}
                                selectedBarbers={selectedBarbers}
                                setSelectedBarbers={setSelectedBarbers}
                            />
                        </>
                    </GenericModal>
                </CenteredContentContainer>
            </DefaultContainer>

        </>
    )
}