import React from "react";
import styled from "styled-components";
import colors from "../../../style/colors";

type ContainerProps = {
    width?: string
    padding?: string
}

const Container = styled.div<ContainerProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 30px 60px 30px;
    gap: 30px;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray};
    border-radius: 5px;
    width: ${(props) => props.width || 'fit-content%'};
    margin: 20px 0;

    @media (max-width: 768px) {
        padding: ${(props) => props.padding || '50px 20px'};
        width: 100%;
    }

`

type Props = {
    children: React.ReactNode
    width?: string
    padding?: string
}

export default function CenteredContentContainer({ children, width, padding }: Props) {
    return (
        <Container 
            width={width}
            padding={padding}
        >
            {children}
        </Container>
    )
}


