import { useEffect, useState } from "react"

export function ScreenSizeHook() {
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 728)
    const [isDesktopView, setIsDesktopView] = useState(window.innerWidth >= 728)

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 728);
            setIsDesktopView(window.innerWidth >= 728);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return {
        isMobileView,
        isDesktopView
    }
}