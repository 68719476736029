import React, { useEffect } from "react"
import { AuthContext } from "../../../context/authContext"
import colors from "../../../style/colors"
import NormalText from "../../../components/texts/NormalText"
import { ConfirmationButton, GoBackButton, InputValue, LabelAndValueContainer, Separator } from "../../globalStyles"
import styled from "styled-components"
import { api } from "../../../config/api"
import { FaUser } from "react-icons/fa"
import { toast } from "react-toastify"
import { UsuarioFotoType, UsuarioType } from "../../../types/user"
import { LoadingHook } from "../../../hooks/LoadingHook"
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen"
import { useNavigate } from "react-router-dom"
import GoBackTitle from "../../../components/GoBackTitle"
import CenteredContentContainer from "../../../components/Containers/CenteredContentContainer"
import DefaultContainer from "../../../components/Containers/DefaultContainer"

const PhotoImageDiv = styled.div<{ src: string, fullSize?: boolean }>`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: ${props => props.fullSize ? "400px" : "275px"};
    max-height: ${props => props.fullSize ? "400px" : "275px"};
    border-radius: 5px;
    object-fit: cover;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${props => props.src});
        background-size: cover;
        background-position: center;
        transition: transform 0.3s ease;
    }

    &:hover:before {
        cursor: pointer;
        transform: scale(1.1);
    }

    &:active {
        outline: 4px solid ${colors.gray};
    }
`

export const BackgoundModalContainer = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    @media (min-width: 768px) { 
        align-items: center;
    }
`

const UpdatePhotoContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 3px;

    @media (min-width: 768px) {
        max-width: 430px;
        margin: 30px 20%;
    }
`
const UploadPhotoInput = styled.input`
    display: none;
`

const NoPhotoContainer = styled.div<{ fullSize?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${props => props.fullSize ? "400px" : "275px"};
    max-height: ${props => props.fullSize ? "400px" : "275px"};
    border-radius: 5px;
    background-color: ${colors.lightGray};
    transition: background-color 0.3s ease;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        outline: 4px solid ${colors.gray};
    }
`

export default function Profile() {
    const { user, signOut } = React.useContext(AuthContext)
    const [photo, setPhoto] = React.useState<string>('')
    const [userPhoto, setUserPhoto] = React.useState<UsuarioFotoType>({
        id: 0,
        usuario_id: 0,
        foto: '',
        data_criacao: ''
    })
    const [modalIsOpen, setModalIsOpen] = React.useState<boolean>(false)
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [userData, setUserData] = React.useState<UsuarioType>()

    useEffect(() => {
        if (user?.id) {

            const fetchPhoto = async () => {
                const response = await api.get(`/usuarios_foto/usuario/${user.id}`)
                if (response.status === 200) {
                    setUserPhoto(response.data)
                    const photo = response.data?.foto
                    if (photo) {
                        setPhoto(process.env.REACT_APP_API_URL + photo)
                    }
                }
            }

            const fetchUserData = async () => {
                const response = await api.get(`/users/${user.id}`)
                if (response.status === 200) {
                    setUserData(response.data)
                }
            }

            setIsLoading(true)
            if (user.id) {
                fetchUserData()
                fetchPhoto()
                setUserData(user)
            }
            hideLoadingWithDelay()
        }
    }, [user])

    const handleLogut = () => {
        signOut()
    }

    const uploadPhoto = async (file: File) => {
        const formData = new FormData()
        formData.append("files", file)

        try {
            setIsLoading(true)

            if (!file) {
                toast.error("Selecione uma foto")
                return
            }

            //post
            if (!userPhoto?.id) {
                const response = await api.post("/upload_files/", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                const full_path_photo = response?.data[0].full_path
                if (full_path_photo) {
                    const user_photo_data = {
                        usuario_id: user?.id,
                        foto: full_path_photo
                    }
                    const response = await api.post("/usuarios_foto/", user_photo_data)
                    if (response.status === 200) {
                        setPhoto(process.env.REACT_APP_API_URL + full_path_photo)
                        setUserPhoto(response.data)
                        setModalIsOpen(false)
                        toast.success("Foto criada com sucesso")
                    }
                }
            }

            // update
            else {
                const response = await api.delete(`/upload_files/`, {
                    data: {
                        file_path: userPhoto.foto
                    }
                })

                if (response.status === 200) {
                    const response = await api.post("/upload_files/", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    const full_path_photo = response?.data[0].full_path
                    if (full_path_photo) {
                        const user_photo_data = {
                            id: userPhoto.id,
                            usuario_id: userPhoto.usuario_id,
                            foto: full_path_photo
                        }
                        const response = await api.put(`/usuarios_foto/${userPhoto.id}`, user_photo_data)
                        if (response.status === 200) {
                            setPhoto(process.env.REACT_APP_API_URL + full_path_photo)
                            setUserPhoto(response.data)
                            setModalIsOpen(false)
                            toast.success("Foto atualizada com sucesso")
                        }
                    }
                }
            }
        } catch (error) {
            toast.error("Erro ao enviar a foto")
        } finally {
            hideLoadingWithDelay()
        }
    }

    const handleSave = async () => {
        setIsLoading(true)
        try {
            if (!userData?.nome) {
                toast.error("Nome é obrigatório")
                return
            }
            if (!userData?.email) {
                toast.error("Email é obrigatório")
                return
            }
            if (!userData?.telefone) {
                toast.error("Telefone é obrigatório")
                return
            }

            if (userData?.nome.length > 100 || userData?.email.length > 100 || userData?.telefone.length > 20) {
                toast.error("Os campos possuem tamanho máximo de 100 caracteres")
                return
            }

            const response = await api.put(`/users/${user?.id}`, userData)
            if (response.status === 200) {
                toast.success("Dados atualizados com sucesso")
            }
        } catch (error) {
            toast.error("Erro ao atualizar os dados")
        } finally {
            hideLoadingWithDelay()
        }
    }

    const handleUploadButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <GoBackTitle text="Dados de perfil" />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
            >

                <CenteredContentContainer
                    width="420px"
                >
                    <LoadingFreezeScreen isLoading={isLoading} />
                    {modalIsOpen && (
                        <BackgoundModalContainer >
                            <UpdatePhotoContainer>
                                {photo ? (
                                    <PhotoImageDiv
                                        src={photo}
                                        onClick={handleUploadButtonClick}
                                        fullSize
                                    />
                                ) : (
                                    <NoPhotoContainer
                                        fullSize
                                        onClick={handleUploadButtonClick}
                                    >
                                        <FaUser size={30} color={colors.darkGray} />
                                        <NormalText bold color={colors.darkGray}>Você não possui foto</NormalText>
                                    </NoPhotoContainer>
                                )}
                                <ConfirmationButton onClick={handleUploadButtonClick}>
                                    <UploadPhotoInput
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                uploadPhoto(e.target.files[0])
                                            }
                                        }}
                                    />
                                    <NormalText color="white" bold>Selecione sua foto</NormalText>
                                </ConfirmationButton>

                                <GoBackButton onClick={() => setModalIsOpen(false)}>
                                    <NormalText bold>Voltar</NormalText>
                                </GoBackButton>
                            </UpdatePhotoContainer>
                        </BackgoundModalContainer>
                    )}

                    {photo ? (
                        <PhotoImageDiv
                            src={photo}
                            onClick={() => setModalIsOpen(true)}
                        />
                    ) : (
                        <NoPhotoContainer
                            onClick={() => setModalIsOpen(true)}
                        >
                            <FaUser size={30} color={colors.darkGray} />
                            <NormalText bold color={colors.darkGray}>Adicione uma foto</NormalText>
                        </NoPhotoContainer>
                    )}

                    {userData && (
                        <>
                            <LabelAndValueContainer>
                                <NormalText color={colors.mediumGray} bold>ID</NormalText>
                                <InputValue
                                    type="text"
                                    value={user?.id}
                                    disabled
                                />
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                                <InputValue
                                    type="text"
                                    value={userData?.nome}
                                    onChange={(e) => setUserData({ ...userData, nome: e.target.value })}
                                />
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <NormalText color={colors.mediumGray} bold>Email</NormalText>
                                <InputValue
                                    type="text"
                                    value={userData?.email}
                                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                                />
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                                <InputValue
                                    type="text"
                                    value={userData?.telefone}
                                    onChange={(e) => setUserData({ ...userData, telefone: e.target.value })}
                                />
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <NormalText color={colors.mediumGray} bold>CPF</NormalText>
                                <InputValue
                                    type="text"
                                    value={userData?.cpf}
                                    onChange={(e) => setUserData({ ...userData, cpf: e.target.value })}
                                />
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <NormalText color={colors.mediumGray} bold>Nova senha</NormalText>
                                <InputValue
                                    type="password"
                                    value={userData?.senha}
                                    onChange={(e) => setUserData({ ...userData, senha: e.target.value })}
                                />
                            </LabelAndValueContainer>
                        </>
                    )}

                    <ConfirmationButton
                        onClick={handleSave}
                    >
                        <NormalText color="white" bold>Salvar</NormalText>
                    </ConfirmationButton>

                    <Separator />

                    <ConfirmationButton
                        onClick={handleLogut}
                    >
                        <NormalText color="white" bold>Sair da conta</NormalText>
                    </ConfirmationButton>
                </CenteredContentContainer>
            </DefaultContainer>
        </>
    )
}