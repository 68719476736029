import React from "react";
import styled from "styled-components";
import NormalText from "../texts/NormalText";
import colors from "../../style/colors";
import LinkText from "../Link";

const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 20%;
    justify-content: center;
    background-color: ${colors.lightGray};
    border: 1px solid ${colors.gray};
    gap: 20px;
    align-items: flex-start;

    @media (max-width: 768px) {
        padding: 20px 10px 100px 10px;
    }
`

const FooterColumns = styled.div`
    display: flex;
    gap: 20px;
`

const FooterColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 16px;
    align-items: flex-start;
`

export default function Footer() {
    return (
        <FooterContainer>
            <NormalText color={colors.darkGray}>© NoCorte, Inc.</NormalText>
            <FooterColumns>
                <FooterColumn>
                    <NormalText color={colors.darkGray} bold>Dúvidas?</NormalText>
                    <LinkText>
                        <NormalText>Quem somos</NormalText>
                    </LinkText>
                    <LinkText>
                        <NormalText>Contato</NormalText>
                    </LinkText>
                    <LinkText>
                        <NormalText>Email</NormalText>
                    </LinkText>
                </FooterColumn>
                <FooterColumn>
                    <NormalText color={colors.darkGray} bold>Redes Sociais</NormalText>
                    <LinkText>
                        <NormalText color={colors.darkGray}>Instagram</NormalText>
                    </LinkText>
                    <LinkText>
                        <NormalText color={colors.darkGray}>Facebook</NormalText>
                    </LinkText>
                </FooterColumn>
            </FooterColumns>
        </FooterContainer>
    )
}