import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ConfirmationButton, InputValue, LabelAndValueContainer } from "../../../globalStyles";
import NormalText from "../../../../components/texts/NormalText";
import colors from "../../../../style/colors";
import MediumText from "../../../../components/texts/MediumText";
import { api } from "../../../../config/api";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import { GetReservationsType } from "../../../../types/reservation";
import { getDateFromDateTime, getHourFromDateTime } from "../../../../functions/date";
import GoBackTitle from "../../../../components/GoBackTitle";
import DefaultContainer from "../../../../components/Containers/DefaultContainer";
import CenteredContentContainer from "../../../../components/Containers/CenteredContentContainer";
import { ClienteNaoCadastradoLocalStorageType } from "../../../../types/client";
import { toast } from "react-toastify";
import Table from "../../../../components/Table";
import { BarbeariaType } from "../../../../types/barbershop";

type formatedReservationType = {
    ID: number;
    Barbeiro: string;
    Endereço: string;
    Serviços: string;
    Data: string;
    Hora: string;
    Total: string;
}

export default function GetReservation() {
    const { id_barbershop } = useParams()
    const [phoneInput, setPhoneInput] = React.useState("")
    const navigate = useNavigate()
    const [reservations, setReservations] = React.useState<GetReservationsType>([])
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [barberShop, setBarberShop] = React.useState<BarbeariaType>()

    useEffect(() => {
        const getReservations = async (phone: string) => {
            try {
                if (!phone) {
                    toast.error("Digite um telefone")
                    return
                }
                const response = await api.get(`/agendamentos_completos/${phone}/${id_barbershop}`)
                if (!response.data) {
                    toast.warning("Nenhuma reserva encontrada")
                    return
                }
                setReservations(response.data)
            } catch (error) {
                console.log(error)
            }
        }

        const clienteNaoCadastrado = JSON.parse(localStorage.getItem("clienteNaoCadastrado") as string) as ClienteNaoCadastradoLocalStorageType
        if (clienteNaoCadastrado) {
            setPhoneInput(clienteNaoCadastrado.telefone)
            getReservations(clienteNaoCadastrado.telefone)
        }
    }, [])

    useEffect(() => {
        const getBarberShopData = async () => {
            const response = await api.get(`/barbearias/${id_barbershop}`)
            if (response) {
                setBarberShop(response.data)
            }
        }

        try {
            if (id_barbershop) {
                getBarberShopData()
            }
        } catch {
            toast.error('Falha ao obter barbearia')
        }

    }, [id_barbershop])

    const handleSearchReservation = () => {
        setIsLoading(true)
        try {
            if (!phoneInput) {
                toast.error("Digite um telefone")
            } else {
                api.get(`/agendamentos_completos/${phoneInput}/${id_barbershop}`)
                    .then(response => {
                        setReservations(response.data)
                    })
            }
        } catch (error) {
            console.log(error)
        }
        hideLoadingWithDelay()
    }

    const handleGoToReservation = (formatedReservation: formatedReservationType) => {
        const reservation = reservations.find(reservation => reservation.agendamento.id === formatedReservation.ID)
        if (!reservation) {
            toast.error("Erro ao buscar reserva")
            return
        }
        navigate(`/barbershop/${reservation.barbearia.id}/reservations/done/${reservation.agendamento.id}`)
    }

    const formatReservationsToArrayString = (reservations: GetReservationsType) => {
        return reservations.map(reservation => {
            return {
                "ID": reservation.agendamento.id,
                "Barbeiro": reservation.usuario.nome,
                "Endereço": reservation.barbearia.nome,
                "Serviços": reservation.servicos.map(service => service.nome).join(", "),
                "Data": getDateFromDateTime(reservation.agendamento.data_agendamento),
                "Hora": getHourFromDateTime(reservation.agendamento.data_agendamento),
                "Total": 'R$ ' + reservation.agendamento_servicos.reduce((acc, curr) => { return acc + curr.valor }, 0)
            }
        })
    }

    return <>
        <LoadingFreezeScreen isLoading={isLoading} />
        <GoBackTitle text={`Veja sua reserva ${barberShop?.nome ? ' - ' + barberShop?.nome : ''}`} />
        <DefaultContainer
            backgroundColor={colors.lightGray}
            borderColor={colors.gray}
            justify="flex-start"
            align="center"
        >
            <CenteredContentContainer
                width="700px"
            >
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                    <InputValue
                        type="tel"
                        placeholder="17999999999"
                        value={phoneInput}
                        onChange={(e) => setPhoneInput(e.target.value)}
                    />
                </LabelAndValueContainer>
                <ConfirmationButton
                    onClick={handleSearchReservation}
                >
                    <MediumText color="white" bold>Buscar reservas</MediumText>
                </ConfirmationButton>

                <Table
                    columnLabels={["ID", "Barbeiro", "Endereço", "Serviços", "Data", "Hora", "Total"]}
                    data={formatReservationsToArrayString(reservations)}
                    columns={["ID", "Barbeiro", "Endereço", "Serviços", "Data", "Hora", "Total"]}
                    onItemClick={(reservation) => handleGoToReservation(reservation)}
                />
            </CenteredContentContainer>
        </DefaultContainer>
    </>
}