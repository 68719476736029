import React from 'react';
import styled from 'styled-components';
import colors from '../../style/colors';
import NormalText from '../texts/NormalText';
import { ScreenSizeHook } from '../../hooks/ScreenSizeHook';
import SmallText from '../texts/SmallText';

interface TableProps {
    data: any[];
    columns: string[];
    columnLabels: string[];
    onItemClick: (item: any) => void;
}

const TableContainer = styled.div`
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 18px;
    text-align: left;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const StyledTh = styled.th`
    background-color: ${colors.primary};
    padding: 12px;
    border: 1px solid #ddd;
    color: ${colors.white};
    min-width: max-content;
    white-space: nowrap;

    @media (max-width: 768px) {
        padding: 8px;
    }
`;

const StyledTd = styled.td`
    padding: 12px;
    border: 1px solid #ddd;
    min-width: max-content;
    white-space: nowrap;

    @media (max-width: 768px) {
        padding: 8px;
    }
`;

const StyledTr = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }

    &:hover {
        background-color: #f1f1f1;
    }
`;

const Table: React.FC<TableProps> = ({ data, columns, columnLabels, onItemClick }) => {
    const { isMobileView } = ScreenSizeHook();

    return (
        <TableContainer>
            <StyledTable>
                <thead>
                    <tr>
                        {columnLabels.map((label, index) => (
                            <StyledTh key={index}>
                                <NormalText color={colors.white}>{label}</NormalText>
                            </StyledTh>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, rowIndex) => (
                        <StyledTr key={rowIndex} onClick={() => onItemClick(item)}>
                            {columns.map((column, colIndex) => (
                                <StyledTd key={colIndex}>
                                    {isMobileView ? (
                                        <NormalText color={colors.darkGray}>
                                            {item[column]}
                                        </NormalText>
                                    ) : (
                                        <SmallText color={colors.darkGray}>
                                            {item[column]}
                                        </SmallText>
                                    )}

                                </StyledTd>
                            ))}
                        </StyledTr>
                    ))}
                </tbody>
            </StyledTable>
        </TableContainer>
    );
};

export default Table;