import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import { BarbersType } from "../../types/barber";
import { NoImageBarber } from "../../pages/globalStyles";
import { api_url } from "../../config/api";
import MediumText from "../texts/MediumText";
import { ScreenSizeHook } from "../../hooks/ScreenSizeHook";
import NormalText from "../texts/NormalText";
import SmallText from "../texts/SmallText";

const BarbersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
`;

const BarberContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 5px;
    background-color: ${colors.white};
    border: 2px solid ${colors.lightGray};
    cursor: pointer;
    user-select: none;
    transition: border 0.3s;

    &:hover {
        border: 2px solid ${colors.mediumGray};
    }

    &:active {
        border: 2px solid ${colors.lightGray};
        /* outline: 1px solid ${colors.gray}; */
    }
`;

const BarberContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
`;

const BarberImage = styled.img`
    width: 80px;
    height: 80px;
    border-radius: 3px;
    border: 1px solid ${colors.lightGray};
    object-fit: cover;

    /* @media (max-width: 768px) {
        width: 50px;
        height: 50px;
    } */
`;

type BarbersSelectorProps = {
    barbers: BarbersType;
}

export default function BarbersViewer({ barbers }: BarbersSelectorProps) {

    const { isMobileView } = ScreenSizeHook();

    return (
        <>
            <BarbersContainer>
                {barbers.map((barber, index) => (
                    <BarberContainer
                        key={index}                     
                    >
                        <BarberImage src={barber.usuario_foto ? api_url + barber.usuario_foto.foto : NoImageBarber} alt={barber.nome} />
                        <BarberContent>
                            {/* <MediumText bold>{barber.nome}</MediumText> */}
                            {isMobileView ? <SmallText>{barber.nome}</SmallText> : <MediumText bold>{barber.nome}</MediumText>}
                        </BarberContent>
                    </BarberContainer>
                ))}
            </BarbersContainer>
        </>
    )
}