import React from "react";
import colors from "../../../../../style/colors";
import BigText from "../../../../../components/texts/BigText";
import NormalText from "../../../../../components/texts/NormalText";
import { InputValue, LabelAndValueContainer } from "../../../../globalStyles";
import { BarbeariaType } from "../../../../../types/barbershop";
import LinkText from "../../../../../components/Link";
import { useNavigate } from "react-router-dom";

type InformationTabProps = {
    barberShopInformation?: BarbeariaType;
    setBarberShopInformation: (barberShopInformation: BarbeariaType) => void;
}

export default function InformationTab({ barberShopInformation, setBarberShopInformation }: InformationTabProps) {
    const navigate = useNavigate();
    return (
        <>
            <BigText>Informações</BigText>
            <NormalText color={colors.mediumGray}>Aqui consta informações básicas de sua barbearia</NormalText>
            {barberShopInformation && (
                <>
                    <LinkText onClick={() => navigate(`/barbershop/${barberShopInformation.id}`)}>
                        <NormalText color={colors.blue} >Ver barbearia</NormalText>
                    </LinkText>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Nome</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Nome da barbearia aqui"
                            value={barberShopInformation?.nome}
                            onChange={(e) => setBarberShopInformation({ ...barberShopInformation, nome: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Descricao</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Seu Endereço aqui"
                            value={barberShopInformation?.descricao}
                            onChange={(e) => setBarberShopInformation({ ...barberShopInformation, descricao: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Telefone</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Seu Telefone aqui"
                            value={barberShopInformation?.telefone}
                            onChange={(e) => setBarberShopInformation({ ...barberShopInformation, telefone: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>CNPJ</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Seu CNPJ aqui"
                            value={barberShopInformation?.cnpj}
                            onChange={(e) => setBarberShopInformation({ ...barberShopInformation, cnpj: e.target.value })}
                        />
                    </LabelAndValueContainer>
                </>
            )}
        </>
    );
}