import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LoadingFreezeScreen from "../../../../../components/LoadingFreezeScreen";
import GoBackTitle from "../../../../../components/GoBackTitle";
import DefaultContainer from "../../../../../components/Containers/DefaultContainer";
import colors from "../../../../../style/colors";
import CenteredContentContainer from "../../../../../components/Containers/CenteredContentContainer";
import StepsItems from "../../../../../components/StepsItems";
import { LoadingHook } from "../../../../../hooks/LoadingHook";
import { ServicesType } from "../../../../../types/services";
import { api } from "../../../../../config/api";
import { useNavigate, useParams } from "react-router-dom";
import ServiceTab from "../components/ServiceTab";
import MediumText from "../../../../../components/texts/MediumText";
import { FloattingContainer, GoBackButton, LabelAndValueContainer, Separator, Service, Services, ServiceValueAndTimeContainer, TotalContainer } from "../../../../globalStyles";
import NormalText from "../../../../../components/texts/NormalText";
import SmallText from "../../../../../components/texts/SmallText";
import { BarbersType, BarberType } from "../../../../../types/barber";
import { composeDateAndHour, formatDateToDDMMYYYYFromString, formatDateToYYYYMMDD } from "../../../../../functions/date";
import BarbersViewer from "../../../../../components/BarbersViewer";
import InformationsTab from "../components/InformationsTab";
import ConfirmationTab from "../components/ConfirmationTab";
import { ScreenSizeHook } from "../../../../../hooks/ScreenSizeHook";
import ConfirmationButton from "../../../../../components/ConfirmationButton";
import { toast } from "react-toastify";
import PeriodTab from "../components/RecurrenceTabs/PreferencesTab";
import { AgendamentoType, RecurrentReservationsScheduleType, RecurrentReservationsType, RecurrentReservationType, ReservationAusencesConflictListType } from "../../../../../types/reservation";
import RecurrentScheduleTab from "../components/RecurrenceTabs/RecurrentScheduleTab";
import { BarbeariaType } from "../../../../../types/barbershop";

const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: flex-start;
    width: 100%;
`;

export default function RecurrentReservation() {
    const { id_barbershop } = useParams()

    const [steps, setSteps] = useState([
        { name: "Preferências", isActive: true },
        { name: "Serviços", isActive: false },
        { name: "Agendamento", isActive: false },
        { name: "Informação", isActive: false },
        { name: "Confirmação", isActive: false },
    ]);

    const [services, setServices] = useState<ServicesType>([])
    const [selectedServices, setSelectedServices] = useState<ServicesType>([])
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [barbers, setBarbers] = useState<BarbersType>([])
    const [selectedBarber, setSelectedBarber] = useState<BarberType>()
    const [selectedHour, setSelectedHour] = useState<String>('')
    const [informations, setInformations] = useState({
        nome: '',
        telefone: ''
    })
    const [isFloattingVisible, setIsFloattingVisible] = useState(true);
    const [actionButtonSettings, setActionButtonSettings] = useState({
        text: "Continuar",
        disabled: false,
    })

    const durationsInMonthsOptions = [
        '1 mês',
        '3 meses',
        '6 meses',
        '1 ano',
    ]

    const [barberPreference, setBarberPreference] = useState<BarbersType>([]);

    const [durationInMonths, setDurationInMonths] = useState('');
    const [dayOfWeekPreference, setDayOfWeekPreference] = useState<number>(1);
    const [hourPreference, setHourPreference] = useState("18:00");

    const [recurrentReservationsSchedules, setRecurrentReservationsSchedules] = useState<RecurrentReservationsScheduleType>([])
    const [selectedRecurrentReservationType, setSelectedRecurrentReservationType] = useState<RecurrentReservationType>();
    const [recurrentReservationTypes, setRecurrentReservationTypes] = useState<RecurrentReservationsType>([])
    const [conflicsWithAllSchedules, setConflictWithAllSchedules] = useState<ReservationAusencesConflictListType>([]);

    const [initialDate, setInitialDate] = useState<Date>(new Date());
    const [finalDate, setFinalDate] = useState<Date>(new Date());

    const [barberShop, setBarberShop] = React.useState<BarbeariaType>()

    const { isLoading, hideLoadingWithDelay, setIsLoading } = LoadingHook();
    const { isMobileView } = ScreenSizeHook()

    const reservationButtonsRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const handleTabClick = (tabName: string) => {
        setSteps(steps.map(step => ({
            ...step,
            isActive: step.name === tabName
        })));
    };

    const getDayOfWeekName = (day: number) => {
        switch (day) {
            case 0:
                return 'Domingo';
            case 1:
                return 'Segunda-feira';
            case 2:
                return 'Terça-feira';
            case 3:
                return 'Quarta-feira';
            case 4:
                return 'Quinta-feira';
            case 5:
                return 'Sexta-feira';
            case 6:
                return 'Sábado';
            default:
                return '';
        }
    }

    const renderContent = () => {
        switch (steps.find(step => step.isActive)?.name) {
            case "Preferências":
                return (<PeriodTab
                    durationInMonths={durationInMonths}
                    setDurationInMonths={setDurationInMonths}
                    dayOfWeekPreference={dayOfWeekPreference}
                    setDayOfWeekPreference={setDayOfWeekPreference}
                    hourPreference={hourPreference}
                    setHourPreference={setHourPreference}
                    durationsInMonthsOptions={durationsInMonthsOptions}
                    barberPreference={barberPreference}
                    setBarberPreference={setBarberPreference}
                    barbers={barbers}
                    recurrentReservationTypes={recurrentReservationTypes}
                    setRecurrentReservationTypes={setRecurrentReservationTypes}
                    selectedRecurrentReservationType={selectedRecurrentReservationType}
                    setSelectedRecurrentReservationType={setSelectedRecurrentReservationType}
                />);
            case "Serviços":
                return (
                    <ServiceTab
                        services={services}
                        setServices={setServices}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                    />
                );
            case "Agendamento":
                return (
                    <RecurrentScheduleTab
                        recurrentReservationsSchedules={recurrentReservationsSchedules}
                        setRecurrentReservationsSchedules={setRecurrentReservationsSchedules}
                        selectedServices={selectedServices}
                        barbers={barbers}
                        conflicsWithAllSchedules={conflicsWithAllSchedules}
                        setConflictWithAllSchedules={setConflictWithAllSchedules}
                    />
                );
            case "Informação":
                return (
                    <InformationsTab
                        informations={informations}
                        setInformations={setInformations}
                    />
                );
            case "Confirmação":
                return (<ConfirmationTab />);
            default:
                return (
                    <div>
                        <h1>Erro</h1>
                    </div>
                );
        }
    }

    useEffect(() => {
        const getServices = async () => {
            try {
                const response = await api.get(`/servicos/barbearia/${id_barbershop}`)
                setServices(response.data)
            } catch (error) {
            }
        }

        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${id_barbershop}`)
                setBarbers(response.data)
            } catch (error) {
            }
        }

        const getBarberShopData = async () => {
            const response = await api.get(`/barbearias/${id_barbershop}`)
            if (response) {
                setBarberShop(response.data)
            }
        }

        if (!id_barbershop) return
        setIsLoading(true)
        getServices()
        getBarbers()
        getBarberShopData()
        hideLoadingWithDelay()
    }, [id_barbershop])

    useEffect(() => {
        setSelectedHour('')
    }, [selectedServices, selectedDate, selectedBarber])

    const handleContinue = async () => {
        const activeStepIndex = steps.findIndex(step => step.isActive)
        const nextStepIndex = activeStepIndex + 1

        if (activeStepIndex === 0) {
            handleLoadReservationsByDateAndRecurrentType()
            handleTabClick(steps[nextStepIndex].name)
        }
        else if (nextStepIndex < steps.length) {
            handleTabClick(steps[nextStepIndex].name)
        } else {
            await handleFinish()
        }

        // navigate to the top of the page
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsFloattingVisible(!entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        const currentRef = reservationButtonsRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    useEffect(() => {
        const previousClientInformations = JSON.parse(localStorage.getItem('clienteNaoCadastrado') || '{}')

        if (previousClientInformations.nome && previousClientInformations.telefone)
            setInformations(previousClientInformations)
    }, [])

    const handleGoBack = () => {
        const activeStepIndex = steps.findIndex(step => step.isActive)
        const previousStepIndex = activeStepIndex - 1

        if (previousStepIndex >= 0) {
            handleTabClick(steps[previousStepIndex].name)
        }
    }

    const getEstimatedTime = () => {
        let estimatedTime = 0
        selectedServices.forEach(service => {
            estimatedTime += service.tempo_estimado
        })

        return estimatedTime
    }

    useEffect(() => {
        const activeStepIndex = steps.findIndex(step => step.isActive)
        const isLastStep = activeStepIndex === steps.length - 1

        if (activeStepIndex === 0) {
            setActionButtonSettings(prev => ({ ...prev, disabled: !durationInMonths || !dayOfWeekPreference || !hourPreference || barberPreference.length === 0 }))
        }
        else if (activeStepIndex === 1) {
            setActionButtonSettings(prev => ({ ...prev, disabled: selectedServices.length === 0 }))
        } else if (activeStepIndex === 2) {
            const hasConflict = conflicsWithAllSchedules.map(conflict => conflict.agendamentos_ausencias.length > 0).reduce((acc, hasConflict) => acc || hasConflict, false)
            setActionButtonSettings(prev => ({ ...prev, disabled: hasConflict }))
        } else if (activeStepIndex === 3) {
            setActionButtonSettings(prev => ({ ...prev, disabled: !informations.nome || !informations.telefone }))
        } else {
            setActionButtonSettings(prev => ({ ...prev, disabled: false }))
        }

        if (isLastStep) {
            setActionButtonSettings(prev => ({ ...prev, text: "Finalizar" }))
        } else {
            setActionButtonSettings(prev => ({ ...prev, text: "Continuar" }))
        }

    }, [
        steps,
        selectedServices,
        selectedBarber,
        selectedDate,
        selectedHour,
        informations,
        durationInMonths,
        dayOfWeekPreference,
        hourPreference,
        barberPreference,
        conflicsWithAllSchedules
    ])


    const handleFinish = async () => {

        if (!informations.nome || !informations.telefone) {
            toast.error("Preencha seu nome e telefone por favor")
            hideLoadingWithDelay()
            return
        }

        const phoneRegex = /^\d{2}\d{4,5}\d{4}$/
        if (!phoneRegex.test(informations.telefone)) {
            toast.error("Telefone inválido")
            hideLoadingWithDelay()
            return
        }

        const recurrentReservationsData = recurrentReservationsSchedules.map(schedule => {
            const [year, month, day] = schedule.data.split('-').map(Number);
            const date = new Date(Date.UTC(year, month - 1, day));
            date.setUTCHours(0, 0, 0, 0);

            const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);

            return {
                agendamento: {
                    barbearia_usuario_id: schedule.barbearia_usuario.barbearia_usuario_id,
                    data_agendamento: composeDateAndHour(localDate, schedule.hora)
                },
                cliente_nao_cadastrado: {
                    nome: informations.nome,
                    telefone: informations.telefone
                },
                agendamento_servicos: selectedServices.map(service => ({
                    servico_id: service.id,
                    valor: service.preco,
                    tempo_estimado: service.tempo_estimado
                }))
            }
        })

        try {
            setIsLoading(true)
            const response = await api.post('/agendamentos_completos/many', recurrentReservationsData)

            toast.success("Reserva realizada com sucesso")
            const agendamentoReturn = response.data.agendamentos as AgendamentoType[]

            const agendamentosIds = agendamentoReturn.map(agendamento => agendamento.id)
            const agendamentoRecorrenteObj = {
                tipo_agendamento_recorrente_id: selectedRecurrentReservationType?.id,
                data_inicio: formatDateToYYYYMMDD(initialDate),
                data_fim: formatDateToYYYYMMDD(finalDate),
                agendamentos_id: agendamentosIds
            }

            const responseRecorrente = await api.post('/agendamentos_recorrentes', agendamentoRecorrenteObj)
            if (responseRecorrente.status === 201) {
                toast.success("Reserva recorrente realizada com sucesso")
            }

            hideLoadingWithDelay()
            navigate(`/barbershop/${id_barbershop}/reservations/recurrent/done/${responseRecorrente.data.agendamento_recorrente.id}`)

        } catch (error) {
            console.log(error)
            toast.error("Ocorreu um erro ao tentar finalizar sua reserva")
        } finally {
            hideLoadingWithDelay()
        }
    }

    useEffect(() => {
        const getRecurrentReservationTypes = async () => {
            try {
                const response = await api.get('/tipos_agendamento_recorrente/')
                setRecurrentReservationTypes(response.data)
            } catch (error) {
            }
        }

        setIsLoading(true)
        getRecurrentReservationTypes()
        hideLoadingWithDelay()
    }, [])

    useEffect(() => {
        const initialDate = new Date();
        const finalDate = new Date();
        const durationInMonthsMap = {
            '1 mês': 1,
            '3 meses': 3,
            '6 meses': 6,
            '1 ano': 12,
        };

        const monthsToAdd = durationInMonthsMap[durationInMonths as keyof typeof durationInMonthsMap] || 0;
        finalDate.setMonth(initialDate.getMonth() + monthsToAdd);

        setInitialDate(initialDate);
        setFinalDate(finalDate);
    }, [durationInMonths])

    const handleLoadReservationsByDateAndRecurrentType = () => {
        setIsLoading(true);
        setRecurrentReservationsSchedules([]);
        const schedules: {
            data: string;
            hora: string;
            barbearia_usuario: {
                barbearia_usuario_id: number;
                nome: string;
            };
        }[] = [];

        const recurrentTypeMap = {
            '4 vezes (Semanal)': 4,
            '1 vez (Mensal)': 1,
            '2 vezes (Quinzenal)': 2,
        };

        const quantityOfWeeks = recurrentTypeMap[selectedRecurrentReservationType?.nome as keyof typeof recurrentTypeMap] || 0;

        const currentDate = new Date(initialDate);

        while (currentDate < finalDate) {
            for (let i = 0; i < quantityOfWeeks; i++) {
                const scheduleDate = new Date(currentDate);
                scheduleDate.setDate(currentDate.getDate() + i * (30 / quantityOfWeeks));

                while (scheduleDate.getDay() !== dayOfWeekPreference) {
                    scheduleDate.setDate(scheduleDate.getDate() + 1);
                }

                const [hour, minute] = hourPreference.split(':').map(Number);
                scheduleDate.setHours(hour, minute);

                const normalizedScheduleDate = formatDateToYYYYMMDD(scheduleDate);
                const normalizedInitialDate = formatDateToYYYYMMDD(initialDate);
                const normalizedFinalDate = formatDateToYYYYMMDD(finalDate);

                if (normalizedScheduleDate >= normalizedInitialDate && normalizedScheduleDate <= normalizedFinalDate) {
                    schedules.push({
                        data: scheduleDate.toISOString().split('T')[0],
                        hora: hourPreference,
                        barbearia_usuario: {
                            barbearia_usuario_id: barberPreference[0].id,
                            nome: barberPreference[0].nome
                        }
                    });
                }
            }
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
        setRecurrentReservationsSchedules(schedules);
        hideLoadingWithDelay();
    }


    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text={`Novo agendamento recorrente ${barberShop?.nome ? ' - ' + barberShop?.nome : ''}`} />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="center"
                align="flex-start"
                flexDirection="row"
            >
                <CenteredContentContainer
                    width="720px"
                >
                    <StepsItems
                        steps={steps}
                        onStepClick={handleTabClick}
                        disabled
                    />
                    {renderContent()}
                </CenteredContentContainer>
                <CenteredContentContainer
                    width="420px"
                >
                    <MediumText bold>Sua reserva</MediumText>
                    <Separator width="60px" height="3px" />

                    <GroupContainer>
                        <NormalText align="left" bold>Periodo</NormalText>
                        <TotalContainer flexDirection="row">
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray}>Duração</SmallText>
                                <NormalText color={colors.darkGray} bold >{durationInMonths}</NormalText>
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray}>Agendamento por mês</SmallText>
                                <NormalText color={colors.darkGray} bold >{selectedRecurrentReservationType?.nome}</NormalText>
                            </LabelAndValueContainer>
                        </TotalContainer>
                        <TotalContainer flexDirection="row">
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray}>Dia de preferencia</SmallText>
                                <NormalText color={colors.darkGray} bold >{getDayOfWeekName(dayOfWeekPreference)}</NormalText>
                            </LabelAndValueContainer>
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray}>Hora de preferencia</SmallText>
                                <NormalText color={colors.darkGray} bold >{hourPreference}</NormalText>
                            </LabelAndValueContainer>
                        </TotalContainer>
                        <SmallText color={colors.mediumGray}>Barbeiro de preferencia</SmallText>
                        {barberPreference && <BarbersViewer barbers={barberPreference} />}
                    </GroupContainer>

                    <Separator width="60px" height="3px" />

                    <GroupContainer>
                        <NormalText align="left" bold>Serviços</NormalText>
                        {selectedServices.length > 0 && (
                            <>
                                <Services>
                                    {selectedServices.map((service, index) => (
                                        <Service
                                            key={index}
                                            isSelected={false}
                                            disabled
                                        >
                                            <MediumText color={colors.darkGray} bold>{service.nome}</MediumText>

                                            <ServiceValueAndTimeContainer>
                                                <MediumText color={colors.darkGray} bold>R$ {service.preco},00</MediumText>
                                                <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                                            </ServiceValueAndTimeContainer>
                                        </Service>
                                    ))}
                                </Services>
                            </>
                        )}
                    </GroupContainer>


                    <GroupContainer>
                        <NormalText align="left" bold>Agendamentos</NormalText>
                        {recurrentReservationsSchedules.length > 0 && (
                            <>
                                {recurrentReservationsSchedules.map((schedule, index) => (
                                    <TotalContainer
                                        key={index}
                                        flexDirection="row"
                                    >
                                        <LabelAndValueContainer>
                                            <SmallText color={colors.mediumGray} >Data</SmallText>
                                            <SmallText color={colors.darkGray} bold >{formatDateToDDMMYYYYFromString(schedule.data)}</SmallText>
                                        </LabelAndValueContainer>
                                        <LabelAndValueContainer>
                                            <SmallText color={colors.mediumGray}>Hora</SmallText>
                                            <SmallText color={colors.darkGray} bold>{schedule.hora}</SmallText>
                                        </LabelAndValueContainer>
                                        <LabelAndValueContainer>
                                            <SmallText color={colors.mediumGray} >Barbeiro</SmallText>
                                            <SmallText color={colors.darkGray} bold >{schedule.barbearia_usuario.nome}</SmallText>
                                        </LabelAndValueContainer>
                                    </TotalContainer>
                                ))}
                            </>
                        )}
                    </GroupContainer>


                    <GroupContainer>
                        <NormalText align="left" bold>Valor total</NormalText>

                        <LabelAndValueContainer>
                            <SmallText color={colors.mediumGray}>Valor Total</SmallText>
                            <NormalText color={colors.darkGray} bold>R$ {(recurrentReservationsSchedules.length) * selectedServices.reduce((acc, service) => acc + service.preco, 0)}</NormalText>
                        </LabelAndValueContainer>
                    </GroupContainer>

                    <GroupContainer>
                        <NormalText align="left" bold>Informações do cliente</NormalText>
                        <TotalContainer
                            flexDirection="row"
                        >
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray} bold>Nome</SmallText>
                                <NormalText color={colors.darkGray}>{informations.nome}</NormalText>
                            </LabelAndValueContainer>
                            <LabelAndValueContainer
                                align="flex-end"
                            >
                                <SmallText color={colors.mediumGray} bold>Telefone</SmallText>
                                <NormalText color={colors.darkGray}>{informations.telefone}</NormalText>
                            </LabelAndValueContainer>
                        </TotalContainer>
                    </GroupContainer>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            width: "100%",
                        }}
                        ref={reservationButtonsRef}
                    >

                        <ConfirmationButton
                            onClick={handleContinue}
                            disabled={actionButtonSettings.disabled}
                            disabledMessage="Selecione ou preencha os campos para seguir por favor :)"
                        >
                            <MediumText color="white" bold>{actionButtonSettings.text}</MediumText>
                        </ConfirmationButton>
                        <GoBackButton
                            onClick={handleGoBack}
                        >
                            <MediumText color={colors.primary} bold>Voltar</MediumText>
                        </GoBackButton>
                    </div>
                </CenteredContentContainer>
            </DefaultContainer>
            {isMobileView && (
                <FloattingContainer isVisible={isFloattingVisible}>
                    <GoBackButton
                        onClick={handleGoBack}
                    >
                        <MediumText color={colors.primary} bold>Voltar</MediumText>
                    </GoBackButton>
                    <ConfirmationButton
                        onClick={handleContinue}
                        disabled={actionButtonSettings.disabled}
                        disabledMessage="Selecione ou preencha os campos para seguir por favor :)"
                    >
                        <MediumText color="white" bold>{actionButtonSettings.text}</MediumText>
                    </ConfirmationButton>
                </FloattingContainer>
            )}
        </>
    );
}