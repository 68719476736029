import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import GoBackTitle from "../../../../components/GoBackTitle";
import DefaultContainer from "../../../../components/Containers/DefaultContainer";
import colors from "../../../../style/colors";
import CenteredContentContainer from "../../../../components/Containers/CenteredContentContainer";
import StepsItems from "../../../../components/StepsItems";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import { ServicesType } from "../../../../types/services";
import { api } from "../../../../config/api";
import { useNavigate, useParams } from "react-router-dom";
import ServiceTab from "./components/ServiceTab";
import MediumText from "../../../../components/texts/MediumText";
import { FloattingContainer, GoBackButton, LabelAndValueContainer, Separator, Service, Services, ServiceValueAndTimeContainer, TotalContainer } from "../../../globalStyles";
import NormalText from "../../../../components/texts/NormalText";
import SmallText from "../../../../components/texts/SmallText";
import ScheduleTab from "./components/ScheduleTab";
import { BarbersType, BarberType } from "../../../../types/barber";
import { composeDateAndHour, formatDateToYYYYMMDD } from "../../../../functions/date";
import BarbersViewer from "../../../../components/BarbersViewer";
import InformationsTab from "./components/InformationsTab";
import ConfirmationTab from "./components/ConfirmationTab";
import { ScreenSizeHook } from "../../../../hooks/ScreenSizeHook";
import ConfirmationButton from "../../../../components/ConfirmationButton";
import { toast } from "react-toastify";
import { BarbeariaType } from "../../../../types/barbershop";

const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: flex-start;
    width: 100%;
`;

export default function Reservation() {
    const { id_barbershop } = useParams()

    const [steps, setSteps] = useState([
        { name: "Serviços", isActive: true },
        { name: "Agendamento", isActive: false },
        { name: "Informação", isActive: false },
        { name: "Confirmação", isActive: false },
    ]);

    const [services, setServices] = useState<ServicesType>([])
    const [selectedServices, setSelectedServices] = useState<ServicesType>([])
    const [selectedDate, setSelectedDate] = useState<Date>(new Date())
    const [barbers, setBarbers] = useState<BarbersType>([])
    const [selectedBarber, setSelectedBarber] = useState<BarberType>()
    const [hoursAvailable, setHoursAvailable] = useState<String[]>([])
    const [selectedHour, setSelectedHour] = useState<String>('')
    const [barberShop, setBarberShop] = React.useState<BarbeariaType>()
    const [informations, setInformations] = useState({
        nome: '',
        telefone: ''
    })
    const [isFloattingVisible, setIsFloattingVisible] = useState(true);
    const [actionButtonSettings, setActionButtonSettings] = useState({
        text: "Continuar",
        disabled: false,
    })

    const { isLoading, hideLoadingWithDelay, setIsLoading } = LoadingHook();
    const { isMobileView } = ScreenSizeHook()

    const reservationButtonsRef = useRef<HTMLDivElement>(null);

    const navigate = useNavigate();

    const handleTabClick = (tabName: string) => {
        setSteps(steps.map(step => ({
            ...step,
            isActive: step.name === tabName
        })));
    };

    const renderContent = () => {
        switch (steps.find(step => step.isActive)?.name) {
            case "Serviços":
                return (
                    <ServiceTab
                        services={services}
                        setServices={setServices}
                        selectedServices={selectedServices}
                        setSelectedServices={setSelectedServices}
                    />
                );
            case "Agendamento":
                return (
                    <ScheduleTab
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                        barbers={barbers}
                        selectedBarber={selectedBarber}
                        setSelectedBarber={setSelectedBarber}
                        hoursAvailable={hoursAvailable}
                        selectedHour={selectedHour}
                        setSelectedHour={setSelectedHour}
                    />
                );
            case "Informação":
                return (
                    <InformationsTab
                        informations={informations}
                        setInformations={setInformations}
                    />
                );
            case "Confirmação":
                return (<ConfirmationTab />);
            default:
                return (
                    <div>
                        <h1>Erro</h1>
                    </div>
                );
        }
    }

    useEffect(() => {
        const getServices = async () => {
            try {
                const response = await api.get(`/servicos/barbearia/${id_barbershop}`)
                setServices(response.data)
            } catch (error) {
            }
        }

        const getBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${id_barbershop}`)
                setBarbers(response.data)
            } catch (error) {
            }
        }

        const getBarberShopData = async () => {
            const response = await api.get(`/barbearias/${id_barbershop}`)
            if (response) {
                setBarberShop(response.data)
            }
        }

        if (!id_barbershop) return
        setIsLoading(true)
        getServices()
        getBarbers()
        getBarberShopData()
        hideLoadingWithDelay()
    }, [id_barbershop])

    useEffect(() => {
        setIsLoading(true)
        const getHoursAvailable = async () => {
            try {
                const formattedDate = formatDateToYYYYMMDD(selectedDate);
                const response = await api.get(`/horarios/?barbearia_usuario_id=${selectedBarber?.barbearia_usuario_id}&date=${formattedDate}&tempo_estimado_servicos=${getEstimatedTime()}`)
                setHoursAvailable(response.data);
            } catch (error) {
            }
        }

        if (selectedBarber && selectedDate) getHoursAvailable()
        hideLoadingWithDelay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedBarber, selectedDate, id_barbershop])

    useEffect(() => {
        setSelectedHour('')
    }, [selectedServices, selectedDate, selectedBarber])

    const handleContinue = async () => {
        const activeStepIndex = steps.findIndex(step => step.isActive)
        const nextStepIndex = activeStepIndex + 1

        if (nextStepIndex < steps.length) {
            handleTabClick(steps[nextStepIndex].name)
        } else {
            await handleFinish()
        }

        window.scrollTo(0, 0)
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsFloattingVisible(!entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        const currentRef = reservationButtonsRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    useEffect(() => {
        const previousClientInformations = JSON.parse(localStorage.getItem('clienteNaoCadastrado') || '{}')

        if (previousClientInformations.nome && previousClientInformations.telefone)
            setInformations(previousClientInformations)
    }, [])

    const handleGoBack = () => {
        const activeStepIndex = steps.findIndex(step => step.isActive)
        const previousStepIndex = activeStepIndex - 1

        if (previousStepIndex >= 0) {
            handleTabClick(steps[previousStepIndex].name)
        }
    }

    const getEstimatedTime = () => {
        let estimatedTime = 0
        selectedServices.forEach(service => {
            estimatedTime += service.tempo_estimado
        })

        return estimatedTime
    }

    useEffect(() => {
        const activeStepIndex = steps.findIndex(step => step.isActive)
        const isLastStep = activeStepIndex === steps.length - 1

        if (activeStepIndex === 0) {
            setActionButtonSettings(prev => ({ ...prev, disabled: selectedServices.length === 0 }))
        } else if (activeStepIndex === 1) {
            setActionButtonSettings(prev => ({ ...prev, disabled: !selectedBarber || !selectedDate || !selectedHour || selectedServices.length === 0 }))
        } else if (activeStepIndex === 2) {
            setActionButtonSettings(prev => ({ ...prev, disabled: !informations.nome || !informations.telefone }))
        } else {
            setActionButtonSettings(prev => ({ ...prev, disabled: false }))
        }

        if (isLastStep) {
            setActionButtonSettings(prev => ({ ...prev, text: "Finalizar" }))
        } else {
            setActionButtonSettings(prev => ({ ...prev, text: "Continuar" }))
        }

    }, [steps, selectedServices, selectedBarber, selectedDate, selectedHour, informations])

    useEffect(() => {
        window.scrollTo(0, 0);
        setTimeout(() => { window.scrollTo(0, 0); }, 100);
    }, [steps])
    

    const handleFinish = async () => {
        if (!informations.nome || !informations.telefone) {
            toast.error("Preencha seu nome e telefone por favor")
            hideLoadingWithDelay()
            return
        }

        const phoneRegex = /^\d{2}\d{4,5}\d{4}$/
        if (!phoneRegex.test(informations.telefone)) {
            toast.error("Telefone inválido")
            hideLoadingWithDelay()
            return
        }

        if (!selectedBarber || !selectedDate || !selectedHour || selectedServices.length === 0) {
            toast.error("Erro na obtenção de campos")
            hideLoadingWithDelay()
            return
        }

        const object_values = {
            agendamento: {
                barbearia_usuario_id: selectedBarber?.barbearia_usuario_id,
                data_agendamento: composeDateAndHour(selectedDate, selectedHour)
            },
            cliente_nao_cadastrado: {
                nome: informations.nome,
                telefone: informations.telefone
            },
            agendamento_servicos: selectedServices.map(service => ({
                servico_id: service.id,
                valor: service.preco,
                tempo_estimado: service.tempo_estimado
            }))
        }

        try {
            const response = await api.post('/agendamentos_completos/', object_values)
            const agendamento = response.data.agendamento

            let newAgendamentosLocalStorage = JSON.parse(localStorage.getItem('agendamento') || '[]') || []
            if (!Array.isArray(newAgendamentosLocalStorage)) {
                newAgendamentosLocalStorage = [];
            }
            newAgendamentosLocalStorage.push(agendamento)
            localStorage.setItem('agendamento', JSON.stringify(newAgendamentosLocalStorage))
            localStorage.setItem('clienteNaoCadastrado', JSON.stringify(informations))

            toast.success("Reserva realizada com sucesso")

            navigate(`/barbershop/${id_barbershop}/reservations/done/${agendamento.id}`)
        } catch (error: any) {
            console.log('error', error)
            if (error?.response?.data?.detail) {
                toast.error(error.response.data.detail)
            } else {
                toast.error("Erro ao realizar reserva")
            }
            hideLoadingWithDelay()
        }
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text={`Novo agendamento ${barberShop?.nome ? ' - ' + barberShop?.nome : ''}`} />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="center"
                align="flex-start"
                flexDirection="row"
            >
                <CenteredContentContainer
                    width="720px"
                >
                    <StepsItems
                        steps={steps}
                        onStepClick={handleTabClick}
                        disabled
                    />
                    {renderContent()}
                </CenteredContentContainer>
                <CenteredContentContainer
                    width="420px"
                >
                    <MediumText bold>Sua reserva</MediumText>
                    <Separator width="60px" height="3px" />
                    <GroupContainer>
                        <NormalText align="left" bold>Serviços</NormalText>
                        {selectedServices.length > 0 && (
                            <>
                                <Services>
                                    {selectedServices.map((service, index) => (
                                        <Service
                                            key={index}
                                            isSelected={false}
                                            disabled
                                        >
                                            <MediumText color={colors.darkGray} bold>{service.nome}</MediumText>

                                            <ServiceValueAndTimeContainer>
                                                <MediumText color={colors.darkGray} bold>R$ {service.preco},00</MediumText>
                                                <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                                            </ServiceValueAndTimeContainer>
                                        </Service>
                                    ))}
                                </Services>
                            </>
                        )}
                    </GroupContainer>

                    <GroupContainer>
                        <NormalText align="left" bold>Barbeiro</NormalText>
                        {selectedBarber && <BarbersViewer barbers={[selectedBarber]} />}
                    </GroupContainer>

                    <GroupContainer>
                        <NormalText align="left" bold>Tempo e valor</NormalText>
                        <TotalContainer
                            flexDirection="row"
                        >
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray} >Tempo estimado</SmallText>
                                <NormalText color={colors.darkGray} bold >{selectedServices.reduce((acc, service) => acc + service.tempo_estimado, 0)}</NormalText>
                            </LabelAndValueContainer>
                            <LabelAndValueContainer
                                align="flex-end"
                            >
                                <SmallText color={colors.mediumGray}>Valor Total</SmallText>
                                <NormalText color={colors.darkGray} bold>R$ {selectedServices.reduce((acc, service) => acc + service.preco, 0)}</NormalText>
                            </LabelAndValueContainer>
                        </TotalContainer>
                    </GroupContainer>

                    <GroupContainer>
                        <NormalText align="left" bold>Data e hora</NormalText>
                        <TotalContainer
                            flexDirection="row"
                        >
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray} >Data</SmallText>
                                <NormalText color={colors.darkGray} bold >
                                    {selectedDate.toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                </NormalText>
                            </LabelAndValueContainer>
                            <LabelAndValueContainer
                                align="flex-end"
                            >
                                <SmallText color={colors.mediumGray}>Hora</SmallText>
                                <NormalText color={colors.darkGray} bold>
                                    {selectedHour}
                                </NormalText>
                            </LabelAndValueContainer>
                        </TotalContainer>
                    </GroupContainer>
                    <GroupContainer>
                        <NormalText align="left" bold>Informações do cliente</NormalText>
                        <TotalContainer
                            flexDirection="row"
                        >
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray} bold>Nome</SmallText>
                                <NormalText color={colors.darkGray}>{informations.nome}</NormalText>
                            </LabelAndValueContainer>
                            <LabelAndValueContainer
                                align="flex-end"
                            >
                                <SmallText color={colors.mediumGray} bold>Telefone</SmallText>
                                <NormalText color={colors.darkGray}>{informations.telefone}</NormalText>
                            </LabelAndValueContainer>
                        </TotalContainer>
                    </GroupContainer>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                            width: "100%",
                        }}
                        ref={reservationButtonsRef}
                    >

                        <ConfirmationButton
                            onClick={handleContinue}
                            disabled={actionButtonSettings.disabled}
                            disabledMessage="Selecione ou preencha os campos para seguir por favor :)"
                        >
                            <MediumText color="white" bold>{actionButtonSettings.text}</MediumText>
                        </ConfirmationButton>
                        <GoBackButton
                            onClick={handleGoBack}
                        >
                            <MediumText color={colors.primary} bold>Voltar</MediumText>
                        </GoBackButton>
                    </div>
                </CenteredContentContainer>
            </DefaultContainer>
            {isMobileView && (
                <FloattingContainer isVisible={isFloattingVisible}>
                    <GoBackButton
                        onClick={handleGoBack}
                    >
                        <MediumText color={colors.primary} bold>Voltar</MediumText>
                    </GoBackButton>
                    <ConfirmationButton
                        onClick={handleContinue}
                        disabled={actionButtonSettings.disabled}
                        disabledMessage="Selecione ou preencha os campos para seguir por favor :)"
                    >
                        <MediumText color="white" bold>{actionButtonSettings.text}</MediumText>
                    </ConfirmationButton>
                </FloattingContainer>
            )}
        </>
    );
}