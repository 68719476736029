import React, { useContext, useEffect, useState } from "react";
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen";
import { LoadingHook } from "../../../hooks/LoadingHook";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/authContext";
import { BarbeariasWithPhotosType } from "../../../types/barbershop";
import { api, api_url } from "../../../config/api";
import { Separator } from "../../globalStyles";
import colors from "../../../style/colors";
import NormalText from "../../../components/texts/NormalText";
import GoBackTitle from "../../../components/GoBackTitle";
import DefaultContainer from "../../../components/Containers/DefaultContainer";
import styled from "styled-components";
import LinkText from "../../../components/Link";
import CenteredContentContainer from "../../../components/Containers/CenteredContentContainer";
import SearchInput from "../../../components/SearchInput";
import ActionButton from "../../../components/ActionButton";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: ${colors.white};
    padding: 10px;
    border: 1px solid ${colors.gray};
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.3s;
    // width: 270px;
    width: 350px;
    height: 400px;
    align-items: flex-start;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const BabershopImage = styled.img`
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 3px;
    border: 1px solid ${colors.lightGray};
`

const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: flex-start;
`

const SearchAndButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
`;

const BarbersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
    width: 100%;
`;

export default function Barbershops() {
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const { user } = useContext(AuthContext);

    const [barbershops, setBarbershops] = useState<BarbeariasWithPhotosType>([])

    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)

        const getBarbershops = async () => {
            try {
                const response = await api.get(`/barbearias/user/${user?.id}/complete`)
                setBarbershops(response.data)
            } catch (error) {
            } finally {
                hideLoadingWithDelay()
            }
        }

        if (user?.id) getBarbershops()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.id])


    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text="Barbearias" />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="flex-start"
                align="flex-start"
            >
                <CenteredContentContainer
                    width="100%"
                >
                    <SearchAndButtonContainer>
                        <SearchInput
                            placeholder="Pesquisar serviço"
                            onSearch={() => { }}
                        />
                        <ActionButton width='fit-content' onClick={() => navigate('barbershop')} text="Adicionar" />
                    </SearchAndButtonContainer>

                    <BarbersContainer>
                        {barbershops.map(barbershop => (
                            <Container key={barbershop.id} >
                                <GroupContainer onClick={() => navigate(`barbershop/${barbershop.id}`)}>
                                    <BabershopImage src={api_url + barbershop.fotos[0]?.foto} />
                                    <NormalText align="left" color={colors.mediumGray} bold>{barbershop.nome}</NormalText>
                                    <NormalText color={colors.mediumGray}>{barbershop.localizacao?.endereco}</NormalText>
                                    <Separator />
                                </GroupContainer>
                                <LinkText onClick={() => navigate(`/barbershop/${barbershop.id}`)}>
                                    <NormalText color={colors.blue}>Ver barbearia</NormalText>
                                </LinkText>
                            </Container>
                        ))}
                    </BarbersContainer>
                </CenteredContentContainer>
            </DefaultContainer>
        </>
    )
}