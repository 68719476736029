import React from "react";
import BigText from "../../../../../../components/texts/BigText";
import NormalText from "../../../../../../components/texts/NormalText";
import colors from "../../../../../../style/colors";

export default function ConfirmationTab() {
    return (
        <>
             <BigText>Confirmação</BigText >
             <NormalText color={colors.mediumGray}>Se todos os dados ao lado estiverem corretos, basta confirmar a reserva :)</NormalText>
        </>
    )
}