export function formatDateToDDMMYYYY(date: Date) {
    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`
}

export function formatDateToYYYYMMDD(date: Date) {
    const localDate = new Date(date.toLocaleString("en-US", { timeZone: "America/Sao_Paulo" }));
    return `${localDate.getFullYear()}-${String(localDate.getMonth() + 1).padStart(2, '0')}-${String(localDate.getDate()).padStart(2, '0')}`
}

export function composeDateAndHour(date: Date, hour: String = '00:00') {
    const formatedDate = formatDateToYYYYMMDD(date)
    return `${formatedDate}T${hour}:00Z`
}

export function getDateFromDateTime(dateTime: string) {
    const date = new Date(dateTime)
    return formatDateToDDMMYYYY(date)
}

export function getHourFromDateTime(dateTime: string) {
    const date = new Date(dateTime)
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`
}
export function formatDateToDDMMYYYYFromString(date: string) {
    if (!date) return ''
    const dateObj = new Date(date)
    const localDate = new Date(dateObj.getTime() + dateObj.getTimezoneOffset() * 60000);
    return formatDateToDDMMYYYY(localDate)
}
