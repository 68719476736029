import React, { useEffect, useState } from "react";
import colors from "../../../../../../../style/colors";
import NormalText from "../../../../../../../components/texts/NormalText";
import BigText from "../../../../../../../components/texts/BigText";
import { ConfirmationButton, InputValue, LabelAndValueContainer, Separator } from "../../../../../../globalStyles";
import { BarbersType } from "../../../../../../../types/barber";
import BarbersSelector from "../../../../../../../components/BarbersSelector";
import { RecurrentReservationScheduleEditType, RecurrentReservationsScheduleType, ReservationAusencesConflictListType, ReservationAusencesListType } from "../../../../../../../types/reservation";
import { formatDateToDDMMYYYYFromString, getHourFromDateTime } from "../../../../../../../functions/date";
import GenericModal from "../../../../../../../components/GenericModal";
import MediumText from "../../../../../../../components/texts/MediumText";
import { ReservationsContainer } from "../../../../../../Barber/Schedules";
import { formatHourToHHMM } from "../../../../../../../functions/hour";
import { LoadingHook } from "../../../../../../../hooks/LoadingHook";
import { api } from "../../../../../../../config/api";
import { ServicesType } from "../../../../../../../types/services";
import styled from "styled-components";
import LoadingFreezeScreen from "../../../../../../../components/LoadingFreezeScreen";
import { toast } from "react-toastify";

const ScheduleContainer = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    border-bottom: 1px solid ${colors.gray};
    padding: 10px 10px;
    cursor: pointer;    
    transition: background-color 0.3s;
    border-radius: 5px;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: ${colors.lightGray};
    }

    &:active {
        background-color: ${colors.gray};
    }
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
`;

const RowContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
`;

const VerticalSeparator = styled.div<{ color?: string }>`
    width: 4px;
    background-color: ${props => props.color || colors.black};
`;


type Props = {
    recurrentReservationsSchedules: RecurrentReservationsScheduleType;
    setRecurrentReservationsSchedules: React.Dispatch<React.SetStateAction<RecurrentReservationsScheduleType>>;
    selectedServices: ServicesType;
    barbers: BarbersType;
    conflicsWithAllSchedules: ReservationAusencesConflictListType;
    setConflictWithAllSchedules: React.Dispatch<React.SetStateAction<ReservationAusencesConflictListType>>;
}

export default function RecurrentScheduleTab(
    {
        recurrentReservationsSchedules,
        setRecurrentReservationsSchedules,
        selectedServices,
        barbers,
        conflicsWithAllSchedules,
        setConflictWithAllSchedules
    }: Props
) {

    const [conflictWithCurrentSchedule, setConflictWithCurrentSchedules] = useState<ReservationAusencesListType>([]);
    
    const [currentSchedule, setCurrentSchedule] = useState<RecurrentReservationScheduleEditType>({
        data: '',
        hora: '',
        barbearia_usuario: {
            barbearia_usuario_id: 0,
            nome: ''
        },
        index: 0
    });

    const [isModalOpen, setIsModalOpen] = useState(false);

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook();

    const openEditSchedule = (index: number) => {
        setIsLoading(true)
        const schedule = {
            ...recurrentReservationsSchedules[index],
            index: index
        }
        setCurrentSchedule(schedule);
        setIsModalOpen(true);
        hideLoadingWithDelay()
    }

    const saveSchedule = () => {
        const updatedSchedules = [...recurrentReservationsSchedules];
        updatedSchedules[currentSchedule.index] = currentSchedule;
        setRecurrentReservationsSchedules(updatedSchedules);
        setIsModalOpen(false);
    }

    const getExistingScheduleAndAusences = async () => {
        try {
            if (!currentSchedule) {
                toast.error("Erro ao obter agendamento")
                return
            };
            const estimatedTime = selectedServices.reduce((total, service) => total + service.tempo_estimado, 0);
            const response = await api.get(`/horarios/agendamentos_ausencias_por_data_hora?barbearia_usuario_id=${currentSchedule?.barbearia_usuario.barbearia_usuario_id}&date_filter=${currentSchedule?.data}&time_filter=${currentSchedule?.hora}&tempo_estimado_servicos=${estimatedTime}`);
            setConflictWithCurrentSchedules(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const getExistingScheduleAndAusencesForAllRecurrentSchedules = async () => {
        try {
            const estimatedTime = selectedServices.reduce((total, service) => total + service.tempo_estimado, 0);
            const schedules = recurrentReservationsSchedules.map((schedule, index) => ({
                data: schedule.data,
                hora: schedule.hora,
                barbearia_usuario_id: schedule.barbearia_usuario.barbearia_usuario_id,
                tempo_estimado_servicos: estimatedTime,
                index: index
            }));
            const response = await api.post(`/horarios/agendamentos_ausencias_por_data_hora_lista`, schedules);
            setConflictWithAllSchedules(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        getExistingScheduleAndAusences()
        hideLoadingWithDelay()
    }, [currentSchedule])

    useEffect(() => {
        setIsLoading(true)
        getExistingScheduleAndAusencesForAllRecurrentSchedules()
        hideLoadingWithDelay()
    }, [recurrentReservationsSchedules])

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <BigText>Preferencias</BigText >
            <NormalText color={colors.mediumGray}>Selecione sua preferencia para a recorrencia</NormalText>

            <NormalText color={colors.darkGray} bold>Quantidade por mês</NormalText>

            {recurrentReservationsSchedules.length > 0 && (
                <>
                    {recurrentReservationsSchedules.map((schedule, index) => (
                        <ScheduleContainer key={index} onClick={() => openEditSchedule(index)} >
                            <ColumnContainer>
                                <MediumText bold>{formatDateToDDMMYYYYFromString(schedule.data)}</MediumText>
                                <NormalText>{formatHourToHHMM(schedule.hora)}</NormalText>
                            </ColumnContainer>
                            <VerticalSeparator color={colors.primary} />
                            <ColumnContainer>
                                <NormalText bold>{schedule.barbearia_usuario.nome}</NormalText>
                                {conflicsWithAllSchedules.map(conflict => {
                                    if (conflict.index === index) {
                                        if (conflict.agendamentos_ausencias.length > 0) {
                                            return <NormalText color={colors.red}>Você tem {conflict.agendamentos_ausencias.length} agendamentos(s) existentes para este dia/hora</NormalText>
                                        }
                                        return <NormalText color={colors.green}>Agenda disponível</NormalText>
                                    }
                                    return null;
                                }
                                ).filter(length => length !== null)}
                            </ColumnContainer>
                        </ScheduleContainer>
                    )
                    )}

                    <GenericModal
                        open={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                    >
                        {currentSchedule && (
                            <>
                                <MediumText>Editar agendamento</MediumText>
                                <Separator />
                                <BarbersSelector
                                    barbers={barbers}
                                    selectedBarbers={barbers.filter(barber => barber.id === currentSchedule.barbearia_usuario.barbearia_usuario_id)}
                                    setSelectedBarbers={(barber) => setCurrentSchedule({ ...currentSchedule, barbearia_usuario: barber[0] })}
                                    onlyOneBarber={true}
                                />
                                <Separator />
                                <LabelAndValueContainer>
                                    <NormalText color={colors.mediumGray} bold>Data</NormalText>
                                    <InputValue
                                        type="date"
                                        value={currentSchedule?.data}
                                        onChange={(e) => setCurrentSchedule({ ...currentSchedule, data: e.target.value })}
                                    />
                                </LabelAndValueContainer>
                                <LabelAndValueContainer>
                                    <NormalText color={colors.mediumGray} bold>Hora</NormalText>
                                    <InputValue
                                        type="time"
                                        value={currentSchedule?.hora}
                                        onChange={(e) => setCurrentSchedule({ ...currentSchedule, hora: e.target.value })}
                                    />
                                </LabelAndValueContainer>
                                <Separator />
                                {conflictWithCurrentSchedule.length === 0 && <NormalText>Nenhum agendamento para este dia</NormalText>}
                                {conflictWithCurrentSchedule.length > 0 && <NormalText>Você tem {conflictWithCurrentSchedule.length} agendamentos(s) para este dia/hora
                                </NormalText>}
                                <ReservationsContainer>
                                    {conflictWithCurrentSchedule.map(reservationOrAusence => {
                                        if (reservationOrAusence.agendamento) {
                                            return (
                                                <>
                                                    <ScheduleContainer>
                                                        <ColumnContainer>
                                                            <MediumText bold>{getHourFromDateTime(reservationOrAusence.agendamento.agendamento.data_agendamento)}</MediumText>
                                                            <NormalText>{reservationOrAusence.agendamento.servicos.reduce(
                                                                (acc, servico) => acc + servico.tempo_estimado, 0
                                                            )} min</NormalText>
                                                        </ColumnContainer>
                                                        <VerticalSeparator color={colors.primary} />
                                                        <ColumnContainer>
                                                            <NormalText bold>Reserva</NormalText>
                                                            <RowContainer>
                                                                <MediumText>{reservationOrAusence.agendamento.cliente_nao_cadastrado.nome}</MediumText>
                                                            </RowContainer>
                                                        </ColumnContainer>
                                                    </ScheduleContainer>
                                                </>
                                            )
                                        }
                                        if (reservationOrAusence.ausencia) {
                                            const horaInicio = new Date(`1970-01-01T${reservationOrAusence.ausencia.hora_inicio}Z`);
                                            const horaFim = new Date(`1970-01-01T${reservationOrAusence.ausencia.hora_fim}Z`);
                                            const differenceInMinutes = (horaFim.getTime() - horaInicio.getTime()) / (1000 * 60);

                                            return (
                                                <ScheduleContainer>
                                                    <ColumnContainer>
                                                        <MediumText bold>{formatHourToHHMM(reservationOrAusence.ausencia.hora_inicio)}</MediumText>
                                                        <NormalText>{differenceInMinutes} min</NormalText>
                                                    </ColumnContainer>
                                                    <VerticalSeparator color={colors.secondary} />
                                                    <ColumnContainer>
                                                        <NormalText bold>Ausência</NormalText>
                                                        <RowContainer>
                                                            <MediumText>{reservationOrAusence.ausencia.descricao}</MediumText>
                                                        </RowContainer>
                                                    </ColumnContainer>
                                                </ScheduleContainer>
                                            )
                                        }
                                    }
                                    )}
                                </ReservationsContainer>
                                <ConfirmationButton onClick={saveSchedule}>
                                    <NormalText color={colors.white}>Salvar</NormalText>
                                </ConfirmationButton>
                            </>
                        )}
                    </GenericModal>
                </>
            )}

        </>
    )
}