import React, { useEffect, useState } from "react";
import styled from "styled-components";
import LinkText from "../Link";
import NormalText from "../texts/NormalText";
import colors from "../../style/colors";
import { ScreenSizeHook } from "../../hooks/ScreenSizeHook";

const StepsContainer = styled.div`
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

type Step = {
  name: string;
  isActive: boolean;
};

type StepsItemsProps = {
  steps: Step[];
  onStepClick: (stepName: string) => void;
  disabled?: boolean;
};

const StepsItems: React.FC<StepsItemsProps> = ({ steps, onStepClick, disabled = false }) => {
  const [activeStep, setActiveStep] = useState(steps.find(step => step.isActive)?.name || steps[0].name);
  const { isMobileView } = ScreenSizeHook();

  const handleStepClick = (stepName: string) => {
    if (!disabled) {
      setActiveStep(stepName);
      onStepClick(stepName);
    }
  };

  useEffect(() => {
    setActiveStep(steps.find(step => step.isActive)?.name || steps[0].name);
  }, [steps]);

  return (
    <StepsContainer>
      {steps.map(step => (
        <LinkText
          key={step.name}
          disabled={disabled}
          onClick={() => handleStepClick(step.name)}
        >
          {isMobileView ? (
            <NormalText bold={step.name === activeStep} color={step.name === activeStep ? colors.darkGray : colors.mediumGray}>
              {step.name}
            </NormalText>
          ) : (
            <NormalText bold={step.name === activeStep} color={step.name === activeStep ? colors.darkGray : colors.mediumGray}>
              {step.name}
            </NormalText>
          )}
        </LinkText>
      ))}
    </StepsContainer>
  );
};

export default StepsItems;