import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import { GetReservationsType, RecurrentReservationData, RecurrentReservationType } from "../../../../types/reservation";
import { api } from "../../../../config/api";
import { toast } from "react-toastify";
import NormalText from "../../../../components/texts/NormalText";
import { LabelAndValueContainer, Separator, Service, Services, ServiceValueAndTimeContainer, TotalContainer } from "../../../globalStyles";
import colors from "../../../../style/colors";
import SmallText from "../../../../components/texts/SmallText";
import { formatDateToDDMMYYYYFromString, getDateFromDateTime, getHourFromDateTime } from "../../../../functions/date";
import MediumText from "../../../../components/texts/MediumText";
import { AuthContext } from "../../../../context/authContext";
import GoBackTitle from "../../../../components/GoBackTitle";
import DefaultContainer from "../../../../components/Containers/DefaultContainer";
import CenteredContentContainer from "../../../../components/Containers/CenteredContentContainer";
import styled from "styled-components";
import WhatsAppButton from "../../../../components/WhatsAppButton";
import { BarbeariaType } from "../../../../types/barbershop";

const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    align-items: flex-start;
    width: 100%;
`;

export default function DoneRecurrentReservation() {
    const { user } = useContext(AuthContext);

    const { id, id_barbershop } = useParams();

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()

    const [recurrentReservationData, setRecurrentReservationData] = useState<RecurrentReservationData>()
    const [recurrentReservationType, setRecurrentReservationType] = useState<RecurrentReservationType>()
    const [reservations, setReservations] = useState<GetReservationsType>()
    const [barberShop, setBarberShop] = React.useState<BarbeariaType>()

    useEffect(() => {
        if (id) {
            setIsLoading(true)
            const getRecurrentReservationData = async () => {
                try {
                    const response = await api.get(`/agendamentos_recorrentes/${id}`)
                    if (response.status !== 200) {
                        toast.error("Erro ao buscar dados do agendamento recorrente")
                        return
                    }
                    setRecurrentReservationData(response.data)

                    const tipo_agendamento_recorrente_id = response.data.tipo_agendamento_recorrente_id
                    const responseType = await api.get(`/tipos_agendamento_recorrente/${tipo_agendamento_recorrente_id}`)
                    setRecurrentReservationType(responseType.data)

                    const responseReservations = await api.get(`/agendamentos_completos/recurrent_id/${id}`)
                    setReservations(responseReservations.data)


                } catch (error) {
                } finally {
                }
            }
            getRecurrentReservationData()
            hideLoadingWithDelay()
        }
    }, [id])

    useEffect(() => {
        const getBarberShopData = async () => {
            const response = await api.get(`/barbearias/${id_barbershop}`)
            if (response) {
                setBarberShop(response.data)
            }
        }

        try {
            if (id_barbershop) {
                getBarberShopData()
            }
        } catch {
            toast.error('Falha ao obter barbearia')
        }

    }, [id_barbershop])


    const handleGoToWhatsAppClientChat = () => {
        if (reservations) {
            const phone = reservations[0].cliente_nao_cadastrado.telefone
            let text = `Olá, ${reservations[0].cliente_nao_cadastrado.nome}! Aqui é da barbearia *${reservations[0].barbearia.nome}*.`;
            text = text.concat(`\n\nVocê tem os seguintes agendamentos: \n`);
            reservations.forEach(reservation => {
                text = text.concat(`\n- ${getDateFromDateTime(reservation.agendamento.data_agendamento)} às ${getHourFromDateTime(reservation.agendamento.data_agendamento)} com ${reservation.usuario.nome}`);
            });
            text = text.concat(`\n\nAguardanmos você! Atenciosamente, ${reservations[0].barbearia.nome}`);
            window.open(`https://api.whatsapp.com/send?phone=55${phone}&text=${text}`, '_blank')
        }
    }

    return <>
        <LoadingFreezeScreen isLoading={isLoading} />
        <GoBackTitle text={`Reserva recorrente${` ${recurrentReservationData?.id ? '# ' + recurrentReservationData?.id : ''} ${barberShop?.nome ? ' - ' + barberShop?.nome : ''}`}`} />
        <DefaultContainer
            backgroundColor={colors.lightGray}
            borderColor={colors.gray}
            justify="flex-start"
            align="center"
        >
            <CenteredContentContainer
                width="430px"
            >

                {reservations && recurrentReservationData && (
                    <>
                        <GroupContainer>
                            <MediumText align="left" bold>Dados barbearia</MediumText>
                            <TotalContainer flexDirection="row">
                                <LabelAndValueContainer>
                                    <SmallText color={colors.mediumGray}>Barbearia</SmallText>
                                    <NormalText bold >{reservations[0].barbearia.nome}</NormalText>
                                </LabelAndValueContainer>
                                <LabelAndValueContainer align="flex-end">
                                    <SmallText color={colors.mediumGray}>Telefone</SmallText>
                                    <NormalText bold >{reservations[0].barbearia.telefone}</NormalText>
                                </LabelAndValueContainer>
                            </TotalContainer>
                        </GroupContainer>

                        <Separator />

                        <GroupContainer>
                            <MediumText align="left" bold>Periodo</MediumText>
                            <TotalContainer flexDirection="row">
                                <LabelAndValueContainer>
                                    <SmallText color={colors.mediumGray}>Data de inicio</SmallText>
                                    <NormalText bold >{formatDateToDDMMYYYYFromString(recurrentReservationData.data_inicio)}</NormalText>
                                </LabelAndValueContainer>
                                <LabelAndValueContainer align="flex-end">
                                    <SmallText color={colors.mediumGray}>Data final</SmallText>
                                    <NormalText bold >{formatDateToDDMMYYYYFromString(recurrentReservationData.data_fim)}</NormalText>
                                </LabelAndValueContainer>
                            </TotalContainer>
                            <LabelAndValueContainer>
                                <SmallText color={colors.mediumGray}>Tipo de agendamento</SmallText>
                                <NormalText bold >{recurrentReservationType?.nome}</NormalText>
                            </LabelAndValueContainer>
                        </GroupContainer>

                        <Separator />

                        <GroupContainer>
                            <MediumText align="left" bold>Cliente</MediumText>
                            <TotalContainer flexDirection="row">
                                <LabelAndValueContainer>
                                    <SmallText color={colors.mediumGray}>Cliente</SmallText>
                                    <NormalText bold >{reservations[0].cliente_nao_cadastrado.nome}</NormalText>
                                </LabelAndValueContainer>
                                <LabelAndValueContainer align="flex-end">
                                    <SmallText color={colors.mediumGray}>Telefone</SmallText>
                                    <NormalText bold >{reservations[0].cliente_nao_cadastrado.telefone}</NormalText>
                                </LabelAndValueContainer>
                            </TotalContainer>
                        </GroupContainer>

                        <Separator />

                        <GroupContainer>
                            <NormalText align="left" bold>Serviços</NormalText>
                            {reservations[0].servicos.length > 0 && (
                                <>
                                    <Services>
                                        {reservations[0].servicos.map((service, index) => (
                                            <Service
                                                key={index}
                                                isSelected={false}
                                                disabled
                                            >
                                                <MediumText color={colors.darkGray} bold>{service.nome}</MediumText>

                                                <ServiceValueAndTimeContainer>
                                                    <MediumText color={colors.darkGray} bold>R$ {service.preco},00</MediumText>
                                                    <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                                                </ServiceValueAndTimeContainer>
                                            </Service>
                                        ))}
                                    </Services>
                                </>
                            )}
                        </GroupContainer>

                        <Separator />

                        <GroupContainer>
                            <MediumText align="left" bold>Agendamentos</MediumText>
                            {reservations.map((schedule, index) => (
                                <TotalContainer
                                    key={index}
                                    flexDirection="row"
                                >
                                    <LabelAndValueContainer>
                                        <SmallText color={colors.mediumGray} >Data</SmallText>
                                        <SmallText color={colors.darkGray} bold >{formatDateToDDMMYYYYFromString(schedule.agendamento.data_agendamento)}</SmallText>
                                    </LabelAndValueContainer>
                                    <LabelAndValueContainer>
                                        <SmallText color={colors.mediumGray}>Hora</SmallText>
                                        <SmallText color={colors.darkGray} bold>{getHourFromDateTime(schedule.agendamento.data_agendamento)}</SmallText>
                                    </LabelAndValueContainer>
                                    <LabelAndValueContainer>
                                        <SmallText color={colors.mediumGray} >Barbeiro</SmallText>
                                        <SmallText color={colors.darkGray} bold >{schedule.usuario.nome}</SmallText>
                                    </LabelAndValueContainer>
                                </TotalContainer>
                            ))}
                        </GroupContainer>

                        <Separator />

                        {user?.id ? (
                            <>
                                <GroupContainer>
                                    <MediumText align="left" bold>Quer entrar em contato com o clliente?</MediumText>
                                    <NormalText align="left" color={colors.mediumGray}>Envie uma mensagem padrão diretamento para o cliente :)</NormalText>
                                    <WhatsAppButton
                                        text="Enviar mensagem"
                                        onClick={handleGoToWhatsAppClientChat}
                                    />
                                </GroupContainer>
                            </>
                        ) : (
                            <>
                                <GroupContainer>
                                    <MediumText align="left" bold>Dúvidas?</MediumText>
                                    <NormalText align="left" color={colors.mediumGray}>Se tem alguma dúvida você pode estar entrando em contato para obter maiores inforçãoes</NormalText>
                                    <WhatsAppButton
                                        text="Enviar mensagem"
                                        onClick={handleGoToWhatsAppClientChat}
                                    />
                                </GroupContainer>
                            </>
                        )}
                    </>
                )}

            </CenteredContentContainer>
        </DefaultContainer>
    </>
}