import React, { useRef } from "react";
import styled from "styled-components";
import colors from "../../../../../style/colors";
import { BarberShopPhotosType, BarberShopPhotoType } from "../../../../../types/barbershop";
import { api_url } from "../../../../../config/api";
import BigText from "../../../../../components/texts/BigText";
import NormalText from "../../../../../components/texts/NormalText";
import { FaPlus, FaTimes} from "react-icons/fa";
import axios from "axios";
import SmallText from "../../../../../components/texts/SmallText";

const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
  margin: 0 auto;
`;

const ImageContainer = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 320px;
    height: 300px;
    border: 1px solid ${colors.gray};
    border-radius: 3px;

    @media (max-width: 768px) {
        width: 150px;
        height: 150px;
    }
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const AddImageButton = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px dashed ${colors.mediumGray};
    border-radius: 8px;
    padding: 10px;
    width: 320px;
    height: 300px;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 150px;
        height: 150px;
    }
`;

const AddImageIcon = styled(FaPlus)`
    color: ${colors.mediumGray};
`;

const AddImageInput = styled.input`
    display: none;
`;

const AddImageText = styled(NormalText)`
    color: ${colors.mediumGray};
`;

const DeleteImageIcon = styled(FaTimes)`
    color: ${colors.darkGray};
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    background-color: ${colors.white};
    border: 2px solid ${colors.gray};
    padding: 5px;
    border-radius: 3px;

    &:hover {
        background-color: ${colors.lightGray};
        color: ${colors.darkGray};
    }


`;

type ImageTabProps = {
    barberShopImages?: BarberShopPhotosType;
    setBarberShopImages: (barberShopImages: BarberShopPhotosType) => void;
    newImages: File[];
    setNewImages : (newImages: File[]) => void;
    imagesToDelete: BarberShopPhotoType[];
    setImagesToDelete: (imagesToDelete: BarberShopPhotoType[]) => void;
};

export default function ImageTab({ 
        barberShopImages, 
        setBarberShopImages, 
        newImages, 
        setNewImages, 
        imagesToDelete, 
        setImagesToDelete  
    }: ImageTabProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleAddImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            setNewImages([...newImages, ...Array.from(files)]);
        }
    };

    const handleRemoveExistingImage = (image: BarberShopPhotoType) => {
        setImagesToDelete([...imagesToDelete, image]);
        setBarberShopImages((barberShopImages || []).filter((i) => i.id !== image.id));
    }

    return (
        <>
            <BigText>Fotos</BigText>
            <NormalText color={colors.mediumGray}>Aqui consta as fotos de sua barbearia</NormalText>
           
            {imagesToDelete.length > 0 && (
                <SmallText color={colors.red}>
                    {imagesToDelete.length} imagem(ns) será(ão) removida(s) ao salvar
                </SmallText>
            )}

            <GridLayout>
                <AddImageButton onClick={handleAddImageClick}>
                    <AddImageIcon />
                    <AddImageText>Adicionar Foto</AddImageText>
                </AddImageButton>
                {barberShopImages?.map((image: BarberShopPhotoType, index: number) => (
                    <ImageContainer>
                        <Image key={index} src={`${api_url}${image.foto}`} alt={image.foto} />
                        <DeleteImageIcon onClick={() => handleRemoveExistingImage(image)}  size={24} />
                    </ImageContainer>
                ))}
                {newImages.map((file, index) => (
                    <ImageContainer>
                        <Image key={index} src={URL.createObjectURL(file)} alt={file.name} />
                        <DeleteImageIcon onClick={() => setNewImages(newImages.filter((_, i) => i !== index))} size={24} />
                    </ImageContainer>
                ))}
            </GridLayout>

            <AddImageInput
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={handleFileChange}
                multiple
            />
        </>
    );
}