import React from "react";
import styled from "styled-components";
import colors from "../../../style/colors";

type Props = {
    bold?: boolean;
    children?: React.ReactNode;
    color?: string;
    width?: string;
    align?: string;
}

type PropsStyled = {
    bold?: boolean;
    color?: string;
    width?: string;
    align?: string;
}

const Text = styled.span<PropsStyled>`
    font-size: 10px;
    font-weight: ${props => props.bold ? "bold" : "normal"};
    color: ${props => props.color ? props.color : colors.darkGray};
    width: ${props => props.width};
    text-align: ${props => props.align};
`

export default function SmallText({children, bold=false, color, width, align}: Props) {
    return (
        <Text 
            bold={bold} 
            color={color}
            width={width}
            align={align}
        >
            {children}
        </Text>
    );
}