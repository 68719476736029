import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import { BarberShopPhotosType } from "../../types/barbershop";
import { api_url } from "../../config/api";

const Layout = styled.div<{ imageCount: number }>`
  display: grid;
  gap: 2px;
  width: 100%;
  margin: 0 auto;

  ${({ imageCount }) => {
    switch (imageCount) {
      case 1:
        return `
          grid-template-columns: 1fr;
          grid-template-rows: 570px 1fr;

          .large-image {
            grid-column: span 1;
            grid-row: span 1;
          }          

          .small-image {
            grid-column: span 1;
            grid-row: span 1;
          }
        `;
      case 2:
        return `
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: auto;
        `;
      case 3:
        return `
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: auto;
        `;
      case 4:
        return `
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(4, 1fr);

          .large-image {
            grid-column: span 2;
            grid-row: span 2;
          }

          .small-image {
            grid-column: span 2;
            grid-row: span 2;
          }
        `;
      case 5:
        return `
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, auto);
          .large-image {
            grid-column: span 2;
            grid-row: span 2;
          }
          .small-image {
            grid-column: span 1;
            grid-row: span 1;

          }
        `;
      default:
        return `
          grid-template-columns: repeat(4, 1fr);
          grid-template-rows: repeat(2, auto);
          .large-image {
            grid-column: span 2;
            grid-row: span 2;
          }
          .small-image {
            grid-column: span 1;
            grid-row: span 1;
          }
        `;
    }
  }}

  .large-image,
  .small-image {
    width: 100%;
    height: 100%;
    border-radius: 3px;
    border: 1px solid ${colors.gray};
    object-fit: cover;
    aspect-ratio: 1 / 1;
    background-color: ${colors.white};
  }

  @media (max-width: 768px) {
    ${({ imageCount }) => {
      switch (imageCount) {
        case 1:
          return `
            grid-template-columns: 1fr;
            grid-template-rows: auto;
          `;
        case 2:
          return `
            grid-template-columns: 1fr;
            grid-template-rows: repeat(2, auto);
          `;
        case 3:
          return `
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, auto);
          `;
        case 4:
          return `
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);
          `;
        case 5:
          return `
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(2, 1fr);
            .large-image {
              grid-column: span 2;
              grid-row: span 2;
            }
            .small-image {
              grid-column: span 1;
              grid-row: span 1;
            }
          `;
        default:
          return `
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(4, auto);
          `;
      }
    }}
  }
`;

type ImageGridProps = {
  barberShopImages?: BarberShopPhotosType;
};

const GalleryBaberShopImage = ({ barberShopImages }: ImageGridProps) => {
  const imageCount = barberShopImages?.length || 0;

  return (
    <Layout imageCount={imageCount}>
      {barberShopImages?.map((image, index) => (
        <img
          key={index}
          src={`${api_url}${image.foto}`}
          className={index === 0 && imageCount === 5 ? "large-image" : "small-image"}
        />
      ))}
    </Layout>
  );
};

export default GalleryBaberShopImage;