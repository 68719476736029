import styled from "styled-components"
import colors from "../../../../style/colors"

export const WhatsAppButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #25d366;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;

    &:hover {
        cursor: pointer;
        background-color: #128c7e;
    }

    &:active {
        background-color: #128c7e;
    }
`

export const InProgressReservationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.primary};
    border-radius: 5px;    
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${colors.black};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`

export const DonedReservationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.mediumGray};
    border-radius: 5px;    
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${colors.darkGray};
    }

    &:active {
        background-color: ${colors.mediumGray};
        outline: 2px solid ${colors.gray};
    }
`