import React from "react";
import { ConfirmationButton, InputValue, LabelAndValueContainer } from "../../globalStyles";
import NormalText from "../../../components/texts/NormalText";
import { LoadingHook } from "../../../hooks/LoadingHook";
import colors from "../../../style/colors";
import MediumText from "../../../components/texts/MediumText";
import DefaultContainer from "../../../components/Containers/DefaultContainer";
import CenteredContentContainer from "../../../components/Containers/CenteredContentContainer";
import BigText from "../../../components/texts/BigText";

export default function RegisterPage() {
    const { setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [registerData, setRegisterData] = React.useState({
        name: '',
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: ''
    })

    React.useEffect(() => {
        setIsLoading(true)
        hideLoadingWithDelay()
    }, [])

    return (
        <DefaultContainer
            backgroundColor={colors.lightGray}
            borderColor={colors.gray}
        >
            <CenteredContentContainer
                width="420px"
            >

                <BigText color={colors.darkGray} bold>Cadastro</BigText>
                <MediumText bold color={colors.red}>Ainda em construção</MediumText>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                    <InputValue
                        type="text"
                        placeholder="Seu nome aqui"
                        value={registerData.name}
                        onChange={(e) => setRegisterData({ ...registerData, name: e.target.value })}
                    />
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Email</NormalText>
                    <InputValue
                        type="text"
                        placeholder="Seu melhor e-mail"
                        value={registerData.email}
                        onChange={(e) => setRegisterData({ ...registerData, email: e.target.value })}
                    />
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                    <InputValue
                        type="text"
                        placeholder="Telefone para contato"
                        value={registerData.phoneNumber}
                        onChange={(e) => setRegisterData({ ...registerData, phoneNumber: e.target.value })}
                    />
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Senha</NormalText>
                    <InputValue
                        type="password"
                        placeholder="********"
                        value={registerData.password}
                        onChange={(e) => setRegisterData({ ...registerData, password: e.target.value })}
                    />
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Confirmação de senha</NormalText>
                    <InputValue
                        type="password"
                        placeholder="********"
                        value={registerData.confirmPassword}
                        onChange={(e) => setRegisterData({ ...registerData, confirmPassword: e.target.value })}
                    />
                </LabelAndValueContainer>
                <ConfirmationButton                >
                    <MediumText color="white" bold>Criar conta</MediumText>
                </ConfirmationButton>
            </CenteredContentContainer>
        </DefaultContainer>
    )
}