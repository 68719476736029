import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import { LoadingHook } from "../../../../hooks/LoadingHook"
import { toast } from "react-toastify"
import { api } from "../../../../config/api"
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen"
import MediumText from "../../../../components/texts/MediumText"
import { ConfirmationButton, GoBackButton, InputValue, LabelAndValueContainer } from "../../../globalStyles"
import NormalText from "../../../../components/texts/NormalText"
import colors from "../../../../style/colors"
import GenericButton from "../../../../components/GenericButton"
import DefaultContainer from "../../../../components/Containers/DefaultContainer"
import GoBackTitle from "../../../../components/GoBackTitle"
import CenteredContentContainer from "../../../../components/Containers/CenteredContentContainer"

export default function InviteBarber() {
    const { id_barbershop } = useParams()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const navigate = useNavigate()
    const [inviteBarber, setInviteBarber] = React.useState('')

    const handleConfim = async () => {
        setIsLoading(true)
        if (inviteBarber) {
            try {
                const response = await api.post('/barbeiros/convite/', { email: inviteBarber, barbearia_id: id_barbershop })
                if (response.status === 200 || response.data.id) {
                    toast.success("Barbeiro convidado com sucesso")
                    navigate(-1)
                }
            } catch (error: any) {
                toast.error(error.response.data.detail)
            } finally {
                hideLoadingWithDelay()
            }
        }
    }

    return (
        <>
            <GoBackTitle text="Convidar barbeiro" />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="flex-start"
                align="center"
            >

                <CenteredContentContainer
                    width="720px"
                >
                    <LoadingFreezeScreen isLoading={isLoading} />
                    <MediumText bold>Convide um barbeiro</MediumText>

                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                        <InputValue
                            type="text"
                            value={inviteBarber}
                            onChange={(e) => {
                                setInviteBarber(e.target.value)
                            }}
                        />
                    </LabelAndValueContainer>
                    <ConfirmationButton
                        onClick={handleConfim}
                    >
                        <NormalText color="white" bold>Convidar</NormalText>
                    </ConfirmationButton>
                </CenteredContentContainer>
            </DefaultContainer>
        </>
    )
}
