import React from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import colors from "../../../../../style/colors";
import BigText from "../../../../../components/texts/BigText";
import NormalText from "../../../../../components/texts/NormalText";
import ActionButton from "../../../../../components/ActionButton";
import { Service, ServiceValueAndTimeContainer } from "../../../../globalStyles";
import MediumText from "../../../../../components/texts/MediumText";
import { ServicesType } from "../../../../../types/services";
import { useNavigate } from "react-router-dom";

const SearchContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const InputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const Input = styled.input`
    padding: 10px 10px 10px 35px;
    border: 1px solid ${colors.gray};
    border-radius: 5px;
    width: 100%;
`;

const SearchIcon = styled(FaSearch)`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: ${colors.gray};
`;

const SearchAndButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;    
    width: 100%;
`;

const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 100%;
    text-align: left;
    gap: 10px;
`;

type ServiceTabProps = {
    services: ServicesType;
    setServices: (services: ServicesType) => void;
}


export default function ServiceTab({ services, setServices }: ServiceTabProps) {
    const navigate = useNavigate();
    
    return (
        <>
            <BigText>Serviços</BigText>
            <NormalText color={colors.mediumGray}>Aqui consta os serviços que sua barbearia oferece</NormalText>

            <SearchAndButtonContainer>
                <SearchContainer>
                    <InputContainer>
                        <SearchIcon />
                        <Input type="text" placeholder="Pesquisar serviço" />
                    </InputContainer>
                </SearchContainer>
                <ActionButton width='fit-content' onClick={() => navigate('service/new')} text="Adicionar" />
            </SearchAndButtonContainer>

            { services.length > 0 && (
                <ServicesContainer>
                    {services.map(service => (
                        <Service 
                            key={service.id}
                            onClick={() => navigate(`service/${service.id}`)}
                        >
                            <NormalText color={colors.mediumGray} bold>{service.nome}</NormalText>
                            <ServiceValueAndTimeContainer>
                                <MediumText color={colors.darkGray} bold>R$ {service.preco}</MediumText>
                                <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                            </ServiceValueAndTimeContainer>
                        </Service>
                    ))}
                </ServicesContainer>
            )}            
        </>
    );
}
