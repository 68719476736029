import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import { FaSearch } from "react-icons/fa";

const SearchContainer = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

const InputContainer = styled.div`
    position: relative;
    width: 100%;
`;

const Input = styled.input`
    padding: 10px 10px 10px 35px;
    border: 1px solid ${colors.gray};
    border-radius: 5px;
    width: 100%;
`;

const SearchIcon = styled(FaSearch)`
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: ${colors.gray};
`;

type SearchInputProps = {
    placeholder: string;
    onSearch: (value: string) => void;
};

const SearchInput: React.FC<SearchInputProps> = ({ placeholder, onSearch }) => {
    const [searchTerm, setSearchTerm] = useState("");

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        onSearch(value);
    };

    return (
        <SearchContainer>
            <InputContainer>
                <SearchIcon />
                <Input
                    type="text"
                    placeholder={placeholder}
                    value={searchTerm}
                    onChange={handleInputChange}
                />
            </InputContainer>
        </SearchContainer>
    );
};

export default SearchInput;