import React from "react";
import { InputValue, LabelAndValueContainer } from "../../../../../globalStyles";
import colors from "../../../../../../style/colors";
import NormalText from "../../../../../../components/texts/NormalText";
import BigText from "../../../../../../components/texts/BigText";

type InformationsTabProps = {
    informations: {
        nome: string;
        telefone: string;
    };
    setInformations: React.Dispatch<React.SetStateAction<{
        nome: string;
        telefone: string;
    }>>;
}

export default function InformationsTab({ informations, setInformations }: InformationsTabProps) {
    return (
        <>
            <BigText>Informações</BigText >
            <NormalText color={colors.mediumGray}>Informe seus dados para contato</NormalText>

            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Nome</NormalText>
                <InputValue
                    type="text"
                    placeholder="Seu nome aqui"
                    value={informations.nome}
                    onChange={(e) => setInformations({
                        ...informations,
                        nome: e.target.value
                    })}
                />
            </LabelAndValueContainer>
            <LabelAndValueContainer>
                <NormalText color={colors.mediumGray} bold>Telefone</NormalText>
                <InputValue
                    type="number"
                    placeholder="1799999-9999"
                    value={informations.telefone}
                    onChange={(e) => setInformations({
                        ...informations,
                        telefone: e.target.value
                    })}
                />
            </LabelAndValueContainer>
        </>
    )
}