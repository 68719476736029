import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import NormalText from "../../../../../components/texts/NormalText";
import SmallText from "../../../../../components/texts/SmallText";
import colors from "../../../../../style/colors";
import { ReservationAusencesBarbersListType, ReservationAusencesType } from "../../../../../types/reservation";
import { getHourFromDateTime } from "../../../../../functions/date";
import { useNavigate } from "react-router-dom";
import { BarbeariaType, OpeningHourType } from "../../../../../types/barbershop";
import { BarbersType, BarberType } from "../../../../../types/barber";
import BarbersViewer from "../../../../../components/BarbersViewer";
import { ScreenSizeHook } from "../../../../../hooks/ScreenSizeHook";
import LinkText from "../../../../../components/Link";
import { formatHourToHHMM } from "../../../../../functions/hour";
import { AuthContext } from "../../../../../context/authContext";
import { toast } from "react-toastify";

const Container = styled.div`
    display: flex;
    width: 100%;
    gap: 5px;
    overflow-x: auto;
    align-items: flex-start;
`;

const ScheduleByBarberContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const TimeSlot = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: white;
    height: 20px; 
`;

const IntegerHourContainer = styled.div<{ isMobileView?: boolean, reservationsAndAusences?: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: fit-content;
    width: ${props => props.isMobileView ? '200px' : '420px'};
    ${props => (props?.reservationsAndAusences ?? 1) === 1 && 'width: 420px;'}
    gap: 10px;
`;

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${colors.mediumGray};
`;

type EventStyledProps = {
    top: number;
    height: number;
    width?: number;
    left?: number;
    color?: string;
    backgroundColor?: string;
    backgroundHoverColor?: string;
    borderColor?: string;
};

const Event = styled.div<EventStyledProps>`
    position: absolute;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border: 1px solid ${({ borderColor }) => borderColor};
    color: ${({ color }) => color};
    padding: 5px;
    border-radius: 3px;
    height: ${({ height }) => height}px;
    width: ${({ width }) => width}px;
    left: ${({ left }) => left}px;
    
    top: ${({ top }) => top}px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 3px;
    z-index: 1;
    cursor: pointer;
    transition: background-color 0.2s;
    user-select: none;
    overflow: hidden;

    &:hover {
        background-color: ${({ backgroundHoverColor }) => backgroundHoverColor};
    }

    &:active {
        background-color: ${({ backgroundColor }) => backgroundColor};
        outline: 1px solid ${({ borderColor }) => borderColor};
    }
`;

const TimeSlotsContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;

const CurrentTimeLine = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${colors.red};
    z-index: 2;
`;

type CalendarScheduleProps = {
    reservationsAndAusences: ReservationAusencesBarbersListType;
    selectedBarberShop: BarbeariaType | null;
    selectedBarbers: BarbersType;
    barberOpeningHours: OpeningHourType[];
    selectedDate: Date;
};

type EventType = {
    top: number;
    height: number;
    startTime: string;
    endTime: string;
    title: string;
    width: number;
    left: number;
    agendamento_ausencia: ReservationAusencesType;
    type?: string;
    barbearia_usuario_id: number;
};

export default function CalendarSchedule({ reservationsAndAusences, selectedBarberShop, selectedBarbers, barberOpeningHours, selectedDate }: CalendarScheduleProps) {
    const navigate = useNavigate()
    const [plotedReservationsAndAusences, setPlotedReservationsAndAusences] = useState<EventType[]>([]);
    const [currentTime, setCurrentTime] = useState(new Date());
    const [timeSlots, setTimeSlots] = useState<string[]>([]);
    const [startTime, setStartTime] = useState<Date>();
    const [endTime, setEndTime] = useState<Date>();
    const { isMobileView } = ScreenSizeHook();
    const { user } = useContext(AuthContext);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {

        if (barberOpeningHours.length === 0) return;

        const startTime = new Date();
        const endTime = new Date();
        let startHour = Math.min(...barberOpeningHours.map(hour => Number(hour?.hora_abertura?.split(":")[0])));
        let startMinute = Math.min(...barberOpeningHours.map(hour => Number(hour?.hora_abertura?.split(":")[1])));
        startTime.setHours(startHour, startMinute, 0, 0);

        let endHour = Math.max(...barberOpeningHours.map(hour => Number(hour?.hora_fechamento?.split(":")[0])));
        let endMinute = Math.max(...barberOpeningHours.map(hour => Number(hour?.hora_fechamento?.split(":")[1])));
        endTime.setHours(endHour, endMinute, 0, 0);

        const startHourFromEvent = Math.min(...reservationsAndAusences.flatMap(barber => barber.agendamentos_ausencias.map(agendamentoAusencia => {
            const { agendamento, ausencia } = agendamentoAusencia;
            if (agendamento) {
                return Number(getHourFromDateTime(agendamento.agendamento.data_agendamento).split(":")[0]);
            }
            if (ausencia) {
                return Number(ausencia.hora_inicio.split(":")[0]);
            }
            return 0;
        })));

        const endHourFromEvent = Math.max(...reservationsAndAusences.flatMap(barber => barber.agendamentos_ausencias.map(agendamentoAusencia => {
            const { agendamento, ausencia } = agendamentoAusencia;
            if (agendamento) {
                const tempo_estimado_em_minutos = agendamento.agendamento_servicos.reduce((acc, servico) => acc + servico.tempo_estimado, 0);
                const startTimePlusTempoEstimado = new Date(agendamento.agendamento.data_agendamento);
                startTimePlusTempoEstimado.setMinutes(startTimePlusTempoEstimado.getMinutes() + tempo_estimado_em_minutos);
                return Number(getHourFromDateTime(startTimePlusTempoEstimado.toISOString()).split(":")[0]) + 1;
            }
            if (ausencia) {
                return Number(ausencia.hora_fim.split(":")[0]) + 1;
            }
            return 0;
        })))

        startHour = Math.min(startHour, startHourFromEvent);
        endHour = Math.max(endHour, endHourFromEvent);

        setStartTime(startTime);
        setEndTime(endTime);

        const hours = Array.from({ length: endHour - startHour }, (_, index) => index + startHour);
        const minutes = [0, 10, 20, 30, 40, 50];
        const timeSlots = hours.flatMap(hour => minutes.map(minute => `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`));

        setTimeSlots(timeSlots);

    }, [barberOpeningHours, reservationsAndAusences]);

    useEffect(() => {
        let id = 0;

        const newPlotedEvents = reservationsAndAusences.flatMap((barber, index) =>
            barber.agendamentos_ausencias.map(agendamentoAusencia => {
                const { agendamento, ausencia } = agendamentoAusencia;
                id += 1;
                if (agendamento) {
                    const startTime = getHourFromDateTime(agendamento.agendamento.data_agendamento);
                    const tempo_estimado_em_minutos = agendamento.agendamento_servicos.reduce((acc, servico) => acc + servico.tempo_estimado, 0);
                    const startTimePlusTempoEstimado = new Date(agendamento.agendamento.data_agendamento);
                    startTimePlusTempoEstimado.setMinutes(startTimePlusTempoEstimado.getMinutes() + tempo_estimado_em_minutos);
                    const endTime = getHourFromDateTime(startTimePlusTempoEstimado.toISOString());

                    const startHour = Number(startTime.split(":")[0]);
                    const startMinute = Number(startTime.split(":")[1]);
                    const endHour = Number(endTime.split(":")[0]);
                    const endMinute = Number(endTime.split(":")[1]);

                    const top = (startHour - 8) * 60 + startMinute;
                    const height = ((endHour - startHour) * 60 + endMinute - startMinute); // 2px per minute

                    return {
                        id,
                        type: 'agendamento',
                        top,
                        height,
                        startTime,
                        endTime,
                        title: agendamento.cliente_nao_cadastrado.nome,
                        width: 100,
                        left: 0,
                        agendamento_ausencia: agendamentoAusencia,
                        barbearia_usuario_id: barber.barbearia_usuario_id,
                        index: index,
                    };
                }

                if (ausencia) {
                    const startTime = ausencia.hora_inicio.slice(0, 5);
                    const endTime = ausencia.hora_fim.slice(0, 5);

                    const startHour = Number(startTime.split(":")[0]);
                    const startMinute = Number(startTime.split(":")[1]);
                    const endHour = Number(endTime.split(":")[0]);
                    const endMinute = Number(endTime.split(":")[1]);

                    const top = (startHour - 8) * 60 + startMinute;
                    const height = ((endHour - startHour) * 60 + endMinute - startMinute);

                    return {
                        id,
                        type: 'ausencia',
                        top,
                        height,
                        startTime,
                        endTime,
                        title: ausencia.descricao,
                        width: 100,
                        left: 0,
                        agendamento_ausencia: agendamentoAusencia,
                        barbearia_usuario_id: barber.barbearia_usuario_id,
                        index: index,
                    };
                }

                return null;
            }).filter(event => event !== null)
        );

        const adjustedEvents = newPlotedEvents.map((event, _, arr) => {
            if (!event) return null;

            const overlappingEvents = arr.filter(e => e && e.top < event.top + event.height && e.top + e.height > event.top && e.barbearia_usuario_id === event.barbearia_usuario_id);

            overlappingEvents.forEach(overlappingEvent => {
                if (!overlappingEvent) return;
                const nestedOverlappingEvents = arr.filter(e => e && e.top < overlappingEvent.top + overlappingEvent.height && e.top + e.height > overlappingEvent.top && e.barbearia_usuario_id === overlappingEvent.barbearia_usuario_id);
                nestedOverlappingEvents.forEach(nestedEvent => {
                    if (!overlappingEvents.includes(nestedEvent)) {
                        overlappingEvents.push(nestedEvent);
                    }
                });
            });

            overlappingEvents.sort((a, b) => (a?.id ?? 0) - (b?.id ?? 0));

            let maxWidth = 0;
            if (reservationsAndAusences.length > 1) {
                if (isMobileView && event.index === 0) {
                    maxWidth = 170;
                }
                else if (isMobileView && event.index !== 0) {
                    maxWidth = 187;
                }
                else if (!isMobileView && event.index === 0) {
                    maxWidth = 380;
                }
                else if (!isMobileView && event.index !== 0) {
                    maxWidth = 405;
                }
            } else {
                maxWidth = 380;
            }

            const width = Math.min(maxWidth / overlappingEvents.length, maxWidth);

            let left = 0;
            if (isMobileView && event.index === 0) {
                left = 30 + overlappingEvents.indexOf(event) * width;
            }
            else if (isMobileView && event.index !== 0) {
                left = 15 + overlappingEvents.indexOf(event) * width;
            }
            else if (!isMobileView && event.index === 0) {
                left = 45 + overlappingEvents.indexOf(event) * width;
            }
            else if (!isMobileView && event.index !== 0) {
                left = 15 + overlappingEvents.indexOf(event) * width;
            }


            return {
                ...event,
                width: width - 2,
                left,
                height: event.height * 2,
            };
        });

        setPlotedReservationsAndAusences(adjustedEvents.filter(event => event !== null) as EventType[]);

    }, [reservationsAndAusences]);

    const handleNavigateToReservation = (event: EventType) => {
        if (selectedBarberShop) {
            const reservation_id = event.agendamento_ausencia?.agendamento?.agendamento.id;
            if (reservation_id) {
                return navigate(`/barbershop/${selectedBarberShop.id}/reservations/done/${reservation_id}`);
            }
        }
    }

    const getEvent = (event: EventType) => {
        const eventStartTime = new Date(currentTime);
        const [startHour, startMinute] = event.startTime.split(":").map(Number);
        eventStartTime.setHours(startHour, startMinute, 0, 0);

        const eventEndTime = new Date(currentTime);
        const [endHour, endMinute] = event.endTime.split(":").map(Number);
        eventEndTime.setHours(endHour, endMinute, 0, 0);
        if (event.type === 'agendamento') {

            if (currentTime >= eventStartTime && currentTime <= eventEndTime) {
                return (
                    <Event
                        top={7}
                        height={event.height}
                        width={event.width}
                        left={event.left}
                        backgroundColor={colors.primary}
                        borderColor={colors.primary}
                        color={colors.white}
                        backgroundHoverColor={colors.primaryHover}
                        onClick={() => handleNavigateToReservation(event)}
                    >
                        {isMobileView ? (
                            <>
                                <SmallText color={colors.white}>Agendamento</SmallText>
                                <SmallText color={colors.white}>{event.startTime} - {event.endTime}</SmallText>
                                <SmallText color={colors.white}>{event.agendamento_ausencia?.agendamento?.cliente_nao_cadastrado.nome}</SmallText>
                            </>
                        ) : (
                            <>
                                <NormalText color={colors.white}>Agendamento</NormalText>
                                <NormalText color={colors.white}>{event.startTime} - {event.endTime}</NormalText>
                                <NormalText color={colors.white}>{event.agendamento_ausencia?.agendamento?.cliente_nao_cadastrado.nome}</NormalText>
                            </>
                        )}

                    </Event>
                );
            }
            return (
                <Event
                    top={7}
                    height={event.height}
                    width={event.width}
                    left={event.left}
                    backgroundColor={colors.primaryEvents}
                    borderColor={colors.primary}
                    color={colors.white}
                    backgroundHoverColor={colors.primaryHover}
                    onClick={() => handleNavigateToReservation(event)}
                >
                    {isMobileView ? (
                        <>
                            <SmallText color={colors.white}>Agendamento</SmallText>
                            <SmallText color={colors.white}>{event.startTime} - {event.endTime}</SmallText>
                            <SmallText color={colors.white}>{event.agendamento_ausencia?.agendamento?.cliente_nao_cadastrado.nome}</SmallText>
                        </>
                    ) : (
                        <>
                            <NormalText color={colors.white}>Agendamento</NormalText>
                            <NormalText color={colors.white}>{event.startTime} - {event.endTime}</NormalText>
                            <NormalText color={colors.white}>{event.agendamento_ausencia?.agendamento?.cliente_nao_cadastrado.nome}</NormalText>
                        </>
                    )}
                </Event>
            );
        }
        if (event.type === 'ausencia') {
            if (currentTime >= eventStartTime && currentTime <= eventEndTime) {
                return (
                    <Event
                        top={7}
                        height={event.height}
                        width={event.width}
                        left={event.left}
                        backgroundColor={colors.secondary}
                        borderColor={colors.secondary}
                        color={colors.white}
                        backgroundHoverColor={colors.secondaryHover}
                        onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/ausences/ausence/${event.agendamento_ausencia?.ausencia?.id}`)}
                    >
                        {isMobileView ? (
                            <>
                                <SmallText>Ausencia</SmallText>
                                <SmallText>{event.startTime} - {event.endTime}</SmallText>
                                <SmallText>{event.agendamento_ausencia?.ausencia?.descricao}</SmallText>
                            </>
                        ) : (
                            <>
                                <NormalText>Ausencia</NormalText>
                                <NormalText>{event.startTime} - {event.endTime}</NormalText>
                                <NormalText>{event.agendamento_ausencia?.ausencia?.descricao}</NormalText>
                            </>
                        )}
                    </Event>
                );
            }
            return (
                <Event
                    top={7}
                    height={event.height}
                    width={event.width}
                    left={event.left}
                    backgroundColor={colors.secondaryEvents}
                    borderColor={colors.secondary}
                    color={colors.white}
                    backgroundHoverColor={colors.secondaryHover}
                    onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/ausences/ausence/${event.agendamento_ausencia?.ausencia?.id}`)}
                >
                    {isMobileView ? (
                        <>
                            <SmallText>Ausencia</SmallText>
                            <SmallText>{event.startTime} - {event.endTime}</SmallText>
                            <SmallText>{event.agendamento_ausencia?.ausencia?.descricao}</SmallText>
                        </>
                    ) : (
                        <>
                            <NormalText>Ausencia</NormalText>
                            <NormalText>{event.startTime} - {event.endTime}</NormalText>
                            <NormalText>{event.agendamento_ausencia?.ausencia?.descricao}</NormalText>
                        </>
                    )}
                </Event>
            );
        }
        return <></>;
    }

    const handleClickOpeningHours = (barber: BarberType) => {
        if (barber.id === user?.id) {
            navigate(`/barbershops/barbershop/${selectedBarberShop?.id}`)
        } else {
            toast.error('Não é possível acessar o horário de atendimento de outro barbeiro')
        }
    }

    return (
        <Container>
            {reservationsAndAusences.map((reservationAndAusence, indexReservationAndAusences) => {
                return (
                    <ScheduleByBarberContainer>
                        <BarbersViewer barbers={selectedBarbers.filter(barber => barber.barbearia_usuario_id === reservationAndAusence.barbearia_usuario_id)} />

                        <LinkText onClick={() => handleClickOpeningHours(
                            selectedBarbers.find(barber => barber.barbearia_usuario_id === reservationAndAusence.barbearia_usuario_id) as BarberType
                        )} >
                            <SmallText color={colors.blue}>Horário de atendimento: {
                                barberOpeningHours.map(hour => {
                                    if (hour?.barbearia_usuario_id === reservationAndAusence?.barbearia_usuario_id) {
                                        return `${formatHourToHHMM(hour?.hora_abertura)} - ${formatHourToHHMM(hour?.hora_fechamento)}`;
                                    }
                                    return '';
                                })}
                            </SmallText>
                        </LinkText>

                        <TimeSlotsContainer>
                            {currentTime.getDay() === selectedDate?.getDay() && currentTime.getHours() >= (startTime?.getHours() ?? 0) && currentTime.getHours() < (endTime?.getHours() ?? 24) && (
                                <CurrentTimeLine
                                    style={{ top: ((currentTime.getHours() - (startTime?.getHours() ?? 0)) * 120 + currentTime.getMinutes() * 2) + 7 }}
                                />
                            )
                            }
                            {timeSlots.length === 0 && (
                                <NormalText>Não há horários disponíveis</NormalText>
                            )}
                            {timeSlots.map((hour) => {
                                return (
                                    <TimeSlot key={hour}>
                                        <IntegerHourContainer
                                            isMobileView={isMobileView}
                                            reservationsAndAusences={reservationsAndAusences?.length}
                                        >
                                            {indexReservationAndAusences === 0 ?
                                                (
                                                    <>
                                                        {["00", "30"].includes(hour.split(":")[1]) ? (
                                                            <>
                                                                {isMobileView ? (
                                                                    <SmallText
                                                                        bold
                                                                        width="20px"
                                                                        align="left"
                                                                    >
                                                                        {hour}
                                                                    </SmallText>
                                                                ) : (
                                                                    <NormalText
                                                                        bold
                                                                        width="40px"
                                                                        align="left"
                                                                    >
                                                                        {hour}
                                                                    </NormalText>
                                                                )
                                                                }
                                                            </>
                                                        ) : (
                                                            <>
                                                                {isMobileView ? (
                                                                    <SmallText
                                                                        color={colors.mediumGray}
                                                                        width="20px"
                                                                    >
                                                                        {hour}
                                                                    </SmallText>
                                                                ) : (
                                                                    <NormalText
                                                                        color={colors.mediumGray}
                                                                        width="40px"
                                                                    >
                                                                        {hour}
                                                                    </NormalText>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                ) :
                                                (
                                                    <>
                                                        {["00", "30"].includes(hour.split(":")[1]) ? (
                                                            <NormalText bold>{'-'}</NormalText>
                                                        ) : (
                                                            <SmallText color={colors.mediumGray}>{'-'}</SmallText>
                                                        )}
                                                    </>
                                                )
                                            }
                                            <Line />
                                            {
                                                plotedReservationsAndAusences.map((event, index) => {
                                                    if (event.startTime === hour && event.barbearia_usuario_id === reservationAndAusence.barbearia_usuario_id) {
                                                        return getEvent(event);
                                                    }
                                                })
                                            }
                                        </IntegerHourContainer>
                                    </TimeSlot>
                                );
                            })
                            }
                        </TimeSlotsContainer>
                    </ScheduleByBarberContainer>
                );
            }
            )}
        </Container>
    );
}