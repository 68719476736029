import React from "react";
import BigText from "../../../../../../components/texts/BigText";
import NormalText from "../../../../../../components/texts/NormalText";
import colors from "../../../../../../style/colors";
import SelectDate from "../../../../../../components/SelectDate";
import { Hour, HoursAvailable, Separator } from "../../../../../globalStyles";
import { BarbersType, BarberType } from "../../../../../../types/barber";
import BarberSelector from "../../../../../../components/BarberSelector/index";

type ScheduleTabProps = {
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    barbers: BarbersType;
    selectedBarber?: BarberType;
    setSelectedBarber: React.Dispatch<React.SetStateAction<BarberType | undefined>>;
    hoursAvailable: String[];
    selectedHour: String;
    setSelectedHour: React.Dispatch<React.SetStateAction<String>>;
}

export default function ScheduleTab({ 
    selectedDate,
    setSelectedDate,
    barbers,
    selectedBarber,
    setSelectedBarber,
    hoursAvailable,
    selectedHour,
    setSelectedHour
 }: ScheduleTabProps) {
    return (
        <>
            <BigText>Agendamento</BigText>
            <NormalText color={colors.mediumGray}>Selecione a data/hora desejada para realizar o corte</NormalText>

            <NormalText bold>Selecione sua data de preferencia</NormalText>

            <SelectDate
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
            />

            <Separator />

            <NormalText bold>Escolha o barbeiro de sua preferencia</NormalText>
            <BarberSelector
                barbers={barbers}
                selectedBarber={selectedBarber}
                setSelectedBarber={setSelectedBarber}
            />

            <Separator />

            <NormalText bold>Selecione um horário disponível do barbeiro selecionado</NormalText>

            {hoursAvailable.length === 0 && (
                <NormalText color={colors.mediumGray} bold>Nenhum horário disponível</NormalText>
            )}
            
            <HoursAvailable>
                {hoursAvailable.map((hour, index) => (
                    <Hour 
                        key={index} 
                        selected={selectedHour === hour} 
                        onClick={() => setSelectedHour(hour)}
                    >
                        <NormalText 
                            color={selectedHour === hour ? colors.white : colors.darkGray}
                        bold>{hour}</NormalText>
                    </Hour>
                ))}
            </HoursAvailable>
        </>
    );
}