import styled, { keyframes } from "styled-components";
import colors from "../../style/colors";

export const Container = styled.div<{ backgroundColor: string, borderColor?: string }>`
    width: 100%;
    padding: 20px 20%;
    display: flex;
    justify-content: center;
    background-color: ${props => props.backgroundColor};
    min-height: 80vh;
    gap: 20px;
    border: 1px solid ${props => props.borderColor ? props.borderColor : 'none'};

    @media (max-width: 768px) {
        padding: 10px;
        flex-direction: column;
        gap: 10px; 
        max-width: 100vw;       
    }
`;

export const GroupTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto 0;
    max-width: 30%;

    @media (max-width: 768px) {
        max-width: 100%;
        text-align: center;
    }
`;

export const AnimationContainer = styled.div`
    width: 100%;
`;

export const TextWithLetterSpacing = styled.p`
    color: ${colors.darkGray};
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 2px;
    text-align: center;
    margin: 20px 0;

    @media (max-width: 768px) {
        font-size: 18px;
    }

    @media (max-width: 480px) {
        font-size: 16px;
    }
`;

const slideInFromLeft = keyframes`
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

const slideInFromRight = keyframes`
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
`;

export const ImageContainer = styled.div<{ backgroundImage?: string, opacity?: number }>`
    width: 40%;
    background-image: url(${props => props.backgroundImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    opacity: ${props => props.opacity || 1};
    transition: opacity 0.5s ease-out;

    &.slide-in-left {
        animation: ${slideInFromLeft} 1s forwards;
    }

    &.slide-in-right {
        animation: ${slideInFromRight} 1s forwards;
    }

    @media (max-width: 768px) {
        height: 40vh;
    }

    @media (max-width: 480px) {
        width: 100%;
    }
`;

export const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;    
    align-content: center;

    @media (max-width: 768px) {
        gap: 10px;
    }
`;

export const GridHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    background-color: ${colors.primary};
    padding: 10px;

    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
`;

export const GridRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid ${colors.gray};
    align-items: center;

    @media (max-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
`;

export const ContentContainer = styled.div<{ backgroundColor: string, borderColor?: string }>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
    padding: 70px 100px;
    background-color: ${props => props.backgroundColor};
    border: 1px solid ${props => props.borderColor ? props.borderColor : colors.gray};
    border-radius: 5px;
    align-items: center;
    height: fit-content;
    margin: auto 0;

    @media (max-width: 768px) {
        padding: 50px 20px;
        max-width: 90%;
    }

    @media (max-width: 480px) {
        padding: 30px 10px;
        max-width: 100%;
    }
`;

export const PlansContainer = styled.div`
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
`;

export const PlanCard = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border: 1px solid ${colors.gray};
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 320px;

    @media (max-width: 768px) {
        width: 100%;
        height: 250px;
    }
`;


export const PlanValueContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 5px;
    }
`;

export const PlanDiscountContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    flex-direction: column;
`;