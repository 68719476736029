
const colors = {
    lightGray: '#F9F8F8',
    gray: '#E5E5E5',
    white: '#ffffff',
    mediumGray: '#9a9a9a',
    darkGray: '#333333',
    red: '#9d0208',
    lightRed: '#c32530',
    blue: '#2381e0',
    blueHover: '#1565c0',
    green: '#4caf50',
    black: '#000000',

    primary: '#14213D',
    primaryHover: '#010408',
    primaryEvents: 'rgba(20, 33, 61, 0.7)',

    secondary: '#FCA311',    
    secondaryHover: '#FCA311',
    secondaryEvents: 'rgba(252, 163, 17, 0.7)',

}

export default colors