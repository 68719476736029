import React, { useContext } from "react";
import styled from "styled-components";
import BigText from "../../../../../components/texts/BigText";
import colors from "../../../../../style/colors";
import NormalText from "../../../../../components/texts/NormalText";
import { OpeningHoursType } from "../../../../../types/barbershop";
import { AuthContext } from "../../../../../context/authContext";
import { BarberDetailType } from "../../../../../types/barber";
import { InputValue } from "../../../../globalStyles";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    align-items: center;

`;

type HoursTabProps = {
    openingHours: OpeningHoursType;
    setOpeningHours: (openingHours: OpeningHoursType) => void
    currentBarber?: BarberDetailType
}

export default function HoursTab({ openingHours, setOpeningHours, currentBarber }: HoursTabProps) {
    const {user} = useContext(AuthContext);

    if (!user) {
        return null;
    }

    const handleChangeOpeningHour = (e: React.ChangeEvent<HTMLInputElement>, dia_semana: number, field: "hora_abertura" | "hora_fechamento") => {
        const existingHourIndex = openingHours.findIndex(hour => hour.dia_semana === dia_semana);

        if (existingHourIndex !== -1) {
            const newOpeningHours = openingHours.map((hour, index) => {
                if (index === existingHourIndex) {
                    return {
                        ...hour,
                        [field]: e.target.value === "" ? '00:00' : e.target.value
                    };
                }
                return hour;
            });
            setOpeningHours(newOpeningHours);
        } else {
            const newHour = {
                dia_semana,
                hora_abertura: field === "hora_abertura" ? e.target.value : '00:00',
                hora_fechamento: field === "hora_fechamento" ? e.target.value : '00:00',
                barbearia_usuario_id: currentBarber?.usuario_barbearia.id ?? 0,
                data_criacao: new Date().toISOString()
            };
            setOpeningHours([...openingHours, newHour]);
        }

    }

    return (
        <>
            <BigText>Horários de agenda (Por barbeiro)</BigText>
            <NormalText color={colors.mediumGray}>Aqui consta os horários que estára disponível na sua agenda como barbeiro nesta barbearia</NormalText>
            <NormalText color={colors.mediumGray}>(Esse não é o horário de abertura e fechamento da barbearia)</NormalText>
            {openingHours.length > 0 && (
                <Grid>
                    <NormalText color={colors.darkGray}>Dia</NormalText>
                    <NormalText color={colors.darkGray}>Abertura</NormalText>
                    <NormalText color={colors.darkGray}>Fechamento</NormalText>

                    <NormalText color={colors.darkGray} bold>Segunda</NormalText>
                    <InputValue
                        type="time"
                        placeholder="00:00" value={openingHours.find(hour => hour.dia_semana === 1)?.hora_abertura ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 1, "hora_abertura")}
                    />
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 1)?.hora_fechamento ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 1, "hora_fechamento")}
                    />

                    <NormalText color={colors.darkGray} bold>Terça</NormalText>
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 2)?.hora_abertura ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 2, "hora_abertura")}
                    />
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 2)?.hora_fechamento ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 2, "hora_fechamento")}
                    />

                    <NormalText color={colors.darkGray} bold>Quarta</NormalText>
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 3)?.hora_abertura ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 3, "hora_abertura")}
                    />
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 3)?.hora_fechamento ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 3, "hora_fechamento")}
                    />

                    <NormalText color={colors.darkGray} bold>Quinta</NormalText>
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 4)?.hora_abertura ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 4, "hora_abertura")}
                    />
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 4)?.hora_fechamento ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 4, "hora_fechamento")}
                    />

                    <NormalText color={colors.darkGray} bold>Sexta</NormalText>
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 5)?.hora_abertura ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 5, "hora_abertura")}
                    />
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 5)?.hora_fechamento ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 5, "hora_fechamento")}
                    />

                    <NormalText color={colors.darkGray} bold>Sábado</NormalText>
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 6)?.hora_abertura ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 6, "hora_abertura")}
                    />
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 6)?.hora_fechamento ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 6, "hora_fechamento")}
                    />

                    <NormalText color={colors.darkGray} bold>Domingo</NormalText>
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 7)?.hora_abertura ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 7, "hora_abertura")}
                    />
                    <InputValue
                        type="time"
                        placeholder="00:00"
                        value={openingHours.find(hour => hour.dia_semana === 7)?.hora_fechamento ?? ""}
                        onChange={(e) => handleChangeOpeningHour(e, 7, "hora_fechamento")}
                    />
                </Grid>
            )}

        </>
    );
}   
