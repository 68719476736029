import React, { useContext } from "react";
import styled from "styled-components";
import colors from "../../../../../style/colors";
import { ReservationAusencesBarbersListType } from "../../../../../types/reservation";
import NormalText from "../../../../../components/texts/NormalText";
import MediumText from "../../../../../components/texts/MediumText";
import { BarbersType, BarberType } from "../../../../../types/barber";
import BarbersViewer from "../../../../../components/BarbersViewer";
import { getHourFromDateTime } from "../../../../../functions/date";
import { calculateDurationInMinutes, formatHourToHHMM } from "../../../../../functions/hour";
import { BarbeariaType, OpeningHourType } from "../../../../../types/barbershop";
import { useNavigate } from "react-router-dom";
import LinkText from "../../../../../components/Link";
import { AuthContext } from "../../../../../context/authContext";
import { toast } from "react-toastify";
import SmallText from "../../../../../components/texts/SmallText";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: flex-start;
`;

const ScheduleContainer = styled.div`
    display: flex;
    gap: 10px;
    width: 100%;
    border-bottom: 1px solid ${colors.gray};
    padding: 10px 10px;
    cursor: pointer;    
    transition: background-color 0.3s;
    border-radius: 5px;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        background-color: ${colors.lightGray};
    }

    &:active {
        background-color: ${colors.gray};
    }
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
`;

const RowContainer = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
`;

const VerticalSeparator = styled.div<{ color?: string }>`
    width: 4px;
    background-color: ${props => props.color || colors.black};
`;

type Props = {
    reservationsAndAusences: ReservationAusencesBarbersListType;
    selectedBarbers: BarbersType;
    selectedBarberShop: BarbeariaType | null;
    barberOpeningHours: OpeningHourType[];
}

export default function ListSchedules({ reservationsAndAusences, selectedBarbers, selectedBarberShop, barberOpeningHours }: Props) {
    const navigate = useNavigate()
    const { user } = useContext(AuthContext)

    const handleClickOpeningHours = (barber: BarberType) => {
        if (barber.id === user?.id) {
            navigate(`/barbershops/barbershop/${selectedBarberShop?.id}`)
        } else {
            toast.error('Não é possível acessar o horário de atendimento de outro barbeiro')
        }    
    }

    return (
        <Container>
            {selectedBarbers.map((barber, index) => {
                return (
                    <>
                        <BarbersViewer key={index} barbers={[barber]} />

                        <LinkText onClick={() => {handleClickOpeningHours(barber)}} >
                            <SmallText color={colors.blue}>Horário de atendimento: {
                                barberOpeningHours.map(hour => {
                                    if (hour?.barbearia_usuario_id === barber?.barbearia_usuario_id) {
                                        return `${formatHourToHHMM(hour?.hora_abertura)} - ${formatHourToHHMM(hour?.hora_fechamento)}`;
                                    }
                                    return '';
                                })}
                            </SmallText>
                        </LinkText>

                        {reservationsAndAusences.map((reservationAndAusences, index) => {
                            if (reservationAndAusences.barbearia_usuario_id === barber.barbearia_usuario_id) {
                                return (
                                    <>
                                        {reservationAndAusences.agendamentos_ausencias.map((reservationAndAusence, index) => {
                                            if (reservationAndAusence.agendamento) {
                                                return (
                                                    <ScheduleContainer
                                                        key={index}
                                                        onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/reservations/done/${reservationAndAusence.agendamento?.agendamento.id}`)}
                                                    >
                                                        <ColumnContainer>
                                                            <MediumText bold>{getHourFromDateTime(reservationAndAusence.agendamento.agendamento.data_agendamento)}</MediumText>
                                                            <NormalText>{reservationAndAusence.agendamento.servicos.reduce(
                                                                (acc, servico) => acc + servico.tempo_estimado, 0
                                                            )} min</NormalText>
                                                        </ColumnContainer>
                                                        <VerticalSeparator color={colors.primary} />
                                                        <ColumnContainer>
                                                            <NormalText bold>{reservationAndAusence.agendamento.cliente_nao_cadastrado.nome}</NormalText>
                                                            <RowContainer>
                                                                <NormalText bold>Reserva</NormalText>
                                                                <MediumText>{reservationAndAusence.agendamento.cliente_nao_cadastrado.nome}</MediumText>
                                                            </RowContainer>
                                                        </ColumnContainer>
                                                    </ScheduleContainer>
                                                )
                                            }
                                            return (
                                                <ScheduleContainer
                                                    key={index}
                                                    onClick={() => navigate(`/barbershop/${selectedBarberShop?.id}/ausences/ausence/${reservationAndAusence?.ausencia?.id}`)}
                                                >
                                                    <ColumnContainer>
                                                        <MediumText bold>{formatHourToHHMM(reservationAndAusence.ausencia?.hora_inicio ?? '')}</MediumText>
                                                        <NormalText>{calculateDurationInMinutes(reservationAndAusence.ausencia?.hora_inicio ?? '', reservationAndAusence.ausencia?.hora_fim ?? '')} min</NormalText>
                                                    </ColumnContainer>
                                                    <VerticalSeparator color={colors.secondary} />
                                                    <ColumnContainer>
                                                        <NormalText bold>Ausência</NormalText>
                                                        <RowContainer>
                                                            <NormalText bold>Reserva</NormalText>
                                                            <MediumText>{reservationAndAusence.ausencia?.descricao}</MediumText>
                                                        </RowContainer>
                                                    </ColumnContainer>
                                                </ScheduleContainer>
                                            )
                                        }
                                        )
                                        }
                                    </>
                                )
                            }

                        }
                        )
                        }

                    </>

                )
            }
            )
            }
        </Container>
    )
}