import React from "react";
import colors from "../../../../../../../style/colors";
import Dropdown from "../../../../../../../components/DropDown";
import NormalText from "../../../../../../../components/texts/NormalText";
import BigText from "../../../../../../../components/texts/BigText";
import { InputValue, Separator } from "../../../../../../globalStyles";
import DaysOfWeekSelector from "../../../../../../../components/DaysOfWeekSelector";
import { BarbersType } from "../../../../../../../types/barber";
import BarbersSelector from "../../../../../../../components/BarbersSelector";
import { RecurrentReservationsType, RecurrentReservationType } from "../../../../../../../types/reservation";

type Props = {
    durationInMonths: string;
    setDurationInMonths: (durationInMonths: string) => void;
    dayOfWeekPreference: number;
    setDayOfWeekPreference: (dayOfWeekPreference: number) => void;
    hourPreference: string;
    setHourPreference: (hourPreference: string) => void;
    durationsInMonthsOptions: string[];
    barberPreference: BarbersType;
    setBarberPreference: React.Dispatch<React.SetStateAction<BarbersType>>;
    barbers: BarbersType;

    recurrentReservationTypes: RecurrentReservationsType;
    setRecurrentReservationTypes: React.Dispatch<React.SetStateAction<RecurrentReservationsType>>;
    selectedRecurrentReservationType: RecurrentReservationType | undefined;
    setSelectedRecurrentReservationType: React.Dispatch<React.SetStateAction<RecurrentReservationType | undefined>>;
}

export default function PeriodTab(
    {
        durationInMonths,
        setDurationInMonths,
        dayOfWeekPreference: daysOfWeekPreference,
        setDayOfWeekPreference: setDaysOfWeekPreference,
        hourPreference,
        setHourPreference,
        durationsInMonthsOptions,
        barberPreference,
        setBarberPreference,
        barbers,
        recurrentReservationTypes,
        setRecurrentReservationTypes,
        selectedRecurrentReservationType,
        setSelectedRecurrentReservationType,
    }: Props
) {

    const handleDayOfWeek = (day: number) => {
        if (daysOfWeekPreference === day) {
            setDaysOfWeekPreference(0);
        } else {
            setDaysOfWeekPreference(day);
        }
    }

    return (
        <>
            <BigText>Preferencias</BigText >
            <NormalText color={colors.mediumGray}>Selecione sua preferencia para a recorrencia</NormalText>

            <NormalText color={colors.darkGray} bold>Periodo de recorrencia (Duração)</NormalText>
            <Dropdown
                options={durationsInMonthsOptions.map(option => ({ label: option, value: option }))}
                selectedOption={{ label: durationInMonths, value: durationInMonths }}
                setSelectedOption={(option) => setDurationInMonths(option.value)}
            />

            <NormalText color={colors.darkGray} bold>Tipo de recorrencia</NormalText>
            <Dropdown
                options={recurrentReservationTypes.map(option => ({ label: option.nome, value: option }))}
                selectedOption={{ label: selectedRecurrentReservationType?.nome || '', value: selectedRecurrentReservationType}}
                setSelectedOption={(option) => setSelectedRecurrentReservationType(option.value)}
            />

            <Separator width="60px" height="3px" />

            <DaysOfWeekSelector
                days={[daysOfWeekPreference]}
                handleDaysOfWeek={handleDayOfWeek}
                title="Dia da semana de preferencia"
            />

            <Separator width="60px" height="3px" />

            <NormalText color={colors.darkGray} bold>Hora de prefencia</NormalText>
            <InputValue
                type="time"
                value={hourPreference}
                onChange={(e) => setHourPreference(e.target.value)}
            />

            <Separator width="60px" height="3px" />

            <NormalText bold>Escolha o barbeiro de sua preferencia</NormalText>
            <BarbersSelector
                barbers={barbers}
                selectedBarbers={barberPreference}
                setSelectedBarbers={setBarberPreference}
                onlyOneBarber
            />
        </>
    )
}