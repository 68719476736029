import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";

type LinkTextProps = {
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    children: React.ReactNode;
    width?: string;
};

const StyledLinkText = styled.a<{ disabled?: boolean, width?: string }>`
    padding: 3px 7px;
    color: ${props => (props.disabled ? colors.gray : colors.darkGray)};
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
    width: ${props => props.width || "auto"};
    
    transition: color 0.3s;

    &:hover {
        color: ${props => (props.disabled ? colors.gray : colors.primary)};
        background-color: ${props => (props.disabled ? "transparent" : colors.lightGray)};
    }
`;

const LinkText: React.FC<LinkTextProps> = ({ disabled, onClick, children, width }) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (disabled) {
            event.preventDefault();
            return;
        }
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <StyledLinkText disabled={disabled} onClick={handleClick} width={width}>
            {children}
        </StyledLinkText>
    );
};

export default LinkText;