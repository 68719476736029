import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { DropdownContainer, DropdownLabel, DropdownOption, DropdownOptionsContainer, RelativeDropdownContainer } from "./style";
import colors from "../../style/colors";
import React, { useEffect, useRef } from "react";

type Option = {
    label: string;
    value: any;
};

type Props = {   
    options: Option[];
    selectedOption: Option;
    setSelectedOption: (option: Option) => void;
};

export default function Dropdown({ options, selectedOption, setSelectedOption }: Props) {
    const [dropdownOpen, setDropdownOpen] = React.useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleOptionClick = (option: Option) => {
        setSelectedOption(option);
        setDropdownOpen(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <RelativeDropdownContainer ref={dropdownRef}>
            <DropdownContainer onClick={handleDropdownClick}>
                <DropdownLabel>
                    {selectedOption.label}
                </DropdownLabel>
                {dropdownOpen ? <FaChevronUp color={colors.darkGray} size={15} /> : <FaChevronDown color={colors.darkGray} size={15} />}
            </DropdownContainer>
            {dropdownOpen && (
                <DropdownOptionsContainer>
                    {options.map((option, index) => (
                        <DropdownOption key={index} onClick={() => handleOptionClick(option)}>
                            {option.label}
                        </DropdownOption>
                    ))}
                </DropdownOptionsContainer>
            )}
        </RelativeDropdownContainer>
    );
}