import React from "react";
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen";
import { ConfirmationButton, InputValue, LabelAndValueContainer } from "../../globalStyles";
import NormalText from "../../../components/texts/NormalText";
import { LoadingHook } from "../../../hooks/LoadingHook";
import colors from "../../../style/colors";
import MediumText from "../../../components/texts/MediumText";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import DefaultContainer from "../../../components/Containers/DefaultContainer";
import CenteredContentContainer from "../../../components/Containers/CenteredContentContainer";
import BigText from "../../../components/texts/BigText";
import LinkText from "../../../components/Link";

export default function Login() {
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const { signIn } = React.useContext(AuthContext)
    const navigate = useNavigate()

    React.useEffect(() => {
        setIsLoading(true)
        hideLoadingWithDelay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleLogin = async () => {
        setIsLoading(true)
        try {
            await signIn(email, password)
            navigate('/')
        } catch (error) {
            console.error(error)
        }
        setIsLoading(false)
    }

    return (
        <DefaultContainer
            backgroundColor={colors.lightGray}
            borderColor={colors.gray}
        >
            <CenteredContentContainer
                width="420px"
            >
                <LoadingFreezeScreen isLoading={isLoading} />
                <BigText color={colors.darkGray} bold>Login</BigText>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Email</NormalText>
                    <InputValue
                        type="text"
                        placeholder="Seu email aqui"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </LabelAndValueContainer>
                <LabelAndValueContainer>
                    <NormalText color={colors.mediumGray} bold>Senha</NormalText>
                    <InputValue
                        type="password"
                        placeholder="Digite sua senha por favor"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </LabelAndValueContainer>
                {/* <LinkText>
                    <NormalText>
                        Esqueci minha senha
                    </NormalText>
                </LinkText> */}

                <ConfirmationButton
                    onClick={handleLogin}
                >
                    <MediumText color="white" bold>Entrar</MediumText>
                </ConfirmationButton>
            </CenteredContentContainer>
        </DefaultContainer>
    )
}