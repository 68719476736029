import React from "react";
import { Route, BrowserRouter, Routes, Outlet } from "react-router-dom";
import Home from "../pages/Home";
import GetReservation from "../pages/Reservations/NormalReservations/GetReservation";
import { AuthProvider } from "../context/authContext";
import Schedules from "../pages/Barber/Schedules";
import Login from "../pages/User/Login";
import Profile from "../pages/User/Profile";
import ListAusences from "../pages/Barber/Ausences/ListAusences";
import Ausence from "../pages/Barber/Ausences/Ausence";
import ServiceList from "../pages/BarberShop/Services/ServicesList";
import Barbershops from "../pages/BarberShop/Barbershops";
import BarberShopHome from "../pages/BarberShop/BarberShopHome";
import Service from "../pages/BarberShop/Services/Service";
import InviteBarber from "../pages/BarberShop/Barbers/InviteBarber";
import BarberBarberShop from "../pages/BarberShop/Barbers/Barber/index";
import BarberShopDetails from "../pages/BarberShop/BarberShop";
import NotFound from "../pages/NotFound";
import BaseLayout from "../components/BaseLayout";
import RegisterPage from "../pages/User/Register";
import Reservation from "../pages/Reservations/NormalReservations/Reservation";
import ScrollToTop from "../components/ScrollToTop";
import RecurrentReservation from "../pages/Reservations/NormalReservations/Reservation/RecurrentReservation";
import ReservationList from "../pages/Reservations/NormalReservations/ReservationList";
import DoneRecurrentReservation from "../pages/Reservations/RecurrentReservations/DoneRecurrentReservation";
import DonedReservation from "../pages/Reservations/NormalReservations/DonedReservation";

const GeneralBaseLayout = () => {
    return (
        <BaseLayout>
            <Outlet />
        </BaseLayout>
    )
}

export default function GeneralRoutes() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <AuthProvider>
                <Routes >
                    <Route path="/" element={<GeneralBaseLayout />} >
                        <Route index element={<Home />} />
                        <Route path="login" element={<Login />} />
                        <Route path="register" element={<RegisterPage />} />
                        <Route path="profile" element={<Profile />} />
                        <Route path="barbershops" >
                            <Route index element={<Barbershops />} />                          
                            <Route path='barbershop'>
                                <Route index element={<BarberShopDetails />} />
                                <Route path=':id_barbershop'>
                                    <Route index element={<BarberShopDetails />} />
                                    <Route path='service'>
                                        <Route index element={<ServiceList />} />
                                        <Route path=':id_service' element={<Service />} />
                                    </Route>
                                    <Route path="barber">
                                        <Route path="invite" element={<InviteBarber />} />
                                        <Route path=":id_barber" element={<BarberBarberShop />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                        <Route path="barbershop/:id_barbershop" >
                            <Route index element={<BarberShopHome />} />
                            <Route path="reservations">
                                <Route index element={<ReservationList />} />
                                <Route path="new" element={<Reservation />} />
                                <Route path="done/:id" element={<DonedReservation />} />
                                <Route path="recurrent">
                                    <Route path='new' element={<RecurrentReservation />} />
                                    <Route path='done/:id' element={<DoneRecurrentReservation />} />
                                </Route>
                            </Route>
                            <Route path='get-reservation' element={<GetReservation />} />
                            <Route path="ausences">
                                <Route index element={<ListAusences />} />
                                <Route path="ausence">
                                    <Route index element={<Ausence />} />
                                    <Route path=":id" element={<Ausence />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="schedule">
                            <Route index element={<Schedules />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
    )
}