import React, { useEffect } from "react";
import { LabelAndValueContainer, Separator, Service, Services, ServiceValueAndTimeContainer, TotalContainer } from "../../../globalStyles";
import NormalText from "../../../../components/texts/NormalText";
import colors from "../../../../style/colors";
import SmallText from "../../../../components/texts/SmallText";
import MediumText from "../../../../components/texts/MediumText";
import { FaCalendarCheck } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { DonedReservationContainer, InProgressReservationContainer } from "./style";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import { ReservationType } from "../../../../types/reservation";
import { api } from "../../../../config/api";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import { getDateFromDateTime, getHourFromDateTime } from "../../../../functions/date";
import { AuthContext } from "../../../../context/authContext";
import ButtonWithModal from "../../../../components/ButtonWithModal";
import DefaultContainer from "../../../../components/Containers/DefaultContainer";
import CenteredContentContainer from "../../../../components/Containers/CenteredContentContainer";
import GoBackTitle from "../../../../components/GoBackTitle";
import styled from "styled-components";
import BarbersViewer from "../../../../components/BarbersViewer";
import ActionButton from "../../../../components/ActionButton";
import WhatsAppButton from "../../../../components/WhatsAppButton";
import LinkText from "../../../../components/Link";
import { toast } from "react-toastify";
import { BarbeariaType } from "../../../../types/barbershop";

const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    // align-items: flex-start;
    width: 100%;
`;


export default function DonedReservation() {
    const { id, id_barbershop } = useParams();

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [reservation, setReservation] = React.useState<ReservationType>()
    const { user } = React.useContext(AuthContext);
    const [barberShop, setBarberShop] = React.useState<BarbeariaType>()

    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)

        const getReservation = async () => {
            try {
                const response = await api.get(`/agendamentos/${id}`)
                setReservation(response.data)
            } catch (error) {
            }
        }

        if (id) getReservation()
        hideLoadingWithDelay()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id])

    useEffect(() => {
        const getBarberShopData = async () => {
            const response = await api.get(`/barbearias/${id_barbershop}`)
            if (response) {
                setBarberShop(response.data)
            }
        }

        try {
            if (id_barbershop) {
                getBarberShopData()
            }
        } catch {
            toast.error('Falha ao obter barbearia')
        }

    }, [id_barbershop])

    const handleGoToWhatsAppClientChat = () => {
        if (!reservation) return

        const message = `Olá, gostaria de conversar sobre a reserva #${reservation.agendamento.id} do dia ${getDateFromDateTime(reservation.agendamento.data_agendamento)} às ${getHourFromDateTime(reservation.agendamento.data_agendamento)} para os serviços: ${reservation.servicos.map(service => service.nome).join(', ')} no valor de R$ ${reservation.servicos.reduce((acc, service) => acc + service.preco, 0)}.`
        const url = `https://api.whatsapp.com/send?phone=55${reservation.clienteNaoCadastrado.telefone}&text=${message}`;
        window.open(url, "_blank")
    }

    const handleGoToWhatsAppBarberChat = () => {
        if (!reservation) return
        const message = `Olá, gostaria de informar que tenho uma reserva sua para o dia ${getDateFromDateTime(reservation.agendamento.data_agendamento)} às ${getHourFromDateTime(reservation.agendamento.data_agendamento)} com os serviços: ${reservation.servicos.map(service => service.nome).join(', ')} no valor de R$ ${reservation.servicos.reduce((acc, service) => acc + service.preco, 0)}.`
        const url = `https://api.whatsapp.com/send?phone=55${reservation.barbeiro.telefone}&text=${message}`;
        window.open(url, "_blank")
    }

    const handleChangeStatusAgendamento = (status_id: number) => {
        setIsLoading(true)
        try {
            api.put(`/agendamentos/${id}`, { status_agendamento_id: status_id })
                .then(response => {
                    const agendamento = response.data
                    if (reservation) {
                        setReservation({ ...reservation, agendamento: { ...reservation.agendamento, status_agendamento_id: agendamento.status_agendamento_id } });
                    }
                })
        } catch (error) {
        }
        hideLoadingWithDelay()
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text={`Reserva${` ${reservation?.agendamento.id ? '# ' + reservation?.agendamento.id : ''} ${barberShop?.nome ? ' - ' + barberShop?.nome : ''}`}`} />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="flex-start"
                align="center"
            >
                <CenteredContentContainer
                    width="430px"
                >

                    {reservation && (
                        <>
                            <GroupContainer>
                                <NormalText align="left" bold>Serviços</NormalText>
                                {reservation.servicos.length > 0 && (
                                    <>
                                        <Services>
                                            {reservation.servicos.map((service, index) => (
                                                <Service
                                                    key={index}
                                                    isSelected={false}
                                                    disabled
                                                >
                                                    <MediumText color={colors.darkGray} bold>{service.nome}</MediumText>

                                                    <ServiceValueAndTimeContainer>
                                                        <MediumText color={colors.darkGray} bold>R$ {service.preco},00</MediumText>
                                                        <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                                                    </ServiceValueAndTimeContainer>
                                                </Service>
                                            ))}
                                        </Services>
                                    </>
                                )}
                            </GroupContainer>

                            <GroupContainer>
                                <NormalText align="left" bold>Barbeiro selecionado</NormalText>
                                {reservation.barbeiro && <BarbersViewer barbers={[reservation.barbeiro]} />}
                            </GroupContainer>

                            <GroupContainer>
                                <NormalText align="left" bold>Tempo e valor</NormalText>
                                <TotalContainer
                                    flexDirection="row"
                                >
                                    <LabelAndValueContainer>
                                        <SmallText color={colors.mediumGray} >Tempo estimado</SmallText>
                                        <NormalText color={colors.mediumGray} bold >{reservation.servicos.reduce((acc, service) => acc + service.tempo_estimado, 0)}</NormalText>
                                    </LabelAndValueContainer>
                                    <LabelAndValueContainer
                                        align="end"
                                    >
                                        <SmallText color={colors.mediumGray}>Valor Total</SmallText>
                                        <NormalText color={colors.mediumGray} bold>R$ {reservation.servicos.reduce((acc, service) => acc + service.preco, 0)}</NormalText>
                                    </LabelAndValueContainer>
                                </TotalContainer>
                            </GroupContainer>

                            <GroupContainer>
                                <NormalText align="left" bold>Data e hora</NormalText>
                                <TotalContainer
                                    flexDirection="row"
                                >
                                    <LabelAndValueContainer>
                                        <SmallText color={colors.mediumGray} >Data</SmallText>
                                        <NormalText color={colors.mediumGray} bold >
                                            {getDateFromDateTime(reservation.agendamento.data_agendamento)}
                                        </NormalText>
                                    </LabelAndValueContainer>
                                    <LabelAndValueContainer
                                       align="end"
                                    >
                                        <SmallText color={colors.mediumGray}>Hora</SmallText>
                                        <NormalText color={colors.mediumGray} bold>
                                            {getHourFromDateTime(reservation.agendamento.data_agendamento)}
                                        </NormalText>
                                    </LabelAndValueContainer>
                                </TotalContainer>
                            </GroupContainer>
                            <GroupContainer>
                                <NormalText align="left" bold>Informações do cliente</NormalText>
                                <TotalContainer
                                    flexDirection="row"
                                >
                                    <LabelAndValueContainer>
                                        <SmallText color={colors.mediumGray}>Nome</SmallText>
                                        <NormalText color={colors.mediumGray} bold>{reservation.clienteNaoCadastrado.nome}</NormalText>
                                    </LabelAndValueContainer>
                                    <LabelAndValueContainer
                                        align="end"
                                    >
                                        <SmallText color={colors.mediumGray}>Telefone</SmallText>
                                        <NormalText color={colors.mediumGray} bold>{reservation.clienteNaoCadastrado.telefone}</NormalText>
                                    </LabelAndValueContainer>
                                </TotalContainer>
                            </GroupContainer>

                            {reservation.agendamento_recorrente?.id && (
                                <>
                                    <GroupContainer>
                                        <NormalText align="left" bold>Agendamento recorrente</NormalText>
                                        <TotalContainer
                                            flexDirection="row"
                                        >
                                            <LabelAndValueContainer>
                                                <SmallText color={colors.mediumGray}>ID recorrencia</SmallText>
                                                {/* <NormalText color={colors.mediumGray} bold>{reservation.agendamento_recorrente.id}</NormalText> */}
                                                <LinkText onClick={() => navigate(`/barbershop/${id_barbershop}/reservations/recurrent/done/${reservation.agendamento_recorrente?.id}`)}>
                                                    <NormalText color={colors.blue} bold>{reservation.agendamento_recorrente.id}</NormalText>
                                                </LinkText>
                                            </LabelAndValueContainer>
                                            <LabelAndValueContainer
                                                align="end"
                                            >
                                                <SmallText color={colors.mediumGray}>Tipo recorrencia</SmallText>
                                                <NormalText color={colors.mediumGray} bold>{reservation.tipo_recorrencia?.nome}</NormalText>
                                            </LabelAndValueContainer>
                                        </TotalContainer>
                                    </GroupContainer>
                                </>
                            )}

                            <Separator />

                            {user?.id ? (
                                <>
                                    <GroupContainer>
                                        <MediumText align="left" bold>Quer entrar em contato com o clliente?</MediumText>
                                        <NormalText align="left" color={colors.mediumGray}>Envie uma mensagem padrão diretamento para o cliente :)</NormalText>
                                        <WhatsAppButton
                                            text="Enviar mensagem"
                                            onClick={handleGoToWhatsAppClientChat}
                                        />
                                    </GroupContainer>

                                    <Separator />

                                    {reservation.agendamento.status_agendamento_id === 4 ? (
                                        <MediumText color={colors.red} bold>Reserva cancelada</MediumText>
                                    ) :
                                        <>
                                            {reservation.agendamento.status_agendamento_id === 3 ? (
                                                <DonedReservationContainer onClick={() => handleChangeStatusAgendamento(1)}>
                                                    <FaCalendarCheck size={15} color={colors.white} />
                                                    <NormalText color={colors.white} bold>Reserva concluída</NormalText>
                                                </DonedReservationContainer>
                                            ) :
                                                (<InProgressReservationContainer onClick={() => handleChangeStatusAgendamento(3)}>
                                                    <FaCalendarCheck size={15} color={colors.white} />
                                                    <NormalText color={colors.white} bold>Concluir reserva</NormalText>
                                                </InProgressReservationContainer>
                                                )
                                            }
                                            < ButtonWithModal
                                                label="Cancelar reserva"
                                                title="Deseja realmente cancelar a reserva?"
                                                cancelFunction={() => handleChangeStatusAgendamento(4)}
                                            />
                                        </>
                                    }
                                </>
                            ) : (
                                <>
                                    <GroupContainer>
                                        <MediumText align="left" bold>Dúvidas?</MediumText>
                                        <NormalText align="left" color={colors.mediumGray}>Se tem alguma dúvida você pode estar entrando em contato para obter maiores inforçãoes</NormalText>
                                        <WhatsAppButton
                                            text="Enviar mensagem"
                                            onClick={handleGoToWhatsAppBarberChat}
                                        />
                                    </GroupContainer>

                                    {reservation.agendamento.status_agendamento_id === 4 ? (
                                        <MediumText color={colors.red} bold>Reserva cancelada</MediumText>
                                    ) : (
                                        <GroupContainer>
                                            <MediumText align="left" bold>Precisa cancelar sua reserva?</MediumText>
                                            <NormalText align="left" color={colors.mediumGray}>Se houver a necessidade de cancelamento, você pode relizar utilizando o botão abaixo, caso esteja ainda em periodo hábil de cancelamento</NormalText>
                                            <ActionButton
                                                text="Cancelar reserva"
                                                onClick={() => handleChangeStatusAgendamento(4)}
                                                padding="20px"
                                            />
                                        </GroupContainer>
                                    )
                                }

                                    
                                </>
                            )
                            }
                        </>
                    )
                    }
                </CenteredContentContainer>
            </DefaultContainer>
        </>
    );
}
