import React from "react";
import styled from "styled-components";
import colors from "../../../style/colors";

type ContainerStyleProps = {
    backgroundColor?: string
    borderColor?: string
    justify?: string
    align?: string
    flexDirection?: string
    fullWidth?: boolean
}

const ContainerStyle = styled.div<ContainerStyleProps>`
    width: 100%;
    padding: 20px 20%;
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    justify-content: ${props => props.justify ? props.justify : 'center'};
    align-items: ${props => props.align ? props.align : 'center'};
    background-color: ${props => props.backgroundColor ? props.backgroundColor : colors.white};
    border: 1px solid ${props => props.borderColor ? props.borderColor : 'none'};
    height: 100%;
    gap: 20px;

    @media (max-width: 768px) {
        padding: ${props => props.fullWidth ? '3px' : '7px'};
        flex-direction: column;
        gap: 10px;        
    }
`;

type ContainerProps = {
    children: React.ReactNode
    backgroundColor?: string
    borderColor?: string
    justify?: string
    align?: string
    flexDirection?: string
    fullWidth?: boolean
}

export default function DefaultContainer({ children, backgroundColor, borderColor, justify, align, flexDirection, fullWidth }: ContainerProps) {
    return (
        <ContainerStyle
            backgroundColor={backgroundColor}
            borderColor={borderColor}
            justify={justify}
            align={align}
            flexDirection={flexDirection}
            fullWidth={fullWidth}
        >
            {children}
        </ContainerStyle>
    )
}
