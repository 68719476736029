import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen";
import GoBackTitle from "../../../components/GoBackTitle";
import DefaultContainer from "../../../components/Containers/DefaultContainer";
import colors from "../../../style/colors";
import CenteredContentContainer from "../../../components/Containers/CenteredContentContainer";
import LinkText from "../../../components/Link";
import MediumText from "../../../components/texts/MediumText";
import NormalText from "../../../components/texts/NormalText";
import ImageTab from "./components/ImageTab";
import InformationTab from "./components/InformationTab";
import HoursTab from "./components/HoursTab";
import ServiceTab from "./components/ServiceTab";
import Barbers from "./components/Barbers";
import { useNavigate, useParams } from "react-router-dom";
import { BarbeariaLocationType, BarbeariaType, BarberShopPhotosType, BarberShopPhotoType, OpeningHoursType } from "../../../types/barbershop";
import { api } from "../../../config/api";
import { AuthContext } from "../../../context/authContext";
import { ServicesType } from "../../../types/services";
import { BarberDetailType, BarbersType } from "../../../types/barber";
import { UsuarioFotosType } from "../../../types/user";
import LocationTab from "./components/LocationTab";
import { citiesType } from "../../../types/city";
import { LoadingHook } from "../../../hooks/LoadingHook";
import { ConfirmationButton, Separator } from "../../globalStyles";
import { toast } from "react-toastify";

const ItemsContainer = styled.div`
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;
`

export default function BarberShopDetail() {
    const { id_barbershop } = useParams();

    const [barberShopImages, setBarberShopImages] = useState<BarberShopPhotosType>([]);
    const [barberShopInformation, setBarberShopInformation] = useState<BarbeariaType>({
        nome: '',
        descricao: '',
        telefone: '',
        cnpj: ''
    });
    const [barberShopLocation, setBarberShopLocation] = useState<BarbeariaLocationType>({
        barbearia_id: 0,
        cidade_id: 0,
        numero: '',
        cep: '',
        lat_long: null,
        endereco: '',
        bairro: '',
        complemento: null
    });
    const [cities, setCities] = useState<citiesType>([]);

    const [openingHours, setOpeningHours] = useState<OpeningHoursType>([
        {
            dia_semana: 1,
            hora_abertura: '08:00',
            hora_fechamento: '18:00',
            barbearia_usuario_id: 0,
        },
        {
            dia_semana: 2,
            hora_abertura: '08:00',
            hora_fechamento: '18:00',
            barbearia_usuario_id: 0,
        },
        {
            dia_semana: 3,
            hora_abertura: '08:00',
            hora_fechamento: '18:00',
            barbearia_usuario_id: 0,
        },
        {
            dia_semana: 4,
            hora_abertura: '08:00',
            hora_fechamento: '18:00',
            barbearia_usuario_id: 0,
        },
        {
            dia_semana: 5,
            hora_abertura: '08:00',
            hora_fechamento: '18:00',
            barbearia_usuario_id: 0,
        },
        {
            dia_semana: 6,
            hora_abertura: '08:00',
            hora_fechamento: '18:00',
            barbearia_usuario_id: 0,
        },
        {
            dia_semana: 7,
            hora_abertura: '08:00',
            hora_fechamento: '18:00',
            barbearia_usuario_id: 0
        }
    ]);
    const [services, setServices] = useState<ServicesType>([]);
    const [barbers, setBarbers] = useState<BarbersType>([]);
    const [barbersPhotos, setBarbersPhotos] = useState<UsuarioFotosType>([]);
    const [currentBarber, setCurrentBarber] = useState<BarberDetailType>();

    const [newImages, setNewImages] = useState<File[]>([]);
    const [imagesToDelete, setImagesToDelete] = useState<BarberShopPhotoType[]>([]);

    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook();

    const { user } = useContext(AuthContext)

    const [tabs, setTabs] = useState([] as { name: string, isActive: boolean }[]);

    const [activeTab, setActiveTab] = useState('Informações')

    const handleTabClick = (tabName: string) => {
        setActiveTab(tabName);
        setTabs(tabs.map(tab => ({
            ...tab,
            isActive: tab.name === tabName
        })));
    };

    const navigate = useNavigate();

    const renderContent = () => {
        switch (activeTab) {
            case 'Informações':
                return <InformationTab
                    barberShopInformation={barberShopInformation}
                    setBarberShopInformation={setBarberShopInformation}
                />;
            case 'Endereço':
                return <LocationTab
                    barberShopLocation={barberShopLocation}
                    setBarberShopLocation={setBarberShopLocation}
                    cities={cities}
                />;
            case 'Fotos':
                return <ImageTab
                    barberShopImages={barberShopImages}
                    setBarberShopImages={setBarberShopImages}
                    newImages={newImages}
                    setNewImages={setNewImages}
                    imagesToDelete={imagesToDelete}
                    setImagesToDelete={setImagesToDelete}

                />
            case 'Seus horários':
                return <HoursTab currentBarber={currentBarber} openingHours={openingHours} setOpeningHours={setOpeningHours} />;
            case 'Serviços':
                return <ServiceTab services={services} setServices={setServices} />;
            case 'Babeiros':
                return <Barbers barbers={barbers} setBarbers={setBarbers} barbersPhotos={barbersPhotos} />;
            case 'Licença':
                return <NormalText>Em breve</NormalText>;
            default:
                return null;
        }
    };

    useEffect(() => {
        const fetchCurrentBarber = async () => {
            try {
                const response = await api.get(`/barbeiros/${user?.id}/barbearia/${id_barbershop}`);
                if (response.data) setCurrentBarber(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchBarberShopInformation = async () => {
            try {
                const response = await api.get(`/barbearias/${id_barbershop}`);
                if (response.data) setBarberShopInformation(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchBarberShopImage = async () => {
            try {
                const response = await api.get(`/barbearias_foto/barbearia/${id_barbershop}`);
                if (response.data) setBarberShopImages(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchServices = async () => {
            try {
                const response = await api.get(`/servicos/barbearia/${id_barbershop}`);
                if (response.data) setServices(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${id_barbershop}`);
                if (response.data) setBarbers(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchBarbersPhotos = async () => {
            try {
                const response = await api.get(`/usuarios_foto/barbearia/${id_barbershop}`);
                if (response.data) setBarbersPhotos(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchBarberShopLocation = async () => {
            try {
                const response = await api.get(`/barbearias_localizacao/barbearia/${id_barbershop}`);
                if (response.data) setBarberShopLocation(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        if (!user || !id_barbershop) return
        setIsLoading(true);
        fetchCurrentBarber();
        fetchBarberShopInformation();
        fetchBarberShopImage();
        fetchServices();
        fetchBarbers();
        fetchBarbersPhotos();
        hideLoadingWithDelay();
        fetchBarberShopLocation();

    }, [id_barbershop, user])

    useEffect(() => {
        const fetchOpeningHours = async () => {
            try {
                const response = await api.get(`/horarios_atendimento/barbeiro/${currentBarber?.usuario_barbearia.id}`);
                if (response.data) setOpeningHours(response.data);
                } catch (error) {
                console.error(error);
            }
        }

        if (!currentBarber) return
        setIsLoading(true);
        fetchOpeningHours();
        hideLoadingWithDelay();
    }, [currentBarber])

    useEffect(() => {
        if (id_barbershop) {
            setTabs([
                { name: 'Informações', isActive: true },
                { name: 'Endereço', isActive: false },
                { name: 'Fotos', isActive: false },
                { name: 'Seus horários', isActive: false },
                { name: 'Serviços', isActive: false },
                { name: 'Babeiros', isActive: false },
                { name: 'Licença', isActive: false },
            ])
        } else {
            setTabs([
                { name: 'Informações', isActive: true },
                { name: 'Endereço', isActive: false },
                { name: 'Fotos', isActive: false },
                { name: 'Seus horários', isActive: false },
            ])
        }
    }, [id_barbershop])

    useEffect(() => {
        const fetchCities = async () => {
            try {
                const response = await api.get('/cidades/');
                setCities(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        setIsLoading(true);
        fetchCities();
        hideLoadingWithDelay();
    }, [])


    const handleSaveClick = async () => {
        setIsLoading(true);

        if (!barberShopInformation.nome || !barberShopInformation.descricao || !barberShopInformation.telefone) {
            toast.error("Preencha todos os campos de obrigatórios de informações");
            hideLoadingWithDelay();
            return;
        }

        if (!barberShopLocation.cidade_id || !barberShopLocation.endereco || !barberShopLocation.numero || !barberShopLocation.cep) {
            toast.error("Preencha todos os campos obrigatórios de endereço");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopImages?.length === 0 && newImages?.length === 0) {
            toast.error("Adicione pelo menos uma foto da barbearia");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopInformation.nome.length > 50) {
            toast.error("O nome da barbearia deve ter no máximo 50 caracteres");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopInformation.descricao.length > 150) {
            toast.error("A descrição da barbearia deve ter no máximo 150 caracteres");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopInformation.telefone.length > 15) {
            toast.error("O telefone da barbearia deve ter no máximo 15 caracteres");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopLocation.endereco.length > 100) {
            toast.error("O endereço da barbearia deve ter no máximo 100 caracteres");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopLocation.numero.length > 10) {
            toast.error("O número do endereço da barbearia deve ter no máximo 10 caracteres");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopLocation.cep.length > 10) {
            toast.error("O CEP da barbearia deve ter no máximo 10 caracteres");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopLocation.bairro.length > 50) {
            toast.error("O bairro da barbearia deve ter no máximo 50 caracteres");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopLocation.complemento && barberShopLocation.complemento.length > 50) {
            toast.error("O complemento do endereço da barbearia deve ter no máximo 50 caracteres");
            hideLoadingWithDelay();
            return;
        }

        if (barberShopInformation.cnpj) {
            if (barberShopInformation.cnpj.length < 14) {
                toast.error("O CNPJ da barbearia deve ter no mínimo 14 caracteres");
                hideLoadingWithDelay();
                return;
            }
        }

        if (id_barbershop) {
            // update
            try {
                await api.put(`/barbearias/`, barberShopInformation);
            } catch (error) {
                console.error(error);
            }

            if (newImages.length > 0) {
                const formData = new FormData();

                newImages.forEach(file => {
                    formData.append("files", file);
                });

                try {
                    const response = await api.post(`/upload_files/`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    const uploadedImages: BarberShopPhotoType[] = response.data.map((file: any) => ({
                        barbearia_id: parseInt(id_barbershop),
                        foto: file.full_path,
                        data_criacao: new Date().toISOString(),
                        id: file.id,
                    }));

                    const responseImages = await api.post(`/barbearias_foto/many`, uploadedImages);
                } catch (error) {
                    console.error("Error uploading image:", error);
                }
            }

            if (imagesToDelete.length > 0) {
                for (const image of imagesToDelete) {
                    try {
                        await api.delete(`/barbearias_foto/${image.id}`);
                    } catch (error) {
                        console.error("Error deleting image:", error);
                    }
                }
            }

            if (openingHours.length > 0) {
                openingHours.forEach(async (openingHour) => {
                    try {
                        if (openingHour?.id) {
                            await api.put('/horarios_atendimento/', openingHour);
                        } else {
                            await api.post('/horarios_atendimento/', openingHour);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                });
            }

            if (barberShopLocation) {
                try {
                    await api.put(`/barbearias_localizacao/`, barberShopLocation);
                } catch (error) {
                    console.error(error);
                }
            }

            hideLoadingWithDelay();
            navigate(`/barbershops/barbershop/${barberShopInformation.id}`);
            toast.success("Barbearia atualizada com sucesso");
        } else {
            // post
            try {
                const response = await api.post(`/barbearias/`, barberShopInformation);

                const response_barber = await api.post(`/barbeiros/convite`, {
                    barbearia_id: response.data.id,
                    email: user?.email,
                });

                const responseLocation = await api.post(`/barbearias_localizacao/`, {
                    ...barberShopLocation,
                    barbearia_id: response.data.id
                });

                Promise.all(openingHours.map(async hour => {
                    const responseHour = await api.post(`/horarios_atendimento/`, {
                        ...hour,
                        barbearia_usuario_id: response_barber.data.id
                    });
                }));

                if (newImages.length > 0) {
                    const formData = new FormData();

                    newImages.forEach(file => {
                        formData.append("files", file);
                    });

                    const responseImages = await api.post(`/upload_files`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    const uploadedImages: BarberShopPhotoType[] = responseImages.data.map((file: any) => ({
                        barbearia_id: response.data.id,
                        foto: file.full_path,
                    }));

                    const responseUploadedImages = await api.post(`/barbearias_foto/many`, uploadedImages);
                }

                hideLoadingWithDelay();
                toast.success("Barbearia criada com sucesso");
                navigate(`/barbershops/barbershop/${response.data.id}`);
            } catch (error) {
                console.error(error);
            }
        }

        hideLoadingWithDelay();
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text={`Dados da barbearia ${barberShopInformation.nome ? ' - ' + barberShopInformation.nome : ''}`} />

            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="flex-start"
                align="center"
            >
                <CenteredContentContainer
                    width="720px"
                >
                    <ItemsContainer>
                        {tabs.map(tab => (
                            <>
                                <LinkText
                                    key={tab.name}
                                    disabled={false}
                                    onClick={() => handleTabClick(tab.name)}
                                >
                                    <NormalText bold={tab.isActive} color={tab.isActive ? colors.darkGray : colors.mediumGray}>
                                        {tab.name}
                                    </NormalText>
                                </LinkText>
                            </>
                        ))}
                    </ItemsContainer>
                    <Separator width="150px" />
                    <ContentContainer>
                        {renderContent()}
                    </ContentContainer>
                    <ConfirmationButton
                        onClick={handleSaveClick}
                    >
                        <MediumText color="white" bold>Salvar</MediumText>
                    </ConfirmationButton>
                </CenteredContentContainer>
            </DefaultContainer>
        </>
    )
}