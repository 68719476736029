import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import NormalText from "../texts/NormalText";

type ContainerProps = {
    width?: string;
    margin?: string;
    padding?: string;
    fontSize?: string;
}

const Container = styled.div<ContainerProps>`
    display: flex;
    width: ${(props) => props.width || '100%'};
    margin: ${(props) => props.margin || '0'};
    justify-content: center;
    align-items: center;
    background-color: ${colors.primary};
    color: white;    
    transition: background-color 0.3s;
    padding: ${(props) => props.padding || '10px'};
    gap: 10px;
    white-space: nowrap;

    &:hover {
        cursor: pointer;
        background-color: ${colors.primaryHover};
    }

    &:active {
        background-color: ${colors.primary};
        outline: 2px solid ${colors.gray};
    }
`;

type ActionButtonProps = {
    text: string;
    onClick?: () => void;
    width?: string;
    margin?: string;
    padding?: string;
}

export default function ActionButton({ text, width, onClick = () => {}, margin, padding }: ActionButtonProps) {
    return (
        <Container 
            onClick={onClick} 
            width={width} 
            margin={margin}
            padding={padding}
        >
            <NormalText color={colors.white}>{text}</NormalText>
        </Container>
    )
}