import React from 'react';
import './style/global.css'
import GeneralRoutes from './routes/generalRouter';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = {
  marginTop: '60px',
}

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={4000}
        hideProgressBar
        newestOnTop={false}
        closeButton={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover 
        style={styles}
      />
        <GeneralRoutes />
    </>
  );
}

export default App;
