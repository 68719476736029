import React, { useEffect, useRef } from "react";
import phoneImage from "../../assets/images/phone.png";
import phone2Image from "../../assets/images/phone2.png";
import phone3Image from "../../assets/images/phone3.png";
import barberImageContact from "../../assets/images/barberContact.png";
import colors from "../../style/colors";
import BigText from "../../components/texts/BigText";
import NormalText from "../../components/texts/NormalText";
import ActionButton from "../../components/ActionButton";
import MediumText from "../../components/texts/MediumText";
import LinkText from "../../components/Link";
import animationData from "../../assets/animations/showreel.json";
import Lottie from "react-lottie";
import { AnimationContainer, Container, ContentContainer, GridContainer, GridHeader, GridRow, GroupTextContainer, ImageContainer, PlanCard, PlanDiscountContainer, PlansContainer, PlanValueContainer, TextWithLetterSpacing } from "./style";
import SmallText from "../../components/texts/SmallText";
import { ScreenSizeHook } from "../../hooks/ScreenSizeHook";

export default function Home() {
    const imageRefs = useRef<(HTMLDivElement | null)[]>([]);
    const { isMobileView, isDesktopView } = ScreenSizeHook();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const target = entry.target as HTMLElement;
                    if (entry.isIntersecting) {
                        target.classList.add(target.dataset.animation || "slide-in-left");
                        observer.unobserve(target);
                    }
                });
            },

            { threshold: 0.1 }
        );

        imageRefs.current.forEach((ref) => {
            if (ref) {
                observer.observe(ref);
            }
        });

        return () => {
            imageRefs.current.forEach((ref) => {
                if (ref) {
                    observer.unobserve(ref);
                }
            });
        };
    }, []);
   
    return (
        <>            
            <Container backgroundColor={colors.black}>
                <GroupTextContainer>
                    <BigText color={colors.white}><b>Simplifique</b> seus agendamentos</BigText>
                    <BigText color={colors.white}><b>Conecte-se</b> com seus clientes</BigText>
                </GroupTextContainer>
                <AnimationContainer>
                    <Lottie options={defaultOptions} />
                </AnimationContainer>
            </Container>
            <TextWithLetterSpacing>AGENDE - CONECTE - CRESÇA</TextWithLetterSpacing>
            <Container backgroundColor={colors.white}>
                <GroupTextContainer>
                    <BigText color={colors.darkGray} bold>Agendamento fácil</BigText>
                    <BigText color={colors.darkGray}>Dê aos seus clientes a liberdade de agendar seus serviços online</BigText>
                    <NormalText color={colors.darkGray}>Sem precisar de cadastro ou APP ;)</NormalText>
                </GroupTextContainer>
                <ImageContainer
                    backgroundImage={phoneImage}
                    ref={(el) => (imageRefs.current[0] = el)}
                    data-animation="slide-in-left"
                />
            </Container>
            {isDesktopView && (
                <Container backgroundColor={colors.white}>
                    <ImageContainer
                        backgroundImage={phone2Image}
                        ref={(el) => (imageRefs.current[1] = el)}
                        data-animation="slide-in-right"
                    />
                    <GroupTextContainer>
                        <BigText color={colors.darkGray} bold>Contato Direto</BigText>
                        <BigText color={colors.darkGray}>Mantenha um canal de comunicação aberto e eficiente com seus clientes.</BigText>
                        <NormalText color={colors.darkGray}>Mensagens pré definidas que facilitam o contato com o cliente</NormalText>
                    </GroupTextContainer>
                </Container>
            )}
            {isMobileView && (
                <Container backgroundColor={colors.white}>
                    <GroupTextContainer>
                        <BigText color={colors.darkGray} bold>Contato Direto</BigText>
                        <BigText color={colors.darkGray}>Mantenha um canal de comunicação aberto e eficiente com seus clientes.</BigText>
                        <NormalText color={colors.darkGray}>Mensagens pré definidas que facilitam o contato com o cliente</NormalText>
                    </GroupTextContainer>
                    <ImageContainer
                        backgroundImage={phone2Image}
                        ref={(el) => (imageRefs.current[1] = el)}
                        data-animation="slide-in-right"
                    />
                </Container>
            )}
            <Container backgroundColor={colors.white}>
                <GroupTextContainer>
                    <BigText color={colors.darkGray} bold>Gerenciamento Simples</BigText>
                    <BigText color={colors.darkGray}>Controle seu calendário e o da sua equipe forma descomplicada.</BigText>
                    <ActionButton
                        onClick={() => { }}
                        text="Quero ver os planos!"
                    />
                </GroupTextContainer>
                <ImageContainer
                    backgroundImage={phone3Image}
                    ref={(el) => (imageRefs.current[2] = el)}
                    data-animation="slide-in-left"
                />
            </Container>
            <Container backgroundColor={colors.lightGray} borderColor={colors.gray}>
                <ContentContainer backgroundColor={colors.white} borderColor={colors.gray}>
                    {isDesktopView && (
                        <>
                            <BigText color={colors.darkGray} bold>Funcionalidades e Benefícios</BigText>
                            <GridContainer>
                                <GridHeader>
                                    <MediumText color={colors.white} bold>Funcionalidades</MediumText>
                                    <MediumText color={colors.white} bold>Beneficios</MediumText>
                                </GridHeader>
                                <GridRow>
                                    <MediumText color={colors.darkGray}>Agendamento Fácil</MediumText>
                                    <MediumText color={colors.darkGray}>Liberdade para seus clientes agendarem online a qualquer hora.</MediumText>
                                </GridRow>
                                <GridRow>
                                    <MediumText color={colors.darkGray}>Contato Direto</MediumText>
                                    <MediumText color={colors.darkGray}>Comunicação rápida e eficiente com seus clientes.</MediumText>
                                </GridRow>
                            </GridContainer>
                        </>
                    )}
                    {isMobileView && (
                        <>
                            <MediumText color={colors.darkGray} bold>Funcionalidades e Benefícios</MediumText>
                            <GridContainer>
                                <GridHeader>
                                    <NormalText color={colors.white} bold>Funcionalidades</NormalText>
                                    <NormalText color={colors.white} bold>Beneficios</NormalText>
                                </GridHeader>
                                <GridRow>
                                    <NormalText color={colors.darkGray}>Agendamento Fácil</NormalText>
                                    <SmallText color={colors.darkGray}>Liberdade para seus clientes agendarem online a qualquer hora.</SmallText>
                                </GridRow>
                                <GridRow>
                                    <NormalText color={colors.darkGray}>Contato Direto</NormalText>
                                    <NormalText color={colors.darkGray}>Comunicação rápida e eficiente com seus clientes.</NormalText>
                                </GridRow>
                            </GridContainer>
                        </>
                    )}


                    <BigText color={colors.darkGray} bold>Planos</BigText>
                    <MediumText color={colors.mediumGray} align="center">
                        Trabalhamos com a venda de licença por barbearia, logo uma licença de barbearia pode ter até 15 barbeiros cadastrados
                    </MediumText>
                    <PlansContainer>
                        <PlanCard>
                            <MediumText color={colors.darkGray}>Plano Mensal</MediumText>
                            <PlanValueContainer>
                                <BigText color={colors.primary} bold>R$ Em breve</BigText>
                            </PlanValueContainer>
                            <LinkText>Assinar</LinkText>
                        </PlanCard>
                        <PlanCard>
                            <MediumText color={colors.darkGray}>Plano Anual</MediumText>
                            <PlanValueContainer>
                                <BigText color={colors.primary} bold>R$ Em breve</BigText>
                                <PlanDiscountContainer>
                                    <NormalText color={colors.green}>De 00,00 por R$ 00,00</NormalText>
                                    <NormalText color={colors.red}>(Desconto R$ 00,00)</NormalText>
                                </PlanDiscountContainer>
                            </PlanValueContainer>
                            <LinkText>Assinar</LinkText>
                        </PlanCard>
                    </PlansContainer>
                </ContentContainer>
            </Container>
            <Container backgroundColor={colors.white}>
                <GroupTextContainer>
                    <BigText color={colors.darkGray} bold>Tem alguma dúvida</BigText>
                    <MediumText color={colors.mediumGray}>Entre em contato conosco para tirarmos suas dúvidas :)</MediumText>
                    <ActionButton
                        onClick={() => { }}
                        text="Entrar em contato"
                    />
                </GroupTextContainer>
                <ImageContainer
                    backgroundImage={barberImageContact}
                    ref={(el) => (imageRefs.current[3] = el)}
                    data-animation="slide-in-right"
                />
            </Container>
        </>
    );
}