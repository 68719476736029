import React from "react";
import styled from "styled-components";
import colors from "../../../style/colors";

type Props = {
    children: React.ReactNode;
    bold?: boolean;
    color?: string;
    align?: string;
}

type PropsStyled = {
    bold?: boolean;
    color?: string;
    align?: string;
}
const Text = styled.span<PropsStyled>`
    font-size: 16px;
    font-weight: ${props => props.bold ? "bold" : "normal"};
    color: ${props => props.color? props.color : colors.darkGray};
    text-align: ${props => props.align ? props.align : "left"};
`

export default function MediumText({children, bold, color=colors.darkGray, align}: Props) {
    return (
        <Text bold={bold} color={color} align={align}>
            {children}
        </Text>
    );
}