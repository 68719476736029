import React from "react";
import styled from "styled-components";
import colors from "../../../style/colors";

type Props = {
    children: React.ReactNode;
    bold?: boolean;
    color?: string;
}

type PropsStyled = {
    bold?: boolean;
    color?: string;
}

const Text = styled.span<PropsStyled>`
    display: inline-block;
    font-size: 24px;
    font-weight: ${props => props.bold ? "bold" : "normal"};
    color: ${props => props.color? props.color : colors.darkGray};
`

export default function BigText({children, bold, color=colors.darkGray}: Props) {
    return (
        <Text bold={bold} color={color}>
            {children}
        </Text>
    );
}