import React, { useEffect } from 'react'
import styled from 'styled-components'
import LoadingFreezeScreen from '../../../../components/LoadingFreezeScreen'
import MediumText from '../../../../components/texts/MediumText'
import { LoadingHook } from '../../../../hooks/LoadingHook'
import { AusencesType } from '../../../../types/ausences'
import colors from '../../../../style/colors'
import { formatDateToDDMMYYYYFromString } from '../../../../functions/date'
import { formatHourToHHMM } from '../../../../functions/hour'
import { useNavigate, useParams } from 'react-router-dom'
import { api } from '../../../../config/api'
import { toast } from 'react-toastify'
import { AuthContext } from '../../../../context/authContext'
import GoBackTitle from '../../../../components/GoBackTitle'
import DefaultContainer from '../../../../components/Containers/DefaultContainer'
import CenteredContentContainer from '../../../../components/Containers/CenteredContentContainer'
import Table from '../../../../components/Table'
import SearchInput from '../../../../components/SearchInput'
import ActionButton from '../../../../components/ActionButton'
import { BarbeariaType } from '../../../../types/barbershop'

const SearchAndButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
`

export default function ListAusences() {
    const { id_barbershop } = useParams()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [ausences, setAusences] = React.useState<AusencesType>([])
    const [ausencesDisplay, setAusencesDisplay] = React.useState<AusencesType>([])
    const [barberShop, setBarberShop] = React.useState<BarbeariaType>()
    const { user } = React.useContext(AuthContext)
    const navigate = useNavigate()

    useEffect(() => {
        const fetchAusences = async () => {
            const response = await api.get(`/ausencias/barbearia_usuario/${id_barbershop}/${user?.id}`)
            if (response) {
                setAusences(response.data)
            }
        }

        const getBarberShopData = async () => {
            const response = await api.get(`/barbearias/${id_barbershop}`)
            if (response) {
                setBarberShop(response.data)
            }
        }

        try {
            setIsLoading(true)
            if (id_barbershop && user?.id) {
                fetchAusences()
                getBarberShopData()
            }
        } catch {
            toast.error('Falha ao obter ausencias')
        } finally {
            hideLoadingWithDelay()
        }
    }, [id_barbershop, user])

    const formatAusenceValues = (ausences: AusencesType) => {
        return ausences.map(ausence => {
            return {
                ...ausence,
                data_inicio: formatDateToDDMMYYYYFromString(ausence.data_inicio),
                data_fim: ausence.recorrente ? "Recorrente" : formatDateToDDMMYYYYFromString(ausence.data_fim ?? ''),
                hora_inicio: formatHourToHHMM(ausence.hora_inicio),
                hora_fim: formatHourToHHMM(ausence.hora_fim)
            }
        })
    }

    useEffect(() => {
        const ausencesDisplay = formatAusenceValues(ausences)
        setAusencesDisplay(ausencesDisplay)
    }, [ausences])

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text={`Lista de ausencias ${barberShop?.nome ? ' - ' + barberShop?.nome : ''}`} />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="center"
                align="flex-start"
                flexDirection="row"
            >
                <CenteredContentContainer
                    width="100%"                    
                >
                    <MediumText bold>Ausencias</MediumText>

                    <SearchAndButtonContainer>
                        <SearchInput
                            onSearch={(search) => {
                                const ausencesFiltred = ausences.filter(ausence => {
                                    return ausence.descricao.includes(search)
                                })
                                setAusencesDisplay(formatAusenceValues(ausencesFiltred))
                            }
                            }
                            placeholder='Pesquisar ausencia'
                        />

                        <ActionButton
                            onClick={() => navigate('ausence')}
                            text='Adicionar nova ausencia'
                            width='fit-content'
                        />

                    </SearchAndButtonContainer>

                    <Table
                        data={ausencesDisplay}
                        columns={[
                            'id',
                            'descricao',
                            'data_inicio',
                            'data_fim',
                            'hora_inicio',
                            'hora_fim',
                            'recorrente',
                        ]}
                        columnLabels={[
                            'ID',
                            'Descricao',
                            'Data de inicio',
                            'Data de fim',
                            'Hora inicio',
                            'Hora fim',
                            'Recorrente',
                        ]}
                        onItemClick={(item) => navigate(`ausence/${item.id}`)}
                    />

                </CenteredContentContainer>
            </DefaultContainer >
        </>
    )
}
