import React, { useContext } from "react";
import logo from "../../assets/logo/logo-full.png";
import { Button, Container, GroupContainer, Logo, MenuItem } from "./style";
import { ScreenSizeHook } from "../../hooks/ScreenSizeHook";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

export default function Topbar() {
    const { isDesktopView, isMobileView } = ScreenSizeHook();
    const navigate = useNavigate()

    const { user } = useContext(AuthContext)

    return (
        <Container>
            {isDesktopView && (
                <>
                    {user?.id ? (
                        <>
                            <GroupContainer>
                                <Logo src={logo} alt="logo" onClick={() => navigate('/')} />
                                <MenuItem href="/schedule">Agenda</MenuItem>
                                <MenuItem href="/barbershops">Barbearias</MenuItem>
                            </GroupContainer>
                            <GroupContainer>
                                <Button onClick={() => navigate('/profile')}>Perfil</Button>
                            </GroupContainer>
                        </>
                    ) : (
                        <>
                            <GroupContainer>
                                <Logo src={logo} alt="logo" onClick={() => navigate('/')} />
                                {/* <MenuItem href="#">Inicio</MenuItem> */}
                                {/* <MenuItem href="#">Barbearia</MenuItem> */}
                            </GroupContainer>
                            <GroupContainer>
                                <MenuItem href="/register">Cadastrar-se</MenuItem>
                                <Button onClick={() => navigate('/login')}>Entrar</Button>
                            </GroupContainer>
                        </>
                    )}
                </>
            )}
            {isMobileView && (
                <>
                    {user?.id ? (
                        <>
                            <GroupContainer>
                                <Logo src={logo} alt="logo" onClick={() => navigate('/')} />
                            </GroupContainer>
                        </>
                    )
                        : (
                            <>
                                <GroupContainer>
                                    <Logo src={logo} alt="logo" onClick={() => navigate('/')} />
                                </GroupContainer>
                                <GroupContainer>
                                    <MenuItem href="/register">Cadastrar-se</MenuItem>
                                    <Button onClick={() => navigate('/login')}>Entrar</Button>
                                </GroupContainer>
                            </>
                        )}
                </>
            )
            }
        </Container >
    )
}