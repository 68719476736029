import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import NormalText from "../texts/NormalText";
import { FaTrash } from "react-icons/fa";
import MediumText from "../texts/MediumText";

const BackgroundModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`

const Modal = styled.div`
    background-color: ${colors.white};
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Button = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.gray};
    border-radius: 5px;    
    border: 1px solid ${colors.lightGray};
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${colors.lightGray};
        border: 1px solid ${colors.gray};
    }

    &:active {
        background-color: ${colors.gray};
        outline: 2px solid ${colors.gray};
    }
`

const ModalButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.lightGray};
    border-radius: 5px;    
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;

    &:hover {
        cursor: pointer;
        background-color: ${colors.gray};
    }

    &:active {
        background-color: ${colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`

type ButtonModalProps = {
    title: string;
    cancelFunction: () => void;
    label: string;
}

export default function ButtonWithModal({ title, cancelFunction, label }: ButtonModalProps) {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleCancel = () => {
        cancelFunction();
        setIsModalOpen(false);
    }

    return (
        <>
            <Button onClick={() => setIsModalOpen(true)}>
                <FaTrash size={15} color={colors.mediumGray} />  
                <NormalText color={colors.mediumGray} bold>{label}</NormalText>
            </Button>
            {isModalOpen && (
                <BackgroundModal>
                    <Modal>
                        <MediumText bold>{title}</MediumText>
                        <ModalButton onClick={() => setIsModalOpen(false)}>
                            <NormalText color={colors.mediumGray} bold>Não</NormalText>
                        </ModalButton>
                        <ModalButton onClick={handleCancel}>
                            <NormalText color={colors.mediumGray} bold>Sim</NormalText>
                        </ModalButton>
                    </Modal>
                </BackgroundModal>
            )}
        </>
    )
}