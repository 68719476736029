import React, { useState } from 'react';
import styled from 'styled-components';
import 'dayjs/locale/pt-br';
import { ptBR as ptBR_locale } from "@mui/x-date-pickers/locales"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import colors from '../../style/colors';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/Sao_Paulo");

const StyledDateCalendar = styled(DateCalendar)`
    color: ${colors.mediumGray};

    .MuiPickersCalendarHeader-root{
        background-color: ${colors.lightGray};
        color: ${colors.mediumGray};
    }

    .MuiDayCalendar-root{
        color: ${colors.mediumGray};
        min-height: 300px;
    }

    .MuiPickersDay-root {
        padding: 10px;
        border: 1px solid ${colors.lightGray};    

        &:hover {
        background-color: ${colors.gray};
        }
    }

    .MuiPickersDay-root{
        width: 100%;
        height: 100%;
        aspect-ratio: 1;
    }

    .MuiTypography-root{
        width: 100%;
        height: 100%;
    }

    .MuiPickersDay-today {
        background-color: ${colors.darkGray};
        color: ${colors.white};
        border: none !important;

        &:hover{
        background-color: ${colors.mediumGray};
        }
    }

    .Mui-disabled{
        background-color: ${colors.lightGray};
    }

    .MuiPickersSlideTransition-root{
    }
`;

type CalendarProps = {
    selectedDate: Date,
    setSelectedDate: (date: Date) => void,
    disablePast?: boolean,
    fixedWeekNumber?: number
}

export default function Calendar({ selectedDate, setSelectedDate, disablePast = true, fixedWeekNumber = 5 }: CalendarProps
) {

    const handleDateChange = (date: any) => {
        setSelectedDate(dayjs(date).tz("America/Sao_Paulo").toDate());
    }

    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
            localeText={
                ptBR_locale.components.MuiLocalizationProvider.defaultProps.localeText
            }
            dateFormats={
                {
                    weekdayShort: 'ddd',
                    weekday: 'dddd',
                }
            }
        >
            <StyledDateCalendar
                showDaysOutsideCurrentMonth
                dayOfWeekFormatter={(day) => day.format('ddd')}
                disablePast={disablePast}
                fixedWeekNumber={fixedWeekNumber}
                defaultValue={dayjs().tz("America/Sao_Paulo")}
                value={dayjs(selectedDate).tz("America/Sao_Paulo")}
                onChange={handleDateChange}
            />
        </LocalizationProvider>
    );
}