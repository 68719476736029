import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import colors from "../../style/colors";
import { BarberShopPhotosType } from "../../types/barbershop";
import { api_url } from "../../config/api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselContainer = styled.div`
  width: 100%;
  max-width: 800px;
`;

const Image = styled.img`
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 3px;
    border: 1px solid ${colors.gray};
`;

type CarouselProps = {
  barberShopImages?: BarberShopPhotosType;
};

const ImageCarousel = ({ barberShopImages }: CarouselProps) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <CarouselContainer>
       {/* {barberShopImages?.map((image, index) => (
          <div key={index}>
            <Image src={`${api_url}${image.foto}`} alt={`Image ${index + 1}`} />
          </div>
        ))} */}

      <Slider {...settings}>
        {barberShopImages?.map((image, index) => (
          <div key={index}>
            <Image src={`${api_url}${image.foto}`} alt={`Image ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </CarouselContainer>
  );
};

export default ImageCarousel;