import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { BarbeariaLocationType, BarbeariaType, BarberShopPhotosType, OpeningHoursByBarbershop } from "../../../types/barbershop"
import { api, api_url } from "../../../config/api"
import { ConfirmationButton, FloattingContainer, Separator } from "../../globalStyles"
import colors from "../../../style/colors"
import NormalText from "../../../components/texts/NormalText"
import LoadingFreezeScreen from "../../../components/LoadingFreezeScreen"
import { LoadingHook } from "../../../hooks/LoadingHook"
import MediumText from "../../../components/texts/MediumText"
import { FaRegCalendarAlt } from "react-icons/fa"
import DefaultContainer from "../../../components/Containers/DefaultContainer"
import styled from "styled-components"
import LinkText from "../../../components/Link"
import GalleryBaberShopImage from "../../../components/GalleryBaberShopImage"
import exampleImage from '../../../assets/images/background.png'
import GoogleMapsComponent from "../../../components/GoogleMapsComponent"
import ActionButton from "../../../components/ActionButton"
import WhatsAppButton from "../../../components/WhatsAppButton"
import { ScreenSizeHook } from "../../../hooks/ScreenSizeHook"
import ImageCarousel from "../../../components/CarouselImages"
import { CityType } from "../../../types/city"
import { BarbersType } from "../../../types/barber"
import { formatHourToHHMM } from "../../../functions/hour"

const RowContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
`

const ContentContainer = styled.div`
   display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

const GroupContainer = styled.div`
    display: flex;
    gap: 20px;
`

const GroupContainerColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    align-items: flex-start;
`

const InformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 60%;
    background-color: ${colors.white};
    border: 1px solid ${colors.gray};
    border-radius: 3px;
    padding: 20px;
    align-items: flex-start;
    text-align: left;
    justify-content: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const ActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 40%;
    height: fit-content;
    padding: 20px;
    align-items: flex-start;

    background-color: ${colors.white};
    border: 1px solid ${colors.gray};
    border-radius: 3px;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const OpeningHoursContainer = styled.div`
    display: flex;
    gap: 10px;
`

const BabersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`

const BarberContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    `

const BaberInformationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    `

const BarberImage = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
    border: 1px solid ${colors.lightGray};
`

const MapsContainer = styled.div`
    display: flex;
    width: 100%;
    height: 300px;
    background-color: ${colors.lightGray};
    border-radius: 5px;
`

export default function BarberShopHome() {
    const { id_barbershop } = useParams()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const { isMobileView } = ScreenSizeHook()

    const [barberShopImages, setBarberShopImages] = useState<BarberShopPhotosType>();
    const [barberShop, setBarberShop] = useState<BarbeariaType>()
    const [barberShopLocation, setBarberShopLocation] = useState<BarbeariaLocationType>()
    const [barberShopCity, setBarberShopCity] = useState<CityType>()
    const [barbers, setBarbers] = useState<BarbersType>([])

    const [isFloattingVisible, setIsFloattingVisible] = useState(true);
    const actionContainerRef = useRef<HTMLDivElement>(null);
    const [openingHours, setOpeningHours] = useState<OpeningHoursByBarbershop[]>([])
    const [showAllHours, setShowAllHours] = useState(false);

    const navigate = useNavigate()

    const handleWhatsAppContact = () => {
        const message = `Olá, gostaria de saber mais informações sobre a barbearia ${barberShop?.nome}`
        const phoneNumber = barberShop?.telefone
        const url = `https://api.whatsapp.com/send?phone=55${phoneNumber}&text=${message}`
        window.open(url, '_blank')?.focus()
    }


    useEffect(() => {
        const getBarberShop = async () => {
            try {
                const response = await api.get(`/barbearias/${id_barbershop}`)
                setBarberShop(response.data)
            } catch (error) {
            }
        }

        const fetchBarberShopImage = async () => {
            try {
                const response = await api.get(`/barbearias_foto/barbearia/${id_barbershop}`);
                setBarberShopImages(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchBarberShopLocation = async () => {
            try {
                const response = await api.get(`/barbearias_localizacao/barbearia/${id_barbershop}`);
                setBarberShopLocation(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchBarbers = async () => {
            try {
                const response = await api.get(`/barbeiros/barbearia/${id_barbershop}`);
                setBarbers(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        const fetchOpeningHours = async () => {
            try {
                const response = await api.get(`/horarios_atendimento/barbearia/${id_barbershop}`);
                setOpeningHours(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        if (!id_barbershop) return
        setIsLoading(true)
        getBarberShop()
        fetchBarbers()
        fetchBarberShopImage()
        fetchBarberShopLocation()
        fetchOpeningHours()
        hideLoadingWithDelay()

    }, [id_barbershop])

    useEffect(() => {
        if (barberShopLocation) {
            const fetchBarberShopCity = async () => {
                try {
                    const response = await api.get(`/cidades/${barberShopLocation.cidade_id}`);
                    setBarberShopCity(response.data);
                } catch (error) {
                    console.error(error);
                }
            }

            fetchBarberShopCity()
        }
    }, [barberShopLocation])

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsFloattingVisible(!entry.isIntersecting);
            },
            { threshold: 0.1 }
        );

        const currentRef = actionContainerRef.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        // document.title = `No Corte - ${barberShop?.nome}`;

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };

    }, [barberShop]);

    const shareUrl = () => {
        const url = window.location.href
        if (navigator.share) {
            navigator.share({
                title: barberShop?.nome,
                url: url
            }).catch(console.error)
        } else if (navigator.clipboard) {
            navigator.clipboard.writeText(url).catch(console.error)
        } else {
            console.error('Clipboard API not supported');
        }
    }

    const formatOpeningHours = (openingHour: OpeningHoursByBarbershop) => {
        const daysOfWeek = ['', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo']
        const dayOfWeek = daysOfWeek[openingHour.dia_semana]
        if (!openingHour.horario_abertura || !openingHour.horario_fechamento) return `${dayOfWeek} - Fechado`
        if (openingHour.horario_abertura === '00:00:00' || openingHour.horario_fechamento === '00:00:00') return `${dayOfWeek} - Fechado`
        return `${dayOfWeek} - ${formatHourToHHMM(openingHour.horario_abertura)} às ${formatHourToHHMM(openingHour.horario_fechamento)}`
    }

    const toggleShowAllHours = () => {
        setShowAllHours(!showAllHours);
    };

    return (
        <>
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="flex-start"
                align="center"
            >
                <LoadingFreezeScreen isLoading={isLoading} />

                {barberShop && (
                    <>
                        <RowContainer>
                            <MediumText color={colors.darkGray} bold>{barberShop.nome}</MediumText>
                            <GroupContainer>
                                <LinkText onClick={shareUrl}>
                                    <NormalText>Compartilhar</NormalText>
                                </LinkText>
                            </GroupContainer>
                        </RowContainer>

                        {isMobileView ? (
                            <ImageCarousel barberShopImages={barberShopImages} />
                            // <></>
                        ) : (
                            <GalleryBaberShopImage barberShopImages={barberShopImages} />
                        )}

                        <ContentContainer>
                            <InformationContainer>
                                <GroupContainerColumn>
                                    <NormalText bold align="left">{barberShop.descricao}</NormalText>
                                    <NormalText color={colors.mediumGray}>Wifi - Banheiro - Agua - Café</NormalText>
                                </GroupContainerColumn>

                                <GroupContainerColumn>
                                    <MediumText bold>Horário de atendimento</MediumText>
                                    {openingHours.length > 0 ? (
                                        <>
                                            {showAllHours ? (
                                                <>
                                                    {openingHours.map((hour, index) => {
                                                        let weekDay = new Date().getDay();
                                                        weekDay = weekDay === 0 ? 7 : weekDay;
                                                        const isToday = weekDay === hour.dia_semana;

                                                        return (
                                                            <OpeningHoursContainer key={index}>
                                                                <FaRegCalendarAlt color={isToday ? colors.blue : colors.mediumGray} size={12} />
                                                                <NormalText color={isToday ? colors.blue : colors.mediumGray} bold={isToday}>{formatOpeningHours(hour)}</NormalText>
                                                            </OpeningHoursContainer>
                                                        )
                                                    })}
                                                    <LinkText onClick={toggleShowAllHours}>
                                                        <NormalText color={colors.blue}>Ver menos</NormalText>
                                                    </LinkText>
                                                </>
                                            ) : (
                                                <>
                                                    {openingHours.map((hour, index) => {
                                                        let weekDay = new Date().getDay();
                                                        weekDay = weekDay === 0 ? 7 : weekDay;
                                                        const isToday = weekDay === hour.dia_semana;
                                                        if (!isToday) return null;
                                                        return (
                                                            <OpeningHoursContainer key={index}>
                                                                <FaRegCalendarAlt color={isToday ? colors.blue : colors.mediumGray} size={12} />
                                                                <NormalText color={isToday ? colors.blue : colors.mediumGray} bold={isToday}>{formatOpeningHours(hour)}</NormalText>
                                                            </OpeningHoursContainer>
                                                        );
                                                    })}
                                                    <LinkText onClick={toggleShowAllHours}>
                                                        <NormalText color={colors.blue}>Ver todos</NormalText>
                                                    </LinkText>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <NormalText>Não tem horário de atendimento cadastrado</NormalText>
                                    )}
                                </GroupContainerColumn>

                                <GroupContainerColumn>
                                    <MediumText bold>Com quem você cortará o cabelo</MediumText>
                                    {barbers.length > 0 ? (
                                        <BabersContainer>
                                            {barbers.map((barber, index) => (
                                                <>
                                                    <BarberContainer key={index}>
                                                        <BarberImage src={barber.usuario_foto ? api_url + barber.usuario_foto.foto : exampleImage} alt="barber" />
                                                        <BaberInformationContainer>
                                                            <NormalText bold>{barber.nome}</NormalText>
                                                        </BaberInformationContainer>
                                                    </BarberContainer>
                                                </>
                                            ))}
                                        </BabersContainer>
                                    ) : (
                                        <NormalText>Não tem barbeiros cadastrados</NormalText>
                                    )}
                                </GroupContainerColumn>

                                <GroupContainerColumn>
                                    <MediumText bold>Localização</MediumText>
                                    <NormalText>{barberShopCity?.nome} - {barberShopLocation?.bairro} - {barberShopLocation?.endereco} - {barberShopLocation?.numero} {barberShopLocation?.complemento}</NormalText>
                                    {barberShopLocation?.lat_long ? (
                                        <MapsContainer>
                                            <GoogleMapsComponent lat_long={barberShopLocation?.lat_long} />
                                        </MapsContainer>
                                    ) : (
                                        <NormalText>Localização via GoogleMaps não cadastrada</NormalText>
                                    )}
                                </GroupContainerColumn>

                            </InformationContainer>
                            <ActionContainer>
                                <MediumText>Reserva</MediumText>
                                <NormalText color={colors.mediumGray}>Escolha uma  data/serviço de seu agrado</NormalText>
                                <ConfirmationButton
                                    ref={actionContainerRef}
                                    onClick={() => navigate('reservations/new')}
                                >
                                    <MediumText color="white" bold>Reservar</MediumText>
                                </ConfirmationButton>

                                <MediumText>Já tem uma reserva?</MediumText>
                                <NormalText color={colors.mediumGray} align="left">Veja suas reservas informando seu número de telefone</NormalText>
                                <ConfirmationButton
                                    onClick={() => navigate('get-reservation')}
                                >
                                    <MediumText color="white" bold>Ver reserva</MediumText>
                                </ConfirmationButton>

                                <Separator />

                                <MediumText>Dúvidas?</MediumText>
                                <NormalText color={colors.mediumGray} align="left">Se tem alguma dúvida você pode estar entrando em contato para obter maiores inforçãoes</NormalText>
                                <WhatsAppButton
                                    text="Entrar em contato"
                                    onClick={handleWhatsAppContact}
                                />
                            </ActionContainer>
                        </ContentContainer>

                    </>
                )}
            </DefaultContainer>

            {isMobileView && (
                <FloattingContainer isVisible={isFloattingVisible}>
                    <WhatsAppButton
                        text="Entrar em contato"
                        onClick={handleWhatsAppContact}
                    />
                    <ActionButton
                        text="Reservar"
                        onClick={() => navigate('reservations/new')}
                    />
                </FloattingContainer>
            )}
        </>
    )
}