import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import NormalText from "../texts/NormalText";
import LinkText from "../Link";
import Calendar from "../Calendar";

const Container = styled.div`
    display: flex;
    gap: 7px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
`;

const DateContainer = styled.div<{ selected?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.selected ? colors.primary : colors.white};
    padding: 15px 9px;
    border-radius: 5px;
    border: 1px solid ${colors.lightGray};
    transition: background-color 0.3s;

    &:hover {
        cursor: pointer;
        background-color: ${props => props.selected ? colors.primaryHover : colors.lightGray};
    }
`;

type SelectDateProps = {
    selectedDate: Date;
    setSelectedDate: React.Dispatch<React.SetStateAction<Date>>;
    storeOnLocalStorage?: boolean;
}

export default function SelectDate(
    { selectedDate, setSelectedDate, storeOnLocalStorage }: SelectDateProps
) {

    const [rangePlusDates, setRangePlusDates] = React.useState<Date[]>([]);
    const [calendarView, setCalendarView] = React.useState<boolean>(false);

    React.useEffect(() => {
        const range = 7;
        const dates = Array.from({ length: range }, (_, index) => {
            const date = new Date(selectedDate);
            date.setDate(selectedDate.getDate() + index);
            return date;
        });
        setRangePlusDates(dates);
    }, []);

    const handleSelectDate = (date: Date) => {
        if (storeOnLocalStorage) {
            localStorage.setItem("selectedDate", date.toISOString());
        }

        setSelectedDate(date);
    }

    return (
        <>
            <Container>
                {calendarView}
                {calendarView ? (
                    <>
                        <Calendar
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                        />
                    </>
                ) : (
                    <>
                        {rangePlusDates.map((date, index) => (
                            <DateContainer
                                key={index}
                                selected={date.getDate() === selectedDate.getDate()}
                                onClick={() => handleSelectDate(date)}
                            >
                                <NormalText
                                    bold={date.getDate() === selectedDate.getDate()}
                                    color={date.getDate() === selectedDate.getDate() ? colors.white : colors.mediumGray}
                                >
                                    {date.toLocaleDateString("pt-BR", { weekday: "short" })}
                                </NormalText>
                                <NormalText
                                    bold={date.getDate() === selectedDate.getDate()}
                                    color={date.getDate() === selectedDate.getDate() ? colors.white : colors.darkGray}
                                >
                                    {date.getDate()}
                                </NormalText>
                            </DateContainer>
                        ))}
                    </>
                )}
            <LinkText
                onClick={() => setCalendarView(!calendarView)}
            >
                <NormalText color={colors.primary}>
                    Ver mais datas
                </NormalText>
            </LinkText>
            </Container>
        </>

    );
}