import React, { useContext } from "react";
import styled from "styled-components";
import { FaHome, FaStore, FaCalendarAlt, FaUser } from "react-icons/fa";
import colors from "../../style/colors";
import { AuthContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";

const MenuBarContainer = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    max-width: 100vw;
    background-color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    border-top: 1px solid ${colors.lightGray};
    z-index: 1;
    gap: 40px;
`;

const MenuItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${colors.primary};
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;

    &:active {
        background-color: ${colors.lightGray};
    }
`;

const IconWrapper = styled.div`
    font-size: 24px;
`;

const MenuBar: React.FC = () => {
    const navigate = useNavigate()

    const { user } = useContext(AuthContext)

    return (
        <MenuBarContainer>
            {user?.id ? (
                <>
                     <MenuItem onClick={() => navigate('/schedule')}>
                        <IconWrapper>
                            <FaCalendarAlt />
                        </IconWrapper>
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/barbershops')}>
                        <IconWrapper>
                            <FaStore />
                        </IconWrapper>
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/profile')}>
                        <IconWrapper>
                            <FaUser />
                        </IconWrapper>
                    </MenuItem>
                </>
            ) : (
                <>
                    <MenuItem onClick={() => navigate('/')}>
                        <IconWrapper>
                            <FaHome />
                        </IconWrapper>
                    </MenuItem>
                    <MenuItem onClick={() => navigate('/login')}>
                        <IconWrapper>
                            <FaUser />
                        </IconWrapper>
                    </MenuItem>
                </>
            )}
        </MenuBarContainer>
    );
};

export default MenuBar;