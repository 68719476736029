import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import { BarbersType, BarberType } from "../../types/barber";
import NormalText from "../texts/NormalText";
import { NoImageBarber } from "../../pages/globalStyles";
import { api_url } from "../../config/api";
import MediumText from "../texts/MediumText";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";

const BarbersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
`;

const BarberContainer = styled.div<{ selected: boolean }>`
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 5px;
    background-color: ${colors.white};
    border: 2px solid ${({ selected }) => selected ? colors.primary : colors.lightGray};
    cursor: pointer;
    user-select: none;
    transition: border 0.3s;

    &:hover {
        border: 2px solid ${({ selected }) => selected ? colors.primary : colors.mediumGray};
    }

    &:active {
        border: 2px solid ${colors.lightGray};
    }
`;

const BarberContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
`;

const BarberImage = styled.img`
    width: 100px;
    height: 100px;
`;

type BarbersSelectorProps = {
    barbers: BarbersType;
    selectedBarbers: BarbersType;
    setSelectedBarbers: React.Dispatch<React.SetStateAction<any>>;
    onlyOneBarber?: boolean;
}

export default function BarbersSelector({ barbers, selectedBarbers, setSelectedBarbers, onlyOneBarber = false }: BarbersSelectorProps) {

   const handleSelectBarber = (barber: BarberType) => {
           let updatedBarbers;
   
           if (onlyOneBarber) {
               updatedBarbers = [barber];
           } else {
               if (Array.isArray(selectedBarbers) && selectedBarbers.find((selectedBarber: BarberType) => selectedBarber.id === barber.id)) {
                   updatedBarbers = selectedBarbers.filter((selectedBarber: BarberType) => selectedBarber.id !== barber.id);
               } else {
                   updatedBarbers = Array.isArray(selectedBarbers) ? [...selectedBarbers, barber] : [barber];
               }
           }
   
           updatedBarbers = updatedBarbers.slice().sort((a, b) => a.id - b.id);
   
           setSelectedBarbers(updatedBarbers);
       }

    return (
        <>
            <BarbersContainer>
                {barbers.map((barber, index) => (
                    <BarberContainer
                        key={index}
                        selected={Array.isArray(selectedBarbers) && selectedBarbers.some((selectedBarber: BarberType) => selectedBarber.id === barber.id)}
                        onClick={() => handleSelectBarber(barber)}
                    >
                        <BarberImage src={barber.usuario_foto ? api_url + barber.usuario_foto.foto : NoImageBarber} alt={barber.nome} />
                        <BarberContent>
                            <MediumText bold>{barber.nome}</MediumText>
                            <NormalText>{Array.isArray(selectedBarbers) && selectedBarbers.some((selectedBarber: BarberType) => selectedBarber.id === barber.id) ?
                                <FaCheckCircle color={colors.primary} size={24} />
                                :
                                <FaRegCircle color={colors.gray}  size={24} />
                            }</NormalText>
                        </BarberContent>
                    </BarberContainer>
                ))}
            </BarbersContainer>
        </>
    )
}