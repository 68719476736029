import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import { toast } from "react-toastify";

export const Container = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => (props.disabled ? colors.mediumGray : colors.primary)};
  border-radius: 3px;
  color: white;
  transition: background-color 0.3s;
  padding: 20px;
  gap: 10px;
  width: 100%;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-color: ${props => (props.disabled ? colors.mediumGray : colors.primaryHover)};
  }

  &:active {
    background-color: ${props => (props.disabled ? colors.mediumGray : colors.primary)};
    outline: ${props => (props.disabled ? "none" : `2px solid ${colors.gray}`)};
  }
`;

type ConfirmationButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  children: React.ReactNode;
  disabledMessage?: string;
};

const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({ children, onClick = () => {}, disabled = false, disabledMessage }) => {
  const handleClick = () => {
    if (!disabled) {
      onClick();
    } else {
      if (disabledMessage) {
        toast.warning(disabledMessage);
      }
    }
  };

  return (
    <Container onClick={handleClick} disabled={disabled}>
      {children}
    </Container>
  );
};

export default ConfirmationButton;