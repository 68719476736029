import React, { useEffect } from "react";
import Topbar from "../Topbar";
import Footer from "../Footer";
import styled from "styled-components";
import { ScreenSizeHook } from "../../hooks/ScreenSizeHook";
import MenuBar from "../MenuBar";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    @media (max-width: 768px) {
        /* max-width: 100vw; */
        overflow-x: hidden;
    }
`

const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;    
    padding: 0 0 20px 0px;
    flex-grow: 1; 
`

type BaseLayoutProps = {
    children: React.ReactNode
}

export default function BaseLayout({ children }: BaseLayoutProps) {

    const { isMobileView } = ScreenSizeHook();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [children])

    return (
        <Container>
            <GroupContainer>                
                {/* <Topbar /> */}
                {!isMobileView && <Topbar />}
                {children}
            </GroupContainer>            
            {isMobileView &&  <MenuBar />}
            <Footer />
        </Container>
    )
}