import React from "react";
import styled from "styled-components";
import colors from "../../../../../style/colors";
import exampleImage from '../../../../../assets/images/background.png';
import NormalText from "../../../../../components/texts/NormalText";
import MediumText from "../../../../../components/texts/MediumText";
import BigText from "../../../../../components/texts/BigText";
import ActionButton from "../../../../../components/ActionButton";
import { BarbersType } from "../../../../../types/barber";
import { api_url } from "../../../../../config/api";
import { UsuarioFotosType } from "../../../../../types/user";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
    align-items: center;
    border: 1px solid ${colors.lightGray};
    border-radius: 5px;
`;

const Image = styled.img`
    width: 100px;
    height: 100px;
    object-fit: cover;
`;

const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 20px;
    align-items: center;
`;

const ColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

type BarbersProps = {
    barbers: BarbersType;
    setBarbers: (barbers: BarbersType) => void;
    barbersPhotos: UsuarioFotosType;
}

export default function Barbers({ barbers, setBarbers, barbersPhotos }: BarbersProps) {
    const navigate = useNavigate();

    return (
        <>
            <BigText bold>Barbeiros</BigText>
            <NormalText color={colors.mediumGray}>Convide barbeiros para sua barbearia</NormalText>
            <ActionButton 
                text="Convidar barbeiro" 
                onClick={() => navigate('barber/invite')} 
                width="fit-content" 
                margin="0 0 0 auto" 
            />
            {barbers.length > 0 && (
                barbers.map(barber => (
                    <Container 
                        key={barber.id}
                        onClick={() => navigate(`barber/${barber.id}`)}
                    >
                        {barbersPhotos.find(photo => photo.usuario_id === barber.id)?.foto ? (
                            <Image src={ api_url + barbersPhotos.find(photo => photo.usuario_id === barber.id)?.foto} alt="barber" />
                        ) : (
                            <Image src={exampleImage} alt="barber" />
                        )}
                        <InfoContainer>
                            <MediumText bold>{barber.nome}</MediumText>
                            <ColumnContainer>
                                <NormalText>{barber.email}</NormalText>
                                <NormalText>{barber.telefone}</NormalText>
                            </ColumnContainer>
                        </InfoContainer>
                    </Container>
                ))
            )}
        </>
    );
}