import React from "react";
import { ServicesType, ServiceType } from "../../../../../../types/services";
import MediumText from "../../../../../../components/texts/MediumText";
import BigText from "../../../../../../components/texts/BigText";
import colors from "../../../../../../style/colors";
import NormalText from "../../../../../../components/texts/NormalText";
import SearchInput from "../../../../../../components/SearchInput";
import { Service, Services, ServiceValueAndTimeContainer } from "../../../../../globalStyles";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";

type ServiceTabProps = {
    services: ServicesType;
    setServices: React.Dispatch<React.SetStateAction<ServicesType>>;
    selectedServices: ServicesType;
    setSelectedServices: React.Dispatch<React.SetStateAction<ServicesType>>;
}

export default function ServiceTab({ services, setServices, selectedServices, setSelectedServices }: ServiceTabProps) {
    const [search, setSearch] = React.useState<string>("");
    const [filteredServices, setFilteredServices] = React.useState<ServicesType>([]);

    React.useEffect(() => {
        setFilteredServices(services.filter(service => service.nome.toLowerCase().includes(search.toLowerCase())))
    }, [search, services])

    const handleSearch = (value: string) => {
        setSearch(value)

        if (!value) {
            setFilteredServices(services)
        }

        setFilteredServices(services.filter(service => service.nome.toLowerCase().includes(value.toLowerCase())))
    }

    const handleSelectService = (service: ServiceType) => {
        if (selectedServices.some(selectedService => selectedService.id === service.id)) {
            setSelectedServices(selectedServices.filter(selectedService => selectedService.id !== service.id))
            return
        }

        setSelectedServices([...selectedServices, service])
    }

    return (
        <>
            <BigText>Serviços</BigText>
            <NormalText color={colors.mediumGray}>Realize a seleção de serviços aqui</NormalText>

            <SearchInput
                placeholder="Pesquisar serviço"
                onSearch={handleSearch}
            />

            <Services>
                {filteredServices.map((service, index) => (
                    <Service
                        key={index}
                        onClick={() => handleSelectService(service)}
                        isSelected={selectedServices.some(selectedService => selectedService.id === service.id)}
                    >
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            {selectedServices.some(selectedService => selectedService.id === service.id) ? (
                                <FaCheckCircle color={colors.primary} />
                            ) : (
                                <FaRegCircle color={colors.gray} />
                            )}
                            <MediumText color={colors.darkGray} bold>{service.nome}</MediumText>
                        </div>

                        <ServiceValueAndTimeContainer>
                            <MediumText color={colors.darkGray} bold>R$ {service.preco},00</MediumText>
                            <NormalText color={colors.mediumGray}>{service.tempo_estimado} minutos</NormalText>
                        </ServiceValueAndTimeContainer>
                    </Service>
                ))}
            </Services>

            
        </>
    )
}