import React, { useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import NormalText from "../texts/NormalText";

const Container = styled.div<{width?: string, margin?: string}>`
    position: relative;
    width: ${props => props.width || '100%'};
    margin: ${props => props.margin || '0 auto'};
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  border: 1px solid ${colors.gray};
  border-radius: 4px;
  box-sizing: border-box;
  color: ${colors.darkGray};
`;

const Dropdown = styled.div`
  position: absolute;
  display: flex;
    flex-direction: column;
    gap: 5;
    align-items: flex-start;

  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);


    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const Option = styled.div`
  padding: 10px;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid ${colors.lightGray};
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

type SelectionOptionsWithSearchProps = {
    options: string[];
    onSelect: (value: string) => void;
    seletedOption?: string;
    label: string;
    placeholder: string;
    width?: string;
    margin?: string;
};

const SelectionOptionsWithSearch: React.FC<SelectionOptionsWithSearchProps> = ({ options, onSelect, seletedOption, label, placeholder, width, margin }) => {
    const [searchTerm, setSearchTerm] = useState(seletedOption);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);

    useEffect(() => {
        setSearchTerm(seletedOption);
    }, [seletedOption]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchTerm(value);
        setFilteredOptions(options.filter(option => option.toLowerCase().includes(value.toLowerCase())));
        setIsDropdownVisible(true);
    };

    const handleOptionClick = (value: string) => {
        setSearchTerm(value);
        setIsDropdownVisible(false);
        onSelect(value);
    };

    return (
        <Container width={width} margin={margin}>
            <NormalText color={colors.darkGray} bold>{label}</NormalText>
            <Input
                type="text"
                placeholder={placeholder}
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={() => setIsDropdownVisible(true)}
                onBlur={() => setTimeout(() => setIsDropdownVisible(false), 200)} // Delay to allow click event
            />
            {isDropdownVisible && (
                <Dropdown>
                    {filteredOptions.map((option, index) => (
                        <Option key={index} onClick={() => handleOptionClick(option)}>
                            {option}
                        </Option>
                    ))}
                </Dropdown>
            )}
        </Container>
    );
};

export default SelectionOptionsWithSearch;