import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import NormalText from "../texts/NormalText";

type ContainerProps = {
    backgroundColor?: string;
    backgroundHoverColor?: string;
}

const Container = styled.div<ContainerProps>`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : colors.lightGray};
    border-radius: 2px;
    color: white;
    font-weight: bold;
    transition: background-color 0.3s;
    padding: 20px;
    gap: 10px;

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.backgroundHoverColor ? props.backgroundHoverColor : colors.gray};
    }

    &:active {
        background-color: ${(props) => props.backgroundColor ? props.backgroundColor : colors.lightGray};
        outline: 2px solid ${colors.gray};
    }
`;

type Props = {
    children: React.ReactNode;
    onClick: () => void;
    backgroundColor?: string;
    backgroundHoverColor?: string;
}

export default function GenericButton( { children, onClick, backgroundColor, backgroundHoverColor }: Props ) {
    return (
        <Container 
            onClick={onClick}
            backgroundColor={backgroundColor}
            backgroundHoverColor={backgroundHoverColor}
        >
            <NormalText>{children}</NormalText>
        </Container>
    )
}