import React, { useEffect } from "react";
import styled from "styled-components";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import GoBackTitle from "../../../../components/GoBackTitle";
import DefaultContainer from "../../../../components/Containers/DefaultContainer";
import colors from "../../../../style/colors";
import CenteredContentContainer from "../../../../components/Containers/CenteredContentContainer";
import SearchInput from "../../../../components/SearchInput";
import ActionButton from "../../../../components/ActionButton";
import Table from "../../../../components/Table";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import { AuthContext } from "../../../../context/authContext";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../../config/api";
import { toast } from "react-toastify";
import { GetReservationsType } from "../../../../types/reservation";
import { formatDateToDDMMYYYYFromString, getHourFromDateTime } from "../../../../functions/date";
import { BarbeariaType } from "../../../../types/barbershop";

const SearchAndButtonContainer = styled.div`
    display: flex;
    gap: 20px;
    width: 100%;
`

export default function ReservationList() {
    const { id_barbershop } = useParams()
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const { user } = React.useContext(AuthContext)
    const navigate = useNavigate()
    const [schedules, setSchedules] = React.useState<GetReservationsType>([])
    const [search, setSearch] = React.useState('')
    const [filteredSchedules, setFilteredSchedules] = React.useState<GetReservationsType>([])
    const [barberShop, setBarberShop] = React.useState<BarbeariaType>()

    useEffect(() => {

        const fetchAusences = async () => {
            const response = await api.get(`/agendamentos_completos/barbearia/${id_barbershop}`)
            if (response) {
                setSchedules(response.data)
            }
        }

        const getBarberShopData = async () => {
            const response = await api.get(`/barbearias/${id_barbershop}`)
            if (response) {
                setBarberShop(response.data)
            }
        }

        try {
            setIsLoading(true)
            if (id_barbershop && user?.id) {
                fetchAusences()
                getBarberShopData()
            }
        } catch {
            toast.error('Falha ao obter ausencias')
        } finally {
            hideLoadingWithDelay()
        }
    }, [id_barbershop, user])

    useEffect(() => {
        if (search === '') {
            setFilteredSchedules(schedules)
            return
        }

        setFilteredSchedules(schedules.filter(schedule => {
            const searchLower = search.toLowerCase();
            return (
                schedule.agendamento.id.toString().includes(searchLower) ||
                schedule.cliente_nao_cadastrado.nome.toLowerCase().includes(searchLower) ||
                formatDateToDDMMYYYYFromString(schedule.agendamento.data_agendamento).includes(searchLower) ||
                getHourFromDateTime(schedule.agendamento.data_agendamento).includes(searchLower) ||
                schedule.servicos.some(servico => servico.nome.toLowerCase().includes(searchLower)) ||
                ('R$' + schedule.servicos.reduce((acc, servico) => acc + servico.preco, 0)).includes(searchLower) ||
                (schedule.status_agendamento?.nome || '').toLowerCase().includes(searchLower)
            );
        }))
    }, [search, schedules])

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text={`Lista de reservas ${barberShop?.nome ? ' - ' + barberShop?.nome : ''}`} />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="center"
                align="flex-start"
                flexDirection="row"
            >
                <CenteredContentContainer
                    width="100%"
                >

                    <SearchAndButtonContainer>
                        <SearchInput
                            onSearch={(value) => setSearch(value)}
                            placeholder='Pesquisar reserva'
                        />

                        <ActionButton
                            onClick={() => navigate('new')}
                            text='Adicionar novo agendamento'
                            width='fit-content'
                        />

                    </SearchAndButtonContainer>

                    <Table
                        data={filteredSchedules.map(schedule => {
                            return {
                                id: schedule.agendamento.id,
                                descricao: schedule.cliente_nao_cadastrado.nome,
                                data: formatDateToDDMMYYYYFromString(schedule.agendamento.data_agendamento),
                                hora: getHourFromDateTime(schedule.agendamento.data_agendamento),
                                servicos: schedule.servicos.map(servico => servico.nome).join(', '),
                                precos: 'R$' + schedule.servicos.reduce((acc, servico) => acc + servico.preco, 0),
                                status: schedule.status_agendamento?.nome,
                                recorrencia_id: schedule.agendamento_recorrente?.id,
                                tipo_recorrencia: schedule.tipo_recorrencia?.nome,
                                data_inicio_recorrencia: formatDateToDDMMYYYYFromString(schedule.agendamento_recorrente?.data_inicio ?? ''),
                                data_fim_recorrencia: formatDateToDDMMYYYYFromString(schedule.agendamento_recorrente?.data_fim ?? ''),
                            }
                        }
                        )}
                        columns={
                            [
                                'id',
                                'descricao',
                                'data',
                                'hora',
                                'servicos',
                                'precos',
                                'status',
                                'recorrencia_id',
                                'tipo_recorrencia',
                                'data_inicio_recorrencia',
                                'data_fim_recorrencia',
                            ]
                        }
                        columnLabels={[
                            'ID',
                            'Descricao',
                            'Data',
                            'Hora',
                            'Servicos',
                            'Preco',
                            'Status',
                            'Recorrencia ID',
                            'Tipo Recorrencia',
                            'Data Inicio Recorrencia',
                            'Data Fim Recorrencia',
                        ]}
                        onItemClick={(item) => navigate(`done/${item.id}`)}
                    />

                </CenteredContentContainer>
            </DefaultContainer >
        </>
    )
}