import React from "react";
import colors from "../../../../../style/colors";
import BigText from "../../../../../components/texts/BigText";
import NormalText from "../../../../../components/texts/NormalText";
import { InputValue, LabelAndValueContainer } from "../../../../globalStyles";
import { BarbeariaLocationType } from "../../../../../types/barbershop";
import { useNavigate } from "react-router-dom";
import { citiesType } from "../../../../../types/city";
import SelectionOptionsWithSearch from "../../../../../components/SelectOptionsWithSearch";

type LocationTabProps = {
    barberShopLocation?: BarbeariaLocationType;
    setBarberShopLocation: (barberShopLocation: BarbeariaLocationType) => void;
    cities: citiesType;
}

export default function LocationTab({ barberShopLocation, setBarberShopLocation, cities }: LocationTabProps) {
    
    return (
        <>
            <BigText>Localização</BigText>
            <NormalText color={colors.mediumGray}>Informações de localização do seu estabelecimento</NormalText>
            {barberShopLocation && (
                <>                  
                    <SelectionOptionsWithSearch
                        label="Cidade"
                        placeholder="Selecione sua cidade"
                        options={cities.map(city => city.nome)}
                        onSelect={(value) => {
                            const selectedCity = cities.find(city => city.nome === value);
                            if (selectedCity) {
                                setBarberShopLocation({ ...barberShopLocation, cidade_id: selectedCity.id_ibge });
                            }
                        }}
                        seletedOption={cities.find(city => city.id_ibge === barberShopLocation?.cidade_id)?.nome ?? ''}
                    />
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Endereço</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Seu Endereço aqui"
                            value={barberShopLocation?.endereco}
                            onChange={(e) => setBarberShopLocation({ ...barberShopLocation, endereco: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Número</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Número"
                            value={barberShopLocation?.numero}
                            onChange={(e) => setBarberShopLocation({ ...barberShopLocation, numero: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Bairro</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Bairro"
                            value={barberShopLocation?.bairro}
                            onChange={(e) => setBarberShopLocation({ ...barberShopLocation, bairro: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Complemento</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Complemento"
                            value={barberShopLocation?.complemento ?? ''}
                            onChange={(e) => setBarberShopLocation({ ...barberShopLocation, complemento: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>CEP</NormalText>
                        <InputValue
                            type="text"
                            placeholder="CEP"
                            value={barberShopLocation?.cep}
                            onChange={(e) => setBarberShopLocation({ ...barberShopLocation, cep: e.target.value })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.darkGray} bold>Latitude e longitude</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Latitude e longitude"
                            value={barberShopLocation?.lat_long ?? ''}
                            onChange={(e) => setBarberShopLocation({ ...barberShopLocation, lat_long: e.target.value })}
                        />
                    </LabelAndValueContainer>
                </>
            )}
        </>
    );
}   