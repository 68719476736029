import React, { useContext, useEffect } from "react";
import LoadingFreezeScreen from "../../../../components/LoadingFreezeScreen";
import { LoadingHook } from "../../../../hooks/LoadingHook";
import { useNavigate, useParams } from "react-router-dom";
import NormalText from "../../../../components/texts/NormalText";
import { ConfirmationButton, GoBackButton, InputValue, LabelAndValueContainer } from "../../../globalStyles";
import colors from "../../../../style/colors";
import { AusenceType } from "../../../../types/ausences";
import styled from "styled-components";
import { api } from "../../../../config/api";
import { toast } from "react-toastify";
import { formatHourToHHMM } from "../../../../functions/hour";
import { AuthContext } from "../../../../context/authContext";
import ButtonWithModal from "../../../../components/ButtonWithModal";
import { FaCheckSquare, FaSquare } from "react-icons/fa";
import GoBackTitle from "../../../../components/GoBackTitle";
import DefaultContainer from "../../../../components/Containers/DefaultContainer";
import CenteredContentContainer from "../../../../components/Containers/CenteredContentContainer";
import { BarberBarberShopType } from "../../../../types/barber";
import { BarbeariaType } from "../../../../types/barbershop";

const FlagContainer = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.active ? colors.darkGray : colors.lightGray};
    color: ${colors.white};
    border-radius: 5px;
    padding: 15px;
    transition: background-color 0.3s;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: ${props => props.active ? colors.mediumGray : colors.gray};
    }

    &:active {
        outline: 1px solid ${colors.mediumGray};
    }
`

const DaysOfWeekContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    flex-wrap: wrap;
    gap: 10px;
`

const DayOfWeek = styled.div<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    background-color: ${props => props.active ? colors.darkGray : colors.lightGray};
    color: ${colors.white};
    border-radius: 5px;
    padding: 15px;
    transition: background-color 0.3s;

    &:hover {
        cursor: pointer;
        background-color: ${props => props.active ? colors.mediumGray : colors.gray};
    }

    &:active {        
        outline: 1px solid ${colors.mediumGray};
    }
`

export default function Ausence() {
    const { id, id_barbershop } = useParams()
    const { user } = useContext(AuthContext)
    const { isLoading, setIsLoading, hideLoadingWithDelay } = LoadingHook()
    const [ausence, setAusence] = React.useState<AusenceType>({
        id: 0,
        descricao: "",
        barbearia_usuario_id: 0,
        data_inicio: new Date().toISOString().split('T')[0],
        data_fim: new Date().toISOString().split('T')[0],
        hora_inicio: "",
        hora_fim: "",
        data_criacao: "",
        recorrente: false,
        recorrencia_dias_semana: [1, 2, 3, 4, 5, 6, 7],
        ativa: false
    })
    const [barber, setBarber] = React.useState<BarberBarberShopType>()
    const [barberShop, setBarberShop] = React.useState<BarbeariaType>()
    const navigate = useNavigate()

    useEffect(() => {
        if (id) {
            const getAusence = async () => {
                const response = await api.get(`/ausencias/${id}`)
                if (response) {
                    setAusence(response.data)
                }
            }
            try {
                setIsLoading(true)
                getAusence()
            } catch (error) {
                toast.error('Erro ao obter ausencia')
                navigate('/barber/ausencias')
            } finally {
                hideLoadingWithDelay()
            }
        }
    }, [id])

    useEffect(() => {
        const getBarberData = async () => {
            const response = await api.get(`/barbeiros/${user?.id}/barbearia/${id_barbershop}`)
            if (response) {
                setBarber(response.data.usuario_barbearia)
            }
        }

        const getBarberShopData = async () => {
            const response = await api.get(`/barbearias/${id_barbershop}`)
            if (response) {
                setBarberShop(response.data)
            }
        }

        try {
            setIsLoading(true)
            if (user?.id && id_barbershop) {
                getBarberData()
                getBarberShopData()
            }

        } catch (error) {
            toast.error('Erro ao obter dados da barbearia')
        } finally {
            hideLoadingWithDelay()
        }
    }, [id_barbershop, user])

    const handleSave = async () => {
        setIsLoading(true)

        if (!ausence.recorrente) {
            try {
                setIsLoading(true)
                const responseGetHoursOpening = await api.get(`horarios_atendimento/barbeiro/${barber?.id}/dia_semana/${new Date(ausence.data_inicio).getDay() + 1}`)
                if (responseGetHoursOpening.status === 200) {
                    const hours = responseGetHoursOpening.data
                    const hour = ausence.hora_inicio.split(':')
                    const hour_fim = ausence.hora_fim.split(':')
                    const start = parseInt(hour[0]) * 60 + parseInt(hour[1])
                    const end = parseInt(hour_fim[0]) * 60 + parseInt(hour_fim[1])
                    const opening = parseInt(hours.hora_abertura.split(':')[0]) * 60 + parseInt(hours.hora_abertura.split(':')[1])
                    const closing = parseInt(hours.hora_fechamento.split(':')[0]) * 60 + parseInt(hours.hora_fechamento.split(':')[1])

                    if (start < opening || end > closing) {
                        toast.error('Horario de inicio ou fim fora do horario de funcionamento')
                        toast.warning(`Horario de funcionamento: ${hours.hora_abertura} - ${hours.hora_fechamento}`)
                        hideLoadingWithDelay()
                        return
                    }

                } else {
                    toast.error('Erro ao obter horarios de funcionamento')
                    hideLoadingWithDelay()
                    return
                }
            } catch (error) {
                toast.error('Erro ao consultar horários de funcionamento')
            } finally {
                hideLoadingWithDelay()
            }
        } 

        if (id) {
            // update
            try {
                const response = await api.put('/ausencias/', ausence)
                if (response.status === 201 || response.data.id) {
                    toast.success("Ausencia atualizada com sucesso")
                } else {
                    toast.error("Erro ao criar ausencia")
                }
            }
            catch (error) {
                toast.error("Erro ao criar ausencia")
            }

        } else {
            try {
                const create_ausence = {
                    descricao: ausence.descricao,
                    recorrente: ausence.recorrente,
                    recorrencia_dias_semana: ausence.recorrencia_dias_semana,
                    data_inicio: ausence.data_inicio,
                    data_fim: ausence.recorrente ? ausence.data_fim : ausence.data_inicio,
                    hora_inicio: ausence.hora_inicio,
                    hora_fim: ausence.hora_fim,
                    barbearia_usuario_id: barber?.id
                }

                const response = await api.post('/ausencias/', create_ausence)
                if (response.status === 201 || response.data.id) {
                    toast.success("Ausencia criada com sucesso")
                    navigate(-1)
                } else {
                    toast.error("Erro ao criar ausencia")
                }
            }
            catch (error) {
                toast.error("Erro ao criar ausencia")
            }
        }

        hideLoadingWithDelay()
    }

    const handleDelete = async () => {
        setIsLoading(true)
        try {
            const response = await api.delete(`/ausencias/${ausence?.id}`)
            if (response.status === 200) {
                toast.success("Ausencia deletada com sucesso")
                navigate('/barber/ausencias')
            } else {
                toast.error("Erro ao deletar ausencia")
            }
        } catch (error) {
            toast.error("Erro ao deletar ausencia")
        } finally {
            hideLoadingWithDelay()
        }
    }

    const handleDaysOfWeek = (day: number) => {
        if (ausence.recorrencia_dias_semana?.includes(day)) {
            setAusence({
                ...ausence,
                recorrencia_dias_semana: ausence.recorrencia_dias_semana?.filter(d => d !== day)
            })
        } else {
            setAusence({
                ...ausence,
                recorrencia_dias_semana: [...(ausence.recorrencia_dias_semana || []), day]
            })
        }
    }

    return (
        <>
            <LoadingFreezeScreen isLoading={isLoading} />
            <GoBackTitle text={`Criação de ausencia ${barberShop?.nome ? ' - ' + barberShop?.nome : ''}`} />
            <DefaultContainer
                backgroundColor={colors.lightGray}
                borderColor={colors.gray}
                justify="center"
                align="flex-start"
                flexDirection="row"
            >
                <CenteredContentContainer
                    width="720px"
                >
                    <NormalText bold>Ausencia</NormalText>
                    {id && (
                        <LabelAndValueContainer>
                            <NormalText color={colors.mediumGray} bold>ID</NormalText>
                            <InputValue
                                type="text"
                                value={ausence.id}
                                disabled
                            />
                        </LabelAndValueContainer>
                    )
                    }
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Descricao</NormalText>
                        <InputValue
                            type="text"
                            placeholder="Descricao"
                            value={ausence.descricao}
                            onChange={(e) => setAusence({
                                ...ausence,
                                descricao: e.target.value
                            })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Recorrente</NormalText>
                        <FlagContainer
                            active={ausence.recorrente}
                            onClick={() => setAusence({
                                ...ausence,
                                recorrente: !ausence.recorrente
                            })
                            }
                        >
                            <NormalText
                                bold
                                color={ausence.recorrente ? colors.white : colors.darkGray}
                            >{ausence.recorrente ? "Desativar recorrencia" : "Marcar como recorrente"}</NormalText>
                        </FlagContainer>
                    </LabelAndValueContainer>

                    {ausence.recorrente && (
                        <>
                            <NormalText bold>Selecione os dias da semana</NormalText>
                            <DaysOfWeekContainer>
                                <DayOfWeek
                                    active={ausence.recorrencia_dias_semana?.includes(1)}
                                    onClick={() => handleDaysOfWeek(1)}
                                >
                                    {ausence.recorrencia_dias_semana?.includes(1) ? (
                                        <FaCheckSquare style={{ marginRight: '8px' }} />
                                    ) : (
                                        <FaSquare style={{ marginRight: '8px' }} />
                                    )}
                                    <NormalText
                                        bold
                                        color={ausence.recorrencia_dias_semana?.includes(1) ? colors.white : colors.darkGray}
                                    >
                                        Segunda
                                    </NormalText>
                                </DayOfWeek>
                                <DayOfWeek
                                    active={ausence.recorrencia_dias_semana?.includes(2)}
                                    onClick={() => handleDaysOfWeek(2)}
                                >
                                    {ausence.recorrencia_dias_semana?.includes(2) ? (
                                        <FaCheckSquare style={{ marginRight: '8px' }} />
                                    ) : (
                                        <FaSquare style={{ marginRight: '8px' }} />
                                    )}
                                    <NormalText
                                        bold
                                        color={ausence.recorrencia_dias_semana?.includes(2) ? colors.white : colors.darkGray}
                                    >
                                        Terça
                                    </NormalText>
                                </DayOfWeek>
                                <DayOfWeek
                                    active={ausence.recorrencia_dias_semana?.includes(3)}
                                    onClick={() => handleDaysOfWeek(3)}
                                >
                                    {ausence.recorrencia_dias_semana?.includes(3) ? (
                                        <FaCheckSquare style={{ marginRight: '8px' }} />
                                    ) : (
                                        <FaSquare style={{ marginRight: '8px' }} />
                                    )}
                                    <NormalText
                                        bold
                                        color={ausence.recorrencia_dias_semana?.includes(3) ? colors.white : colors.darkGray}
                                    >
                                        Quarta
                                    </NormalText>
                                </DayOfWeek>
                                <DayOfWeek
                                    active={ausence.recorrencia_dias_semana?.includes(4)}
                                    onClick={() => handleDaysOfWeek(4)}
                                >
                                    {ausence.recorrencia_dias_semana?.includes(4) ? (
                                        <FaCheckSquare style={{ marginRight: '8px' }} />
                                    ) : (
                                        <FaSquare style={{ marginRight: '8px' }} />
                                    )}
                                    <NormalText
                                        bold
                                        color={ausence.recorrencia_dias_semana?.includes(4) ? colors.white : colors.darkGray}
                                    >
                                        Quinta
                                    </NormalText>
                                </DayOfWeek>
                                <DayOfWeek
                                    active={ausence.recorrencia_dias_semana?.includes(5)}
                                    onClick={() => handleDaysOfWeek(5)}
                                >
                                    {ausence.recorrencia_dias_semana?.includes(5) ? (
                                        <FaCheckSquare style={{ marginRight: '8px' }} />
                                    ) : (
                                        <FaSquare style={{ marginRight: '8px' }} />
                                    )}
                                    <NormalText
                                        bold
                                        color={ausence.recorrencia_dias_semana?.includes(5) ? colors.white : colors.darkGray}
                                    >
                                        Sexta
                                    </NormalText>
                                </DayOfWeek>
                                <DayOfWeek
                                    active={ausence.recorrencia_dias_semana?.includes(6)}
                                    onClick={() => handleDaysOfWeek(6)}
                                >
                                    {ausence.recorrencia_dias_semana?.includes(6) ? (
                                        <FaCheckSquare style={{ marginRight: '8px' }} />
                                    ) : (
                                        <FaSquare style={{ marginRight: '8px' }} />
                                    )}
                                    <NormalText
                                        bold
                                        color={ausence.recorrencia_dias_semana?.includes(6) ? colors.white : colors.darkGray}
                                    >
                                        Sabado
                                    </NormalText>
                                </DayOfWeek>
                                <DayOfWeek
                                    active={ausence.recorrencia_dias_semana?.includes(7)}
                                    onClick={() => handleDaysOfWeek(7)}
                                >
                                    {ausence.recorrencia_dias_semana?.includes(7) ? (
                                        <FaCheckSquare style={{ marginRight: '8px' }} />
                                    ) : (
                                        <FaSquare style={{ marginRight: '8px' }} />
                                    )}
                                    <NormalText
                                        bold
                                        color={ausence.recorrencia_dias_semana?.includes(7) ? colors.white : colors.darkGray}
                                    >
                                        Domingo
                                    </NormalText>
                                </DayOfWeek>
                            </DaysOfWeekContainer>
                        </>
                    )}

                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Data de inicio</NormalText>
                        <InputValue
                            type="date"
                            value={ausence.data_inicio}
                            onChange={(e) => setAusence({
                                ...ausence,
                                data_inicio: e.target.value
                            })}
                        />
                    </LabelAndValueContainer>

                    {ausence.recorrente && (
                        <LabelAndValueContainer>
                            <NormalText color={colors.mediumGray} bold>Data de fim</NormalText>
                            <InputValue
                                type="date"
                                value={ausence.data_fim}
                                onChange={(e) => setAusence({
                                    ...ausence,
                                    data_fim: e.target.value
                                })}
                            />
                        </LabelAndValueContainer>
                    )}

                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Hora de inicio</NormalText>
                        <InputValue
                            type="time"
                            value={formatHourToHHMM(ausence.hora_inicio)}
                            onChange={(e) => setAusence({
                                ...ausence,
                                hora_inicio: e.target.value
                            })}
                        />
                    </LabelAndValueContainer>
                    <LabelAndValueContainer>
                        <NormalText color={colors.mediumGray} bold>Hora de fim</NormalText>
                        <InputValue
                            type="time"
                            value={formatHourToHHMM(ausence.hora_fim)}
                            onChange={(e) => setAusence({
                                ...ausence,
                                hora_fim: e.target.value
                            })}
                        />
                    </LabelAndValueContainer>

                    <ConfirmationButton
                        onClick={handleSave}
                    >
                        <NormalText color="white" bold>Salvar</NormalText>
                    </ConfirmationButton>
                    {id && (
                        < ButtonWithModal
                            cancelFunction={handleDelete}
                            label="Deletar Ausencia"
                            title="Deseja realmente deletar a ausencia?"
                        />
                    )}
                    <GoBackButton
                        onClick={() => navigate(-1)}
                    >
                        <NormalText bold>Voltar</NormalText>
                    </GoBackButton>
                </CenteredContentContainer>
            </DefaultContainer >
        </>
    )
}