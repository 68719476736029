import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import { FaAngleLeft } from "react-icons/fa";
import MediumText from "../texts/MediumText";
import { useNavigate } from "react-router-dom";
import { ScreenSizeHook } from "../../hooks/ScreenSizeHook";
import NormalText from "../texts/NormalText";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 20%;
    width: 100%;
    position: relative;
    background-color: ${colors.white};
`;

const Icon = styled(FaAngleLeft)`
    font-size: 17px;
    color: ${colors.primary};
    position: absolute;
    left: 20%;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }

    @media (max-width: 768px) {
        left: 10px;
    }
`;

type GoBackTitleProps = {
    text: string
}

export default function GoBackTitle({ text }: GoBackTitleProps) {
    const navigate = useNavigate();
    const { isMobileView } = ScreenSizeHook()


    return (
        <Container>
            <Icon onClick={() => navigate(-1)} />
            {isMobileView ? 
                <NormalText 
                    width="100%" 
                    align="center" 
                    bold
                >
                    {text}
                </NormalText> 
            : 
                <MediumText 
                    bold 
                    align="center"
                >
                    {text}
                </MediumText>
            }
        </Container>
    );
}