import styled from "styled-components"
import colors from "../../style/colors"
import { FaBars } from "react-icons/fa";

export const Container = styled.div`
    width: 100%;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.lightGray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20%;
    user-select: none;

    @media (max-width: 768px) {
        padding: 10px 10px;
    }
`;

export const Logo = styled.img`
    width: 63px;
    height: 17px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 1;
    }
`;

export const MenuItem = styled.a`
    color: ${colors.darkGray};
    font-size: 12px;
    cursor: pointer;
    &:hover {
        color: ${colors.blue};
    }

    &:last-child {
        margin-right: 0;
    }

    &:active {
        color: ${colors.darkGray};
    }
`;

export const GroupContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 20px;
`;

export const Button = styled.button`
    background-color: ${colors.primary};
    color: ${colors.white};
    border: none;
    padding: 5px 10px;
    min-width: 100px;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s;

    &:hover {
        background-color: ${colors.black};
    }
    &:active {
        background-color: ${colors.primary};
    }
`;

export const MenuIcon = styled(FaBars)`
    font-size: 24px;
    font-weight: 500;
    color: ${colors.darkGray};
    cursor: pointer;
`;