import React from "react";
import styled from "styled-components";
import colors from "../../style/colors";
import { BarbersType, BarberType } from "../../types/barber";
import NormalText from "../texts/NormalText";
import { NoImageBarber } from "../../pages/globalStyles";
import { api_url } from "../../config/api";
import MediumText from "../texts/MediumText";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";

const BarbersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
`;

const BarberContainer = styled.div<{ selected: boolean }>`
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 5px;
    background-color: ${colors.white};
    border: 2px solid ${({ selected }) => selected ? colors.primary : colors.lightGray};
    cursor: pointer;
    user-select: none;
    transition: border 0.3s;

    &:hover {
        border: 2px solid ${({ selected }) => selected ? colors.primary : colors.mediumGray};
    }

    &:active {
        border: 2px solid ${colors.lightGray};
    }
`;

const BarberContent = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
`;

const BarberImage = styled.img`
    width: 100px;
    height: 100px;
`;

type BarbersSelectorProps = {
    barbers: BarbersType;
    selectedBarber?: BarberType;
    setSelectedBarber: React.Dispatch<React.SetStateAction<BarberType | undefined>>;
}

export default function BarbersSelector({ barbers, selectedBarber, setSelectedBarber }: BarbersSelectorProps) {

    const handleSelectBarber = (barber: BarberType) => {
        if (selectedBarber?.id === barber.id) {
            setSelectedBarber(undefined)
            return
        }
        setSelectedBarber(barber)
    }

    return (
        <>
            <BarbersContainer>
                {barbers.map((barber, index) => (
                    <BarberContainer
                        key={index}
                        selected={selectedBarber?.id === barber.id}
                        onClick={() => handleSelectBarber(barber)}
                    >
                        <BarberImage src={barber.usuario_foto ? api_url + barber.usuario_foto.foto : NoImageBarber} alt={barber.nome} />
                        <BarberContent>
                            <MediumText bold>{barber.nome}</MediumText>
                            <NormalText>{selectedBarber?.id === barber.id ?
                                <FaCheckCircle color={colors.primary} size={24} />
                                :
                                <FaRegCircle color={colors.gray}  size={24} />
                            }</NormalText>
                        </BarberContent>
                    </BarberContainer>
                ))}
            </BarbersContainer>
        </>
    )
}